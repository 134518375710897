import { useCallback, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { ApiController } from "../../apiController"
import { useCombinedStore } from "../../store"
import { IntermediaryLogin, User, UserParent } from "../../swagger/models"
import _ from 'lodash';
import { encryptString } from "../common-module/helper"
import { useTranslation } from 'react-i18next';
import { GoogleReCaptcha } from "react-google-recaptcha-v3"

function IntermediaryMultipleLogin() {

  const navigate = useNavigate()
  const { t } = useTranslation();
  const selectMIIProfiles = useCombinedStore((state: any) => state.selectMIIProfiles)
  const intermediaryLoginRequest = useCombinedStore((state: any) => state.intermediaryLoginRequest)
  const login = useCombinedStore((state: any) => state.intermediaryLoginAPI)
  const [userParents, setUserParents] = useState<any[]>([])

  const [loginRequest, setLoginRequest] = useState<IntermediaryLogin>({
    email: '',
    password: '',
    otp: '',
    parentId: '',
    subCategoryId: ''
  })
  const [OTPRequest, setOTPRequest] = useState<IntermediaryLogin>({  })

  useEffect(() => {
    setLoginRequest(intermediaryLoginRequest)
    setOTPRequest(intermediaryLoginRequest)
  }, [intermediaryLoginRequest])

  const proceedLogin = () => {
    login(OTPRequest)
  }

  useEffect(() => {
    ApiController.getInstance().UserLoggedIn$.subscribe((userLoggedIn: User | null) => {
      if (userLoggedIn) {
        if (userLoggedIn.userType === "Intermediary") {
          navigate(`/intermediary/disputes`)
        } else {
          navigate(`/intermediary/login`)
        }
      }
    })
  }, [])

  useEffect(() => {
    const unqiueIds = _.uniqBy(selectMIIProfiles, 'parentId')
    setUserParents(unqiueIds)
  }, [selectMIIProfiles])

  const selectedProfile = (profile: any) => {
    localStorage.setItem('selectProfile', JSON.stringify(profile))
  }

  const onVerify = useCallback((token: string) => {
    setLoginRequest({
      ...intermediaryLoginRequest,
      captchaToken: token
    })
    setOTPRequest({
      ...intermediaryLoginRequest,
      captchaToken: token
    })
  }, [intermediaryLoginRequest]);

  return (
    <div className="pt-7 w-full min-h-screen h-full pr-1 sm:pr-4 pl-1 pb-7">
      <GoogleReCaptcha onVerify={onVerify} refreshReCaptcha={true} />
      <div
        style={{ background: "#F4F4F4" }}
        className="flex w-full h-full flex-initial items-center  px-8 py-7 flex-col gap-2 rounded-2xl"
      >
        <p className="text-2xl font-bold">{t('SelectTheMIIDisputes')}</p>
        <p className="text-xs">{t('YourAccountLinked')}</p>
        <div className="sm:grid sm:grid-cols-4 flex flex-wrap gap-16 pt-16">
          {
            userParents && userParents?.map((profile: UserParent) => {
              return <div onClick={(e: any) => {
                setLoginRequest({
                ...loginRequest,
                parentId: profile.parentId,
                subCategoryId: profile.subCategoryId,
                // email: profile.email,
              }), setOTPRequest({
                ...OTPRequest,
                password: encryptString(loginRequest.password),
                parentId: profile.parentId,
                subCategoryId: profile.subCategoryId,
                // email: profile.email,
              }), selectedProfile(profile)}} className={`items-center rounded-lg flex flex-col gap-2 px-12 py-4 hover:bg-highlight-blue hover:text-white cursor-pointer ${profile.parentId === loginRequest.parentId ? 'bg-highlight-blue text-white' : 'bg-light-blue'}`}>
                <img src="https://ocp-module-development.s3.ap-south-1.amazonaws.com/SMARTODR_LOGO.svg" alt="SMARTODR Logo" style={{ width: '90px', height: 'fit' }} />
                <p>{profile?.Parent?.organisationName.length>1? profile?.Parent?.organisationName : 'Other'}</p>
              </div>
            })
          }
        </div>
        <button disabled={!loginRequest.subCategoryId} aria-label='Login' type='button' onClick={proceedLogin} className='bg-highlight-blue hover:bg-highlight-blue/[0.8] disabled:bg-highlight-blue/[0.8] mt-4 text-white text-sm py-4 px-28 rounded-xl'>
        {t('Proceed')}
        </button>
      </div>
    </div>
  )
}

export default IntermediaryMultipleLogin