import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';

import { ApiController } from "../../../apiController";
import { Complain, User } from "../../../swagger/models";
import { useCombinedStore } from "../../../store"
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import { useTranslation } from 'react-i18next';

interface ComplaintPreviewModalProps {
  previewModal?: any
  setPreviewModal?: any
  category?: any,
  complainObject?: any,
  natureOfComplainDescription?: any,
  selectedNatureOfComplaints?: any,
  intermediary?: any
}

function ComplaintPreview({ previewModal, setPreviewModal, category, complainObject, natureOfComplainDescription, intermediary, selectedNatureOfComplaints }: ComplaintPreviewModalProps) {

  const navigate = useNavigate()
  const params = useParams()
  const { t } = useTranslation();

  const role: string = params.role || "investor"

  useEffect(() => {
    ApiController.getInstance().UserLoggedIn$.subscribe((user: User | null) => {
      if (user) {
        if (user.userType === "Investor") {
          setUser(user);
        }
      }
    });
  }, [])

  const [user, setUser] = useState<User>({})
  const [refreshCaptcha, setRefreshCaptcha] = useState<number>(0)


  const fileAComplaint = useCombinedStore((state: any) => state.fileComplaints)
  const handleComplaintSubmission = (e: any) => {
    e.preventDefault()
    fileAComplaint(complainObject)
    setRefreshCaptcha(refreshCaptcha + 1)
    ApiController.getInstance().ComplainFiled$.subscribe((complainFiled: Complain | null) => {
      if (complainFiled) {
        fetch(
          "https://hooks.slack.com/services/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",
          {
            method: "POST",
            mode: "no-cors",
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
            body: JSON.stringify({
              text: `*${user?.userName}* has filed a dispute against *${intermediary?.organisationName}*. The MII in this dispute is *${complainFiled?.MII?.organisationName}*`,
            }),
          }
        )
        navigate(`/${role}/dispute-registration/success?email=${user.email}&complaintID=${complainFiled.complaintUUID}&mii=${complainFiled?.MII?.organisationName}`)
      }
    })
  }

  const onVerify = useCallback((token: string) => {
    complainObject.captchaToken = token
  }, [refreshCaptcha]);

  return (
    <div
      className="inset-0 fixed z-10 flex items-center justify-center"
      id="modal"
    >
      <div className="px-6 sm:px-0 sm:w-1/3">
        <GoogleReCaptcha onVerify={onVerify} refreshReCaptcha={true} />
        <div className="flex justify-center pt-4 pb-20">
          <div
            className="fixed inset-0 transition-opacity"
          >
            <div className="absolute inset-0 bg-gray-900 opacity-75" />
          </div>
          <div
            className="inline-block align-center h-fit w-fit bg-white rounded-xl py-4 text-left shadow-xl transform transition-all "
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
            
          >
            <div className="flex flex-col justify-between h-full px-8 pb-5">
              <div className="flex flex-col gap-1">
              <h1 className='font-semibold text-2xl pt-3 w-full pb-4 border-b-2 border-b-highlight-blue'>{t('DisputePreview')}</h1>
              <div className='flex flex-col gap-6 pb-3 pt-8  text-label-grey w-full h-96 overflow-hidden overflow-y-scroll'>
                <div className="flex flex-col gap-3 w-full">

                  <div className="flex sm:flex-row flex-col w-full">
                    <p className="text-xs text-label-grey w-1/3 mr-2 whitespace-nowrap">{t('email')}:</p>
                    <p className="text-xs text-label-grey w-2/3">{user.email ? user.email : ""}</p>
                  </div>
                  <div className="flex sm:flex-row flex-col w-full">
                    <p className="text-xs text-label-grey w-1/3 mr-2 whitespace-nowrap">{t('Intermediary')}:</p>
                    <p className="text-xs text-label-grey w-2/3">{category.length > 40 ? category.slice(0, 40) + '...' : category}</p>
                  </div>
                  <div className="flex sm:flex-row flex-col w-full">
                    <p className="text-xs text-label-grey w-1/3 mr-2 whitespace-nowrap">{t('DisputeAgainst')}:</p>
                    <p className="text-xs text-label-grey w-2/3">{intermediary?.organisationName}</p>
                  </div>
                  {selectedNatureOfComplaints?.map((complain: any, index: any) => {
                    return (
                      <>
                        <div className="flex sm:flex-row flex-col w-full">
                          <p className="text-xs text-label-grey w-1/3 mr-2 whitespace-nowrap">{t('NatureOfDispute')}:</p>
                          <p className="text-xs text-label-grey w-2/3">
                            {complain.natureType}
                          </p>
                        </div>
                        <hr />
                        {complain?.selectedSubComplaintType?.map((subcomplain: any, index: any) => {
                          return (
                            <>
                              <div className="flex sm:flex-row flex-col w-full">
                                <p className="text-xs text-label-grey w-1/3 mr-2 ">{t('Sub-NatureOfDispute')}:</p>
                                <p className="text-xs text-label-grey w-2/3">{subcomplain?.subNatureType}</p>
                              </div>
                              <div className="flex sm:flex-row flex-col w-full">
                                <p className="text-xs text-label-grey w-1/3 mr-2 whitespace-nowrap">{t('DisputeDetails')}:</p>
                                <p className="text-xs text-label-grey w-2/3">{subcomplain?.description}</p>
                              </div>
                              {complainObject?.natureFiles?.map((file: any, index: any) => {
                                return (<>
                                  {file?.natureType === subcomplain?.subNatureType && (
                                    <div className="flex sm:flex-row flex-col w-full">
                                      <p className="text-xs text-label-grey w-1/3 mr-2 whitespace-nowrap">{t('Attachments')}:</p>
                                      <p className="text-xs text-label-grey w-2/3">{file?.fileName.length > 30 ? file?.fileName.slice(0,30) + '...' : file?.fileName}</p>
                                    </div>
                                  )
                                  }
                                </>)
                              })}
                              <hr />
                            </>
                          )
                        })}
                      </>
                    )
                  })}

                </div>
              </div>
              </div>
              <div className='flex sm:flex-row flex-col w-full pt-6 gap-4 justify-start'>
                <button onClick={(e) => { handleComplaintSubmission(e) }} className="hover:bg-highlight-blue/[0.4] bg-highlight-blue justify-center text-white flex flex-row gap-2 text-xs py-3 rounded-xl disabled:bg-highlight-blue/[0.4] px-12" aria-label={t('Proceed')}
                >
                  <p>{t('Proceed')}</p>
                </button>
                <button aria-label={t('cancel')}
                  onClick={() => { setPreviewModal(!previewModal) }}
                  className=" hover:bg-black/[0.1] cursor-pointer hover:text-white justify-center text-xs bg-white text-back-gray px-12 py-3 rounded-xl">
                  {t('cancel')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default ComplaintPreview