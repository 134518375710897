import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  Filler,
} from "chart.js";
import { useEffect, useRef, useState } from "react";
import { Line } from "react-chartjs-2";

import { dateTimeHelper, titleCase } from "../../common-module/helper";
import { ApiController } from "../../../apiController";
import { Complain, DashboardReport, DashboardReportParams } from "../../../swagger/models";
import { useTranslation } from 'react-i18next';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

const options = {
  scales: {
    x: {
      grid: {
        display: true,
      },
    },
    y: {
      display: true,
      grid: {
        display: true,
      },
      ticks: {
        display: true,
      },
    },
  },
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
  },
};

/**
 * This component renders the dashboard for the admin user.
 * It displays the number of disputes received, resolved, and their average resolution time.
 * It also displays the number of disputes referred to ODR and the total claim value.
 * Additionally, it displays a graph of the number of disputes filed over time.
 * Finally, it displays the top dispute regions.
 */
function Dashboard() {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<any>(null);
  const [dashboardReport, setDashboardReport] = useState<DashboardReport>({});
  const [disputesResolved, setDisputesResolved] = useState<number>(0);
  const [disputeType, setDisputeType] = useState('Total')
  const [dashboardReportParams, setDashboardReportParams] = useState<DashboardReportParams>({});

  const [labels, setLabels] = useState<any[]>([]);

  const [graphData, setGraphData] = useState<{ labels: any[], datasets: any[] }>({
    labels: labels,
    datasets: [
      {
        label: "Complains",
        data: [],
        fill: true,
        backgroundColor: ["rgba(37, 205, 37, 0.40)"],
        borderColor: ["rgba(37,205,37,1)"],
        borderWidth: 1,
        borderRadius: 6,
        tension: 0.4,
      },
    ],
  });

  const handleClickOutside = (event: any) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const fetchReport = async () => {
    const report = await ApiController.getInstance().adminApi.adminDashboardReport(
      dashboardReportParams
    );
    setDashboardReport(report.data);
    setDisputesResolved(report.data?.resolvedConciliationCount + report.data?.resolvedArbitrationCount + report.data?.resolvedPreConciliationCount)
    setLabels(report.data.twelveMonthComplains.map((complain: any) => complain.Date));
    setGraphData({
      ...graphData,
      labels: report.data.twelveMonthComplains.map((complain: any) => complain.Date),
      datasets: [{
        ...graphData.datasets[0],
        data: report.data.twelveMonthComplains.map((complain: any) => complain.count)
      }]
    })
  };

  const formatNumber = (number: number) => {
    if (number >= 10000000) {
      return (number / 10000000).toFixed(2) + ' Cr';
    } else if (number < 10000000 && number >= 100000) {
      return (number / 100000).toFixed(2) + ' L';
    } else {
      return number;
    }
  }

  const calculatePercentageDifference = (oldValue: number, newValue: number) => {
    const difference = newValue - oldValue;
    if (difference === 0) {
      return t('NoChange');
    }
    if (oldValue === 0) {
      return `${newValue} disputes filed this week`
    } else {
      const percentageDifference = (difference / oldValue) * 100;
      return percentageDifference > 1 ? t('IncreaseComparedWeek', {
        percentage: Math.abs(percentageDifference).toFixed(2)
      }) : t('DecreaseComparedWeek', {
        percentage: Math.abs(percentageDifference).toFixed(2)
      });
    }
  }

  useEffect(() => {
    fetchReport();
  }, [dashboardReportParams]);

  return (
    <div className=" w-full sm:h-screen h-full p-5">
      <div
        style={{ background: "#F4F4F4" }}
        className="flex w-full sm:h-full h-full flex-initial  px-8 py-7 flex-col gap-8 rounded-2xl"
      >
        <div className="flex flex-col-reverse text-center sm:text-left gap-5 items-center sm:items-start sm:flex-row sm:justify-between">
          <div className="flex flex-col gap-1">
            <p className="sm:text-4xl text-2xl font-semibold">{t('DisputeDataDashboard')}</p>
            <p className="text-xs text-back-gray">{t('LastUpdatedAt', {
              date: dateTimeHelper(new Date())
            })}</p>
          </div>
          <img src="https://ocp-module-development.s3.ap-south-1.amazonaws.com/SMARTODR_LOGO.svg" alt="SMARTODR Logo" style={{ width: '250px', height: 'fit' }} />
        </div>

        <div className="flex sm:flex-row sm:justify-between flex-col gap-6">
          <div className="flex flex-col gap-2 rounded-xl sm:w-1/3 w-full sm:mr-8 sm:pl-4 px-4 shadow bg-white py-4 border border-border-grey">
            <p className="text-xl font-semibold whitespace-nowrap">{t('TotalDisputesReceived')}</p>
            <p className="text-5xl font-medium flex gap-3 items-center">{dashboardReport?.complainCount}
              {dashboardReport?.lastWeekComplainCount - dashboardReport?.currentWeekComplainCount < 0 &&
                <i className="text-light-green text-2xl ph-bold ph-arrow-up-right"></i>
              }
              {dashboardReport?.lastWeekComplainCount - dashboardReport?.currentWeekComplainCount > 0 &&
                <i className="ph-bold ph-arrow-down-right text-2xl text-highlight-red"></i>
              }
            </p>
            <p className="text-dash-grey text-sm">{calculatePercentageDifference(dashboardReport?.lastWeekComplainCount, dashboardReport?.currentWeekComplainCount)}.</p>
          </div>
          <div className="flex flex-col gap-2 rounded-xl sm:w-1/3 w-full sm:mr-8 sm:pl-4 px-4 shadow bg-white py-4 border border-border-grey">
            <p className="text-xl font-semibold whitespace-nowrap">{t('DisputesResolved')}</p>
            <p className="text-5xl font-medium flex gap-3 items-center">{disputesResolved}
              {/* <i className="text-light-green text-2xl ph-bold ph-arrow-up-right"></i> */}
            </p>
            <p className="text-dash-grey text-sm">{disputeType === "Total" ? t('TotaldisputesResolved') : disputeType === "Pre-Con" ? t('TotalDisputesResolvedPreCon') : disputeType === "Con" ? t('TotalDisputesResolvedConciliation') : t('TotalDisputesResolvedArbitration')}</p>
            {/* <p className="text-dash-grey text-sm">10% Increase compared to last week.</p> */}
            <div className="text-xs pt-10 items-center flex flex-wrap gap-2 sm:gap-0 sm:flex-nowrap">
              <button className={`${disputeType === "Total" ? 'bg-border-grey text-white' : ''} text-xs p-2 sm:rounded-tl-lg sm:rounded-bl-lg border border-border-grey hover:bg-back-gray`}
                onClick={() => {
                  setDisputesResolved(dashboardReport?.resolvedPreConciliationCount + dashboardReport?.resolvedConciliationCount + dashboardReport?.resolvedArbitrationCount)
                  setDisputeType("Total")
                }}>{t('Total')}</button>
              <button className={`${disputeType === "Pre-Con" ? 'bg-border-grey text-white' : ''} text-xs p-2 border border-border-grey hover:bg-back-gray`}
                onClick={() => {
                  setDisputesResolved(dashboardReport?.resolvedPreConciliationCount)
                  setDisputeType("Pre-Con")
                }}>{t('Pre-Conciliation')}</button>
              <button className={`${disputeType === "Con" ? 'bg-border-grey text-white' : ''} text-xs p-2 border border-border-grey hover:bg-back-gray`}
                onClick={() => {
                  setDisputesResolved(dashboardReport?.resolvedConciliationCount)
                  setDisputeType("Con")
                }}>{t('Conciliation')}</button>
              <button className={`${disputeType === "Arb" ? 'bg-border-grey text-white' : ''} text-xs p-2 sm:rounded-tr-lg sm:rounded-br-lg border border-border-grey hover:bg-back-gray`}
                onClick={() => {
                  setDisputesResolved(dashboardReport?.resolvedArbitrationCount)
                  setDisputeType("Arb")
                }}>{t('Arbitration')}</button>
            </div>
          </div>
          <div className="flex flex-col gap-2 rounded-xl sm:w-1/3 w-full sm:mr-8 sm:pl-4 px-4 shadow bg-white py-4 border border-border-grey">
            <p className="text-xl font-semibold whitespace-nowrap">{t('AverageResolutionTime')}</p>
            <p className="text-5xl font-medium flex gap-3 items-center">{dashboardReport?.averageResolutionTime} {t('days')}</p>
          </div>
        </div>
        <div className="sm:flex flex-row h-full hidden overflow-auto max-h-full">
          <div className="flex flex-col w-2/5 h-full shadow border border-border-grey bg-white rounded-lg">
            <div className="px-6 pt-6 pb-4 flex flex-row justify-between">
              <p className="text-lg font-semibold">{t('RecentDisputes')}</p>
              {/* <p className="text-dash-grey text-sm">Sort by Top</p> */}
            </div>
            <div className="px-2 max-h-fit overflow-hidden overflow-y-scroll flex flex-col gap-2">
              {dashboardReport?.latestDisputes?.map(
                (dispute: Complain) => {
                  return (
                    <div className="group hover:bg-dash-highblue rounded-lg flex flex-row px-6 py-4 justify-between">
                      <div className="flex flex-row gap-4">
                        <div className="bg-highlight-blue w-fit py-4 px-5 rounded-full">{" "}</div>
                        <div className="flex flex-col gap-1">
                          <p className="text-sm font-medium">{dispute?.complaintUUID}</p>
                          <p className="text-xs text-dash-grey">{dispute?.SubCategoryMaster?.categoryName}</p>
                        </div>
                      </div>
                      <p className="items-center text-xs hidden group-hover:flex">{titleCase(dispute?.status)}</p>
                      <div>
                      </div>
                    </div>
                  )
                })}
            </div>
          </div>
          <div className="flex flex-col gap-8 w-3/5 ml-8 h-full">
            <div className="flex flex-col h-4/5  gap-4 px-4 py-4 w-full shadow bg-white border border-border-grey rounded-xl">
              <p className="text-lg font-semibold">{t('DisputesFiledOverTime')}</p>
              <div className="h-full">
                {labels?.length > 0 && <Line aria-label="Area Graph" options={options} data={graphData} />}
              </div>
            </div>
            <div className="flex flex-row w-full h-1/5">
              <div className="bg-white w-1/3 mr-8 h-fit shadow border border-border-grey justify-between rounded-lg p-4 flex flex-col gap-4">
                <p className="text-sm text-dash-grey font-semibold">{t('ReferredToODR')}</p>
                <div className="flex flex-col">
                  <p className="font-semibold text-2xl">{dashboardReport?.complainODRCount}</p>
                  <p className="text-xs text-dash-grey">{t('Disputes')}</p>
                </div>
              </div>
              <div className="bg-white w-1/3 mr-8 h-fit shadow border border-border-grey justify-between rounded-lg p-4 flex flex-col gap-4">
                <p className="text-sm text-dash-grey font-semibold">{t('TotalClaimValue')}</p>
                <div className="flex flex-col">
                  <p className="font-semibold text-2xl">₹{formatNumber(dashboardReport?.claimTotal)}</p>
                  <p className="text-xs text-dash-grey">{t('Aggregate')}</p>
                </div>
              </div>
              <div className="bg-white w-1/3 h-fit shadow border border-border-grey justify-between rounded-lg p-4 flex flex-col gap-4">
                <p className="text-sm text-dash-grey font-semibold">{t('TopDisputeRegions')}</p>
                <div className="flex flex-col">
                  <p className="font-semibold text-2xl">{dashboardReport?.topDisputeRegion?.city}</p>
                  <p className="text-xs text-dash-grey">{dashboardReport?.topDisputeRegion?.totalCount} {t('Disputes')}</p>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div className="flex sm:hidden flex-col h-96 gap-4 px-4 py-4 w-full shadow bg-white border border-border-grey rounded-xl">
          <p>{t('DisputesFiledOverTime')}</p>
          <div className="h-96">
            {labels?.length > 0 && <Line aria-label="Area Graph" options={options} data={graphData} />}
          </div>
        </div>
        <div className="sm:hidden flex flex-col h-4/5 shadow border border-border-grey bg-white rounded-lg">
          <div className="px-6 pt-6 pb-4 flex flex-row justify-between">
            <p className="text-lg font-semibold">{t('RecentDisputes')}</p>
          </div>
          <div className="px-2 flex flex-col gap-2 overflow-hidden overflow-y-auto">
            {dashboardReport?.latestDisputes?.map(
              (dispute: Complain) => {
                return (
                  <div className="group hover:bg-dash-highblue rounded-lg flex flex-row px-6 py-4 justify-between">
                    <div className="flex flex-row gap-4">
                      <div className="bg-highlight-blue w-8 h-8  rounded-full">{" "}</div>
                      <div className="flex flex-col gap-1">
                        <p className="text-sm font-medium">{dispute?.complaintUUID}</p>
                        <p className="text-xs text-dash-grey">{dispute?.SubCategoryMaster?.categoryName}</p>
                      </div>
                    </div>
                    <p className="items-center text-xs hidden group-hover:flex">{titleCase(dispute?.status)}</p>
                    <div>
                    </div>
                  </div>
                )
              })}
          </div>
        </div>
        <div className="flex sm:hidden flex-col gap-8 w-full">
          <div className="bg-white w-full mr-8 shadow border border-border-grey justify-between rounded-lg p-4 flex flex-col gap-4">
            <p className="text-sm text-dash-grey font-semibold">{t('ReferredToODR')}</p>
            <div className="flex flex-col">
              <p className="font-semibold text-2xl">{dashboardReport?.complainODRCount}</p>
              <p className="text-xs text-dash-grey">{t('Disputes')}</p>
            </div>
          </div>
          <div className="bg-white w-full mr-8 shadow border border-border-grey justify-between rounded-lg p-4 flex flex-col gap-4">
            <p className="text-sm text-dash-grey font-semibold">{t('TotalClaimValue')}</p>
            <div className="flex flex-col">
              <p className="font-semibold text-2xl">₹{formatNumber(dashboardReport?.claimTotal)}</p>
              <p className="text-xs text-dash-grey">{t('Aggregate')}</p>
            </div>
          </div>
          <div className="bg-white w-full shadow border border-border-grey justify-between rounded-lg p-4 flex flex-col gap-4">
            <p className="text-sm text-dash-grey font-semibold">{t('TopDisputeRegions')}</p>
            <div className="flex flex-col">
              <p className="font-semibold text-2xl">{dashboardReport?.topDisputeRegion?.city}</p>
              <p className="text-xs text-dash-grey">{dashboardReport?.topDisputeRegion?.totalCount} {t('Disputes')}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
