import { yupResolver } from "@hookform/resolvers/yup"
import { Fragment, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { Link, useNavigate } from "react-router-dom"
import { ApiController } from "../../../apiController"
import { ODRFormValidationSchema } from "../../../helpers/validation"
import { useCombinedStore } from "../../../store"
import { ODRInfoBank, User } from "../../../swagger/models"
import { notifyError } from '../../../helpers/helper'
import { saveAs } from "file-saver"
import { ageRange, languages, StateType } from "../../common-module/helper"
import { useTranslation } from 'react-i18next';

function ODRInfoBankD() {
  const { t } = useTranslation();
  const [odrDetail, setOdrDetails] = useState<ODRInfoBank>({})
  const [openTab, setOpenTab] = useState(1)
  const [userDetail, setUserDetails] = useState<User>({})
  const [demographicFile, setDemographicFile] = useState()
  const currentURL = window.location.href.split('/')[3]

  const odrInfoBankPayload = useCombinedStore((state: any) => state.updateODRInfoBank);
  const uploadOdrDemographicFile = useCombinedStore((state: any) => state.uploadOdrDemographic)
  const fetchOdrHandbook = useCombinedStore((state: any) => state.getODRHandBook)
  const navigate = useNavigate()

  const formOptions = {
    resolver: yupResolver(ODRFormValidationSchema),
  }

  const { register, handleSubmit, formState, reset } = useForm(formOptions)
  const { errors } = formState

  /**
   * 
   * @param e 
   */
  const onInputChange = (e: any) => {
    const { name, value } = e.target
    setOdrDetails({
      ...odrDetail,
      [name]: value,
    })
  }

  /**
   * Function to update the State related information
   * @param e Event object
   * @param type Type of Service
   */
  const cityInputChange = (e: any, type: string) => {
    const { name, value } = e.target
    const cityData = type === 'Arbitrators' ? (odrDetail?.cityWiseArbitrators || []) : (odrDetail?.cityWiseConciliators || [])
    if (cityData && cityData.length > 0) {
      const index = cityData.findIndex((i: any) => i.city === name)
      if (index > -1) {
        cityData[index].count = value
      } else {
        cityData.push({
          city: name,
          count: value
        })
      }
    } else {
      cityData.push({
        city: name,
        count: value
      })
    }

    if (type === 'Arbitrators') {
      setOdrDetails({
        ...odrDetail,
        cityWiseArbitrators: cityData
      })
    } else {
      setOdrDetails({
        ...odrDetail,
        cityWiseConciliators: cityData
      })
    }
  }

  /**
   * Function to update the Language related information
   * @param e Event object
   * @param type Type of Service
   */
  const languageInputChange = (e: any, type: string) => {
    const { name, value } = e.target
    const languageData = type === 'Arbitrators' ? (odrDetail?.languageDistributionArbitrators || []) : (odrDetail?.languageDistributionConciliators || [])
    if (languageData && languageData.length > 0) {
      const index = languageData.findIndex((i: any) => i.language === name)
      if (index > -1) {
        languageData[index].count = value
      } else {
        languageData.push({
          language: name,
          count: value
        })
      }
    } else {
      languageData.push({
        language: name,
        count: value
      })
    }

    if (type === 'Arbitrators') {
      setOdrDetails({
        ...odrDetail,
        languageDistributionArbitrators: languageData
      })
    } else {
      setOdrDetails({
        ...odrDetail,
        languageDistributionConciliators: languageData
      })
    }
  }

  /**
   * Function to update the age related information
   * @param e Event object
   * @param type Type of Service
   */
  const ageInputChange = (e: any, type: string) => {
    const { name, value } = e.target
    const ageData = type === 'Arbitrators' ? (odrDetail?.ageDistributionArbitrators || []) : (odrDetail?.ageDistributionConciliators || [])
    if (ageData && ageData.length > 0) {
      const index = ageData.findIndex((i: any) => i.age === name)
      if (index > -1) {
        ageData[index].count = value
      } else {
        ageData.push({
          age: name,
          count: value
        })
      }
    } else {
      ageData.push({
        age: name,
        count: value
      })
    }
    if (type === 'Arbitrators') {
      setOdrDetails({
        ...odrDetail,
        ageDistributionArbitrators: ageData
      })
    } else {
      setOdrDetails({
        ...odrDetail,
        ageDistributionConciliators: ageData
      })
    }
  }

  /**
   * Function to update the gender related information
   * @param e Event object
   * @param type Type of Service
   */
  const onGenderChange = (e: any, type: string) => {
    const { name, value } = e.target
    const genderData = type === 'Arbitrators' ? (odrDetail?.genderDistributionArbitrators || {}) : (odrDetail?.genderDistributionConciliators)
    if (genderData) {
        genderData[name]  = value
    }
    if (type === 'Arbitrators') {
      setOdrDetails({
        ...odrDetail,
        genderDistributionArbitrators: genderData
      })
    } else {
      setOdrDetails({
        ...odrDetail,
        genderDistributionConciliators: genderData
      })
    }
  }

  /**
   * 
   */
  useEffect(() => {
    ApiController.getInstance().user$.subscribe((user: any) => {
      if (user) {
        setUserDetails(user)
      }
    });
    fetchOdrHandbook().then((handbook: any) => {
      reset(handbook.data)
      setOdrDetails(handbook.data)
    })
  }, [])

  function downloadExcel() {
    fetch(`$}/logos/Demographic Data for ODR Infobank (1).xlsx`, {
      method: 'GET',
      headers: {
        Authorization: localStorage.getItem('TOKEN') || '',
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then((response) => {
        return response.blob()
      })
      .then((blob) => {
        saveAs(blob, 'SampleDemographicData.xlsx', { autoBom: false })
      })
      .catch((error) => {
        console.log(error)
      })
  }

  /**
   * 
   * @param e 
   * @returns 
   */
  async function onSubmit(e: any) {
    odrInfoBankPayload({ odrDetail })
  }

  const handleFileUpload = (e: any) => {
    const file = e.target.files[0]; // Get the first file from the list
    const formData = new FormData();
    formData.append('file', file);
    if (file.size > 20 * 1000 * 1024) {
      notifyError(t('UploadFileExceeds'))
      return false;
    }
    uploadOdrDemographicFile(file)
    setDemographicFile(file.name)
  }

  const redirect = () => {
    navigate(`/${currentURL}/settings`)
  }

  return (
    <div className="pt-7 w-full h-full min-h-screen pr-4 pl-1 pb-7">
      <div
        style={{ background: '#F4F4F4' }}
        className="flex w-full h-full flex-initial  px-8 py-7 flex-col gap-2 rounded-2xl">
        <p className='text-xs sm:block hidden text-back-gray'><Link to={`/${currentURL}/settings`}>{t('Settings')}</Link> &gt; {t('ODRInstitutionInfobank')} &gt; {t('EditData')}</p>
        <h1 className="sm:text-3xl text-lg font-semibold text-highlight-blue pb-3">{t('Settings')}</h1>
        <div className="bg-white w-full h-full flex flex-col rounded-xl gap-4">
          <Link to={`/${currentURL}/settings`} className="py-3 border-b border-F5F5F5 text-xs pl-7">{t('Back')}</Link>
          <div className="flex flex-row pb-4 gap-3 pl-7 border-b border-F5F5F5">
            <i className="ph-bold ph-buildings sm:p-3 p-2 border border-F5F5F5 rounded-full"></i>
            <div className="flex flex-col sm:gap-2 gap-1">
              <p className="sm:text-sm text-xs">{t('ODRInstitutionInfobank')}</p>
              <p className="sm:text-xs text-[10px] text-back-gray">{t('EditData')}</p>
            </div>
          </div>
            <div className="flex flex-row border-b px-8 sm:gap-12 gap-5 border-b-low-grey">

              <div onClick={() => setOpenTab(1)} className={`${openTab === 1 ? 'flex flex-row gap-2 items-center text-highlight-blue border-b-2 border-b-highlight-blue cursor-pointer text-left sm:text-sm text-xs w-fit sm:py-3 pt-5' : 'flex flex-row gap-2 items-center sm:text-sm text-xs w-fit sm:py-3 pt-5 text-label-grey text-left hover:text-highlight-blue hover:border-highlight-blue hover:border-b-2 cursor-pointer'}`}>
                <p>{t('ODRInfo')}</p>
              </div>
              <div onClick={() => setOpenTab(2)} className={`${openTab === 2 ? 'flex flex-row gap-2 items-center text-highlight-blue border-b-2 border-b-highlight-blue cursor-pointer text-left sm:text-sm text-xs w-fit sm:py-3 pt-5' : 'flex flex-row gap-2 items-center sm:text-sm text-xs w-fit sm:py-3 pt-5 text-label-grey text-left hover:text-highlight-blue hover:border-highlight-blue hover:border-b-2 cursor-pointer'}`}>
                <p>{t('Age')}</p>
              </div>
              <div onClick={() => setOpenTab(3)} className={`${openTab === 3 ? 'flex flex-row gap-2 items-center text-highlight-blue border-b-2 border-b-highlight-blue cursor-pointer text-left sm:text-sm text-xs w-fit sm:py-3 pt-5' : 'flex flex-row gap-2 items-center sm:text-sm text-xs w-fit sm:py-3 pt-5 text-label-grey text-left hover:text-highlight-blue hover:border-highlight-blue hover:border-b-2 cursor-pointer'}`}>
                <p>{t('Gender')}</p>
              </div>
              <div onClick={() => setOpenTab(4)} className={`${openTab === 4 ? 'flex flex-row gap-2 items-center text-highlight-blue border-b-2 border-b-highlight-blue cursor-pointer text-left sm:text-sm text-xs w-fit sm:py-3 pt-5' : 'flex flex-row gap-2 items-center sm:text-sm text-xs w-fit sm:py-3 pt-5 text-label-grey text-left hover:text-highlight-blue hover:border-highlight-blue hover:border-b-2 cursor-pointer'}`}>
                <p>{t('Language')}</p>
              </div>
              <div onClick={() => setOpenTab(5)} className={`${openTab === 5 ? 'flex flex-row gap-2 items-center text-highlight-blue border-b-2 border-b-highlight-blue cursor-pointer text-left sm:text-sm text-xs w-fit sm:py-3 pt-5' : 'flex flex-row gap-2 items-center sm:text-sm text-xs w-fit sm:py-3 pt-5 text-label-grey text-left hover:text-highlight-blue hover:border-highlight-blue hover:border-b-2 cursor-pointer'}`}>
                <p>{t('State')}</p>
              </div>
            </div>
          {openTab === 1 && (
            <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-row w-full justify-between gap-8">
              <div className="flex flex-col gap-2 px-7 sm:w-2/3">
                <label htmlFor="totalConciliators" className="text-xs">{t('EnterCountTotalMediators')}</label>
                <input
                  {...register('totalConciliators')}
                  value={odrDetail.totalConciliators}
                  onChange={onInputChange} required aria-label={t('EnterCountTotalMediators')} type="number" name='totalConciliators' id='totalConciliators' className={`rounded-xl border text-xs p-2 border-solid ${errors.totalConciliators?.message ? 'border-highlight-red' : 'border-border-grey'}`} />
                <span className="text-xs text-highlight-red">{errors.totalConciliators?.message}</span>

                <label htmlFor="totalArbitrators" className="text-xs">{t('EnterCountTotalArbitrators')}</label>
                <input
                  {...register('totalArbitrators')}
                  value={odrDetail.totalArbitrators}
                  onChange={onInputChange} required aria-label={t('EnterCountTotalArbitrators')} type="number" name='totalArbitrators' id='totalArbitrators' className={`rounded-xl border p-2 text-xs border-solid ${errors.totalArbitrators?.message ? 'border-highlight-red' : 'border-border-grey'}`} />
                <span className="text-xs text-highlight-red">{errors.totalArbitrators?.message}</span>

                <label htmlFor="empanelmentUrl" className="text-xs">{t('URLEmpanel')}</label>
                <input
                  {...register('empanelmentUrl')}
                  value={odrDetail.empanelmentUrl}
                  onChange={onInputChange} required aria-label={t('URLEmpanel')} type="text" name='empanelmentUrl' id='empanelmentUrl' className={`rounded-xl text-xs border p-2 border-solid ${errors.empanelmentUrl?.message ? 'border-highlight-red' : 'border-border-grey'}`} />
                <span className="text-xs text-highlight-red">{errors.empanelmentUrl?.message}</span>

                <label htmlFor="grievanceUrl" className="text-xs">{t('URLGrievance')}</label>
                <input
                  {...register('grievanceUrl')}
                  value={odrDetail.grievanceUrl}
                  onChange={onInputChange} required aria-label={t('URLGrievance')} type="text" name='grievanceUrl' id='grievanceUrl' className={`rounded-xl border p-2 text-xs border-solid ${errors.grievanceUrl?.message ? 'border-highlight-red' : 'border-border-grey'}`} />
                <span className="text-xs text-highlight-red">{errors.grievanceUrl?.message}</span>

                <label htmlFor="rulesUrl" className="text-xs">{t('URLRules')}</label>
                <input
                  {...register('rulesUrl')}
                  value={odrDetail.rulesUrl}
                  onChange={onInputChange} required aria-label={t('URLRules')} type="text" name='rulesUrl' id='rulesUrl' className={`rounded-xl border p-2 text-xs border-solid ${errors.rulesUrl?.message ? 'border-highlight-red' : 'border-border-grey'}`} />
                <span className="text-xs text-highlight-red">{errors.rulesUrl?.message}</span>

              </div>
              <div className="pr-8 flex w-1/3  flex-col gap-4">
                <p className="text-sm">{t('UploadDemographicData')}</p>
                <label htmlFor='upload' className="rounded-lg border-2 border-dashed border-border-grey flex flex-col h-full justify-center items-center cursor-pointer">
                  <i className="ph ph-plus text-9xl text-border-grey"></i>
                  <p className='text-center text-border-grey'>{t('UploadCSV')}</p>
                  <input onChange={handleFileUpload} aria-label={t('Choosefile')} type="file" id='upload' style={{ display: 'none' }} accept=".xlsx, .csv" />
                </label>
                {odrDetail?.files && <p className="text-xs">{t('Uploaded')}: {odrDetail?.files?.fileName}</p>}

                <p className="text-xs">{t('UploadDemographicMediators')}</p>
                <a className="text-xs hover:underline" style={{ color: '#306AFF' }} href="https://ocp-samples.s3.ap-south-1.amazonaws.com/Demographic+Data+for+ODR+Infobank+(2).xlsx">{t('DownloadExcelTemplateDemographicData')}</a>
              </div>
            </div>

            <div className="flex flex-row gap-3 pl-7 pt-2">
              <button type="submit" className="hover:bg-highlight-blue/[0.4] bg-highlight-blue items-center text-white flex flex-row gap-2 text-xs px-12 py-2.5 rounded-lg disabled:bg-highlight-blue/[0.4]" aria-label={t('Save')}>
                {t('Save')}
              </button>
              <button onClick={redirect} type="button" aria-label={t('cancel')} className=" hover:bg-black/[0.4] text-xs  text-back-gray px-8 py-2.5 rounded-lg">
              {t('cancel')}
              </button>
            </div>
          </form>
          )}
          {openTab === 2 && (
            <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-row w-full justify-between gap-8">
              <div className="flex flex-col gap-2 px-7 sm:w-1/2">
                {ageRange?.map((age: string) => {
                  return <Fragment key={age}>
                    <label htmlFor={`${age}`} className="text-xs">{t('CountMediatorsAged', {age})}</label>
                    <input
                      value={odrDetail.ageDistributionConciliators?.find((i: any) => i.age === age)?.count}
                      onChange={(e) => ageInputChange(e, 'Mediators')} aria-label={`${t('CountMediatorsAged', {age})}`} type="number" name={`${age}`} id={`${age}`} className="rounded-xl border text-xs p-2 border-solid border-border-grey" />
                  </Fragment>
                })}
              </div>
              <div className="flex w-1/2 pr-7 flex-col gap-2">
                {ageRange?.map((age: string) => {
                  return <Fragment key={age}>
                    <label htmlFor={`${age}`} className="text-xs">{t('CountArbitratorsAged', {age})}</label>
                    <input
                      value={odrDetail.ageDistributionArbitrators?.find((i: any) => i.age === age)?.count}
                      onChange={(e) => ageInputChange(e, 'Arbitrators')} aria-label={`${t('CountArbitratorsAged', {age})}`} type="number" name={`${age}`} id={`${age}`} className="rounded-xl border text-xs p-2 border-solid border-border-grey" />
                  </Fragment>
                })}
              </div>
            </div>

            <div className="flex flex-row gap-3 pl-7 pt-2">
              <button type="submit" className="hover:bg-highlight-blue/[0.4] bg-highlight-blue items-center text-white flex flex-row gap-2 text-xs px-12 py-2.5 rounded-lg disabled:bg-highlight-blue/[0.4]" aria-label={t('Save')}>
                {t('Save')}
              </button>
              <button onClick={redirect} type="button" aria-label={t('cancel')} className=" hover:bg-black/[0.4] text-xs  text-back-gray px-8 py-2.5 rounded-lg">
              {t('cancel')}
              </button>
            </div>
          </form>
          )}

          {openTab === 3 && (
            <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-row w-full justify-between gap-8">
              <div className="flex flex-col gap-2 px-7 sm:w-1/2">
                <label htmlFor="male" className="text-xs">{t('CountMaleMediators')}</label>
                <input
                  value={odrDetail?.genderDistributionConciliators?.male}
                  onChange={(e) => onGenderChange(e, 'Mediators')} required aria-label={t('CountMaleMediators')} type="number" name='male' id='male' className={`rounded-xl border text-xs p-2 border-solid 'border-border-grey'}`} />

                <label htmlFor="female" className="text-xs">{t('CountFemaleMediators')}</label>
                <input
                  value={odrDetail.genderDistributionConciliators?.female}
                  onChange={(e) => onGenderChange(e, 'Mediators')} required aria-label={t('CountFemaleMediators')} type="number" name='female' id='female' className={`rounded-xl border p-2 text-xs border-solid 'border-border-grey'}`} />

                <label htmlFor="other" className="text-xs">{t('CountMediatorsOtherGenders')}</label>
                <input
                  value={odrDetail.genderDistributionConciliators?.other}
                  onChange={(e) => onGenderChange(e, 'Mediators')} required aria-label={t('CountMediatorsOtherGenders')} type="number" name='other' id='other' className={`rounded-xl text-xs border p-2 border-solid 'border-border-grey'}`} />
              </div>
              <div className="flex w-1/2 pr-7 flex-col gap-2">
                <label htmlFor="male" className="text-xs">{t('CountMaleArbitrators')}</label>
                <input
                  value={odrDetail?.genderDistributionArbitrators?.male}
                  onChange={(e) => onGenderChange(e, 'Arbitrators')} required aria-label={t('CountMaleArbitrators')} type="number" name='male' id='male' className={`rounded-xl border text-xs p-2 border-solid'border-border-grey'}`} />

                <label htmlFor="female" className="text-xs">{t('CountFemaleArbitrators')}</label>
                <input
                  value={odrDetail?.genderDistributionArbitrators?.female}
                  onChange={(e) => onGenderChange(e, 'Arbitrators')} required aria-label={t('CountFemaleArbitrators')} type="number" name='female' id='female' className={`rounded-xl border p-2 text-xs border-solid 'border-border-grey'}`} />

                <label htmlFor="other" className="text-xs">{t('CountArbitratorsOtherGenders')}</label>
                <input
                  value={odrDetail?.genderDistributionArbitrators?.other}
                  onChange={(e) => onGenderChange(e, 'Arbitrators')} required aria-label={t('CountArbitratorsOtherGenders')} type="number" name='other' id='other' className={`rounded-xl text-xs border p-2 border-solid 'border-border-grey'}`} />
              </div>
            </div>

            <div className="flex flex-row gap-3 pl-7 pt-2">
              <button type="submit" className="hover:bg-highlight-blue/[0.4] bg-highlight-blue items-center text-white flex flex-row gap-2 text-xs px-12 py-2.5 rounded-lg disabled:bg-highlight-blue/[0.4]" aria-label={t('Save')}>
                {t('Save')}
              </button>
              <button onClick={redirect} type="button" aria-label={t('cancel')} className=" hover:bg-black/[0.4] text-xs  text-back-gray px-8 py-2.5 rounded-lg">
              {t('cancel')}
              </button>
            </div>
          </form>
          )}

          {openTab === 4 && (
            <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-row w-full justify-between gap-8">
              <div className="flex flex-col gap-2 px-7 sm:w-1/2">
                {languages?.map((language, index) => {
                  return <>
                    <label htmlFor={`${language}`} className="text-xs">{t('CountMediatorsSpeak', {language})}</label>
                    <input
                      value={odrDetail.languageDistributionConciliators?.find((i: any) => i.language === language)?.count}
                      onChange={(e) => languageInputChange(e, 'Mediators')} aria-label={`${t('CountMediatorsSpeak', {language})}`} type="number" name={`${language}`} id={`${language}`} className="rounded-xl border text-xs p-2 border-solid border-border-grey" />
                  </>
                })}
              </div>
              <div className="flex w-1/2 pr-7 flex-col gap-2">
                {languages?.map((language, index) => {
                  return <>
                    <label htmlFor={`${language}`} className="text-xs">{t('CountArbitratorsSpeak', {language})}</label>
                    <input
                      value={odrDetail.languageDistributionArbitrators?.find((i: any) => i.language === language)?.count}
                      onChange={(e) => languageInputChange(e, 'Arbitrators')} aria-label={`${t('CountArbitratorsSpeak', {language})}`} type="number" name={`${language}`} id={`${language}`} className="rounded-xl border text-xs p-2 border-solid border-border-grey" />
                  </>
                })}
              </div>
            </div>

            <div className="flex flex-row gap-3 pl-7 pt-2">
              <button type="submit" className="hover:bg-highlight-blue/[0.4] bg-highlight-blue items-center text-white flex flex-row gap-2 text-xs px-12 py-2.5 rounded-lg disabled:bg-highlight-blue/[0.4]" aria-label={t('Save')}>
                {t('Save')}
              </button>
              <button onClick={redirect} type="button" aria-label={t('cancel')} className=" hover:bg-black/[0.4] text-xs  text-back-gray px-8 py-2.5 rounded-lg">
              {t('cancel')}
              </button>
            </div>
          </form>
          )}
          {openTab === 5 && (
            <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-row w-full justify-between gap-8">
              <div className="flex flex-col gap-2 px-7 sm:w-1/2">
                {StateType?.map((state: string) => {
                  return <Fragment key={state}>
                    <label htmlFor={`${state}`} className="text-xs">{t('CountMediatorsIn', {state})} </label>
                    <input
                      value={odrDetail.cityWiseConciliators?.find((i: any) => i.city === state)?.count}
                      onChange={(e) => cityInputChange(e, 'Mediators')} aria-label={`${t('CountMediatorsIn', {state})}`} type="number" name={`${state}`} id={`${state}`} className="rounded-xl border text-xs p-2 border-solid border-border-grey" />
                  </Fragment>
                })}
              </div>
              <div className="flex w-1/2 pr-7 flex-col gap-2">
                {StateType?.map((state: string) => {
                  return <Fragment key={state}>
                    <label htmlFor={`${state}`} className="text-xs">{t('CountArbitratorsIn', {state})}</label>
                    <input
                      value={odrDetail.cityWiseArbitrators?.find((i: any) => i.city === state)?.count}
                      onChange={(e) => cityInputChange(e, 'Arbitrators')} aria-label={`${t('CountArbitratorsIn', {state})}`} type="number" name={`${state}`} id={`${state}`} className="rounded-xl border text-xs p-2 border-solid border-border-grey" />
                  </Fragment>
                })}
              </div>
            </div>

            <div className="flex flex-row gap-3 pl-7 pt-2">
              <button type="submit" className="hover:bg-highlight-blue/[0.4] bg-highlight-blue items-center text-white flex flex-row gap-2 text-xs px-12 py-2.5 rounded-lg disabled:bg-highlight-blue/[0.4]" aria-label={t('Save')}>
                {t('Save')}
              </button>
              <button onClick={redirect} type="button" aria-label={t('cancel')} className=" hover:bg-black/[0.4] text-xs  text-back-gray px-8 py-2.5 rounded-lg">
              {t('cancel')}
              </button>
            </div>
          </form>
          )}
          <p className="pb-3 text-xs pl-7">{" "}</p>
        </div>
      </div>
    </div>
  )
}

export default ODRInfoBankD