import { Tooltip as Tool } from 'react-tippy';
import { dateTimeHelper } from './common-module/helper';
import { useEffect, useState } from 'react';
import { MapContainer, GeoJSON, CircleMarker, Tooltip as CirTool } from "react-leaflet";
import mapDataFeatures from '../helpers/indiageo.json';
import _, { toUpper } from 'lodash';
import { ODRInfoBank, ODRInfoBankOutput } from '../swagger/models';
import { ApiController } from '../apiController';
import { useTranslation } from 'react-i18next';

const mapData = {
  "type": "FeatureCollection" as const,
  "features": mapDataFeatures
}
import cityData from "../../state.json"
import { notifyError } from '../helpers/helper';

function OdrDashboard() {
  const { t } = useTranslation();
  const [disputeType, setDisputeType] = useState("Mediation")
  const [infobank, setInfobank] = useState<ODRInfoBankOutput>({})
  const [odrRegions, setODRRegions] = useState<any[]>([]);
  const [languageModal, setLanguageModal] = useState(false)
  const [odrInfoBankDetail, setODRInfoBankDetail] = useState<ODRInfoBank>({})
  const [isTotal, setIsTotal] = useState(true)

  useEffect(() => {
    if (isTotal) {
      setODRInfoBankDetail({});
    }
  }, [isTotal]);

  useEffect(() => {
    if (isTotal && Object.keys(odrInfoBankDetail).length !== 0) {
      setIsTotal(false);
    }
  }, [odrInfoBankDetail, isTotal]);

  const [ageDistribution, setAgeDistribution] = useState<any>([])
  const [genderDistribution, setGenderDistribution] = useState<any>({})
  const [languageDistribution, setLanguageDistribution] = useState<any>([])
  const [allLanguageDistribution, setAllLanguageDistribution] = useState<any>([])
  const [cityDistribution, setCityDistribution] = useState<any>([])

  const fetchReport = async () => {
    try {
      const categoryReport = (await ApiController.getInstance().indexApi.odrInfobankReport(
        odrInfoBankDetail
      )).data;
      setInfobank(categoryReport)
      if (disputeType === 'Mediation') {
        setAgeDistribution(categoryReport?.ageDistributionConciliators)
        setGenderDistribution(categoryReport?.genderDistributionConciliators)
        setLanguageDistribution((_.orderBy(_.map(categoryReport?.languageDistributionConciliators, obj => ({
          ...obj,
          count: parseInt(obj.count) // Convert to integer
        })), ['count'], ['desc'])).slice(0, 5))
        setCityDistribution(categoryReport?.cityWiseConciliators)
      } else {
        setAgeDistribution(categoryReport?.ageDistributionArbitrators)
        setGenderDistribution(categoryReport?.genderDistributionArbitrators)
        setLanguageDistribution((_.orderBy(_.map(categoryReport?.languageDistributionArbitrators, obj => ({
          ...obj,
          count: parseInt(obj.count) // Convert to integer
        })), ['count'], ['desc'])).slice(0, 5))
        setCityDistribution(categoryReport?.cityWiseArbitrators)
      }
    } catch (error: any) {
      setInfobank({})
      setAgeDistribution([])
      setGenderDistribution([])
      setCityDistribution([])
      setLanguageDistribution([])
      notifyError(error?.response?.data?.message);
    }
  }

  const fetchAggregateReport = async () => {
    try {
      const categoryReport = (await ApiController.getInstance().indexApi.odrInfobankAggregateReport(
        odrInfoBankDetail
      )).data;
      setInfobank(categoryReport)
      if (disputeType === 'Mediation') {
        setAgeDistribution(categoryReport?.ageDistributionConciliators)
        setGenderDistribution(categoryReport?.genderDistributionConciliators)
        setLanguageDistribution((_.orderBy(_.map(categoryReport?.languageDistributionConciliators, obj => ({
          ...obj,
          count: parseInt(obj.count) // Convert to integer
        })), ['count'], ['desc'])).slice(0, 5))
        setAllLanguageDistribution((_.orderBy(_.map(categoryReport?.languageDistributionConciliators, obj => ({
          ...obj,
          count: parseInt(obj.count) // Convert to integer
        })), ['count'], ['desc'])))
        setCityDistribution(categoryReport?.cityWiseConciliators)
      } else {
        setAgeDistribution(categoryReport?.ageDistributionArbitrators)
        setGenderDistribution(categoryReport?.genderDistributionArbitrators)
        setLanguageDistribution((_.orderBy(_.map(categoryReport?.languageDistributionArbitrators, obj => ({
          ...obj,
          count: parseInt(obj.count) // Convert to integer
        })), ['count'], ['desc'])).slice(0, 5))
        setAllLanguageDistribution((_.orderBy(_.map(categoryReport?.languageDistributionArbitrators, obj => ({
          ...obj,
          count: parseInt(obj.count) // Convert to integer
        })), ['count'], ['desc'])))
        setCityDistribution(categoryReport?.cityWiseArbitrators)
      }
    } catch (error: any) {
      setInfobank({})
      setAgeDistribution([])
      setGenderDistribution([])
      setCityDistribution([])
      setLanguageDistribution([])
      notifyError(error?.response?.data?.message);
    }
  }

  const fetchCoordinates = async () => {
    const topDisputeRegionCopy = [];

    for (const city of cityDistribution) {
      // Replace "null" with "Delhi" if the city name is null
      const cityName = city.city == "null" ? "Delhi" : city.city;

      const data = cityData.find((c: any) => c.state === cityName)

      if (data && parseInt(city.count, 10) > 0) {
        const lat = data?.coordinates[0];
        const lng = data?.coordinates[1];
        city.coordinates = [lat, lng];
        topDisputeRegionCopy.push({
          city: city.city,
          coordinates: [lat, lng],
          totalCount: city.count
        })
      }
    }
    setODRRegions(topDisputeRegionCopy);
  };


  useEffect(() => {
    fetchCoordinates();
  }, [cityDistribution]);

  useEffect(() => {
    if (odrInfoBankDetail.orgId) {
      fetchReport()
    } else {
      fetchAggregateReport()
    }
  }, [odrInfoBankDetail])

  useEffect(() => {
    if (disputeType === 'Mediation') {
      setAgeDistribution(infobank?.ageDistributionConciliators)
      setGenderDistribution(infobank?.genderDistributionConciliators)
      setLanguageDistribution((_.orderBy(_.map(infobank?.languageDistributionConciliators, obj => ({
        ...obj,
        count: parseInt(obj.count) // Convert to integer
      })), ['count'], ['desc'])).slice(0, 5))
      setAllLanguageDistribution((_.orderBy(_.map(infobank?.languageDistributionConciliators, obj => ({
        ...obj,
        count: parseInt(obj.count) // Convert to integer
      })), ['count'], ['desc'])))
      setCityDistribution(infobank?.cityWiseConciliators)

    } else {
      setAgeDistribution(infobank?.ageDistributionArbitrators)
      setGenderDistribution(infobank?.genderDistributionArbitrators)
      setCityDistribution(infobank?.cityWiseArbitrators)
      setLanguageDistribution((_.orderBy(_.map(infobank?.languageDistributionArbitrators, obj => ({
        ...obj,
        count: parseInt(obj.count) // Convert to integer
      })), ['count'], ['desc'])).slice(0, 5))
      setAllLanguageDistribution((_.orderBy(_.map(infobank?.languageDistributionArbitrators, obj => ({
        ...obj,
        count: parseInt(obj.count) // Convert to integer
      })), ['count'], ['desc'])))
    }
  }, [disputeType])

  return (
    <div className=" w-full sm:h-full min-h-screen h-full p-5">
      <div
        style={{ background: "#F4F4F4" }}
        className="flex w-full sm:h-full h-full px-8 py-7 flex-col gap-8 rounded-2xl"
      >
        <div className="flex flex-col-reverse text-center sm:text-left gap-5 items-center sm:items-start sm:flex-row sm:justify-between">
          <div className="flex flex-col gap-1">
            <div className="flex flex-row gap-2 items-center">
              <p className="sm:text-4xl text-2xl font-semibold">{t('ODRInstitutionInfobank')}</p>
              <Tool
                title={t('ViewRelevantStats')}
                position="right"
                trigger="click"
                arrow={true}
                animation="shift"
                {...({ children: <i className="ph-bold text-xl ph-info cursor-pointer"></i> } as any)} // Type assertion to bypass TS errors
              >
              </Tool>
            </div>

            <p className="text-xs text-back-gray">{t('LastUpdatedAt', {
              date: dateTimeHelper(new Date())
            })}</p>
            <p className="text-xs text-back-gray">{t('ClickODRInstitution')}</p>
          </div>
          <img src="https://ocp-module-development.s3.ap-south-1.amazonaws.com/SMARTODR_LOGO.svg" alt="SMARTODR Logo" style={{ width: '250px', height: 'fit' }} />
        </div>
        <div className='flex sm:flex-row flex-col gap-8 items-center pb-9'>
        <div onClick={() => {
            setODRInfoBankDetail({});
            setIsTotal(true);
          }} className={`p-2 rounded-lg hover:bg-white cursor-pointer ${isTotal ? "bg-white" : ""}`}>
            <p className='text-lg font-medium'>{t('Aggregate')}</p>
          </div>
          <div onClick={() => {
            setODRInfoBankDetail({
              ...odrInfoBankDetail,
              orgId: 11
            })
            setIsTotal(false)
          }} className={`p-2 rounded-lg hover:bg-white cursor-pointer ${odrInfoBankDetail?.orgId === 11 ? "bg-white" : ""}`}>
            <img src="https://ocp-module-production.s3.ap-south-1.amazonaws.com/logos/CADRE+logo.png" alt="SMARTODR Logo" style={{ width: '114px', height: 'fit' }} />
          </div>
          <div onClick={() => {
            setODRInfoBankDetail({
              ...odrInfoBankDetail,
              orgId: 12
            })
            setIsTotal(false)
          }} className={`p-2 rounded-lg hover:bg-white cursor-pointer ${odrInfoBankDetail?.orgId === 12 ? "bg-white" : ""}`}>
            <img src="https://ocp-module-production.s3.ap-south-1.amazonaws.com/logos/CORD+ODR.png" alt="SMARTODR Logo" style={{ width: '115px', height: 'fit' }} />
          </div>
          <div onClick={() => {
            setODRInfoBankDetail({
              ...odrInfoBankDetail,
              orgId: 15
            })
            setIsTotal(false)
          }} className={`p-2 rounded-lg hover:bg-white cursor-pointer ${odrInfoBankDetail?.orgId === 15 ? "bg-white" : ""}`}>
            <img src="https://ocp-module-production.s3.ap-south-1.amazonaws.com/logos/Jupitice+Logo.png" alt="SMARTODR Logo" style={{ width: '111px', height: 'fit' }} />
          </div>
          <div onClick={() => {
            setODRInfoBankDetail({
              ...odrInfoBankDetail,
              orgId: 17
            })
            setIsTotal(false)
          }} className={`p-2 rounded-lg hover:bg-white cursor-pointer ${odrInfoBankDetail?.orgId === 17 ? "bg-white" : ""}`}>
            <img src="https://ocp-module-production.s3.ap-south-1.amazonaws.com/logos/JustAct+logo+wo+bg.png" alt="SMARTODR Logo" style={{ width: '60px', height: '39px' }} />
          </div>
          <div onClick={() => {
            setODRInfoBankDetail({
              ...odrInfoBankDetail,
              orgId: 9
            })
            setIsTotal(false)
          }} className={`p-2 rounded-lg hover:bg-white cursor-pointer ${odrInfoBankDetail?.orgId === 9 ? "bg-white" : ""}`}>
            <img src="https://ocp-module-production.s3.ap-south-1.amazonaws.com/logos/Presolv360.png" alt="SMARTODR Logo" style={{ width: '164px', height: 'fit' }} />
          </div>
          <div onClick={() => {
            setODRInfoBankDetail({
              ...odrInfoBankDetail,
              orgId: 13
            })
            setIsTotal(false)
          }} className={`p-2 rounded-lg hover:bg-white cursor-pointer ${odrInfoBankDetail?.orgId === 13 ? "bg-white" : ""}`}>
            <img src="https://ocp-module-production.s3.ap-south-1.amazonaws.com/logos/Sama_Logo+Plain.png" alt="SMARTODR Logo" style={{ width: '123px', height: 'fit' }} />
          </div>
          <div onClick={() => {
            setODRInfoBankDetail({
              ...odrInfoBankDetail,
              orgId: 7
            })
            setIsTotal(false)
          }} className={`p-2 rounded-lg hover:bg-white cursor-pointer ${odrInfoBankDetail?.orgId === 7 ? "bg-white" : ""}`}>
            <img src="https://ocp-module-production.s3.ap-south-1.amazonaws.com/logos/webnyay+logo.png" alt="SMARTODR Logo" style={{ width: '115px', height: 'fit' }} />
          </div>
        </div>
        <div className='flex flex-col gap-6'>
          <p className='font-semibold text-xl'>{t('AggregateDataConciliatorsArbitrators')}</p>
          <div className='flex flex-row'>
            <button className={`${disputeType === "Mediation" ? 'bg-border-grey text-black font-semibold' : ''} text-xs px-3 py-2 rounded-tl-lg rounded-bl-lg border bg-white border-border-grey hover:bg-back-gray`}
              onClick={() => {
                setDisputeType("Mediation")
              }}>{t('Conciliators')}</button>
            <button className={`${disputeType === "Arbitration" ? 'bg-border-grey text-black font-semibold' : ''} text-xs px-3 py-2 rounded-tr-lg rounded-br-lg border bg-white border-border-grey hover:bg-back-gray`}
              onClick={() => {
                setDisputeType("Arbitration")
              }}>{t('Arbitrators')}</button>
          </div>
        </div>
        <div className='flex h-fit sm:flex-row flex-col gap-8 sm:gap-0 justify-between'>
          <div className="h-screen sm:h-full sm:w-2/3 sm:mr-8 bg-white p-5 rounded-xl shadow border border-border-grey">
            {cityDistribution?.length > 0 && mapData?.features?.length > 0 &&
              <MapContainer
                className="h-full w-full rounded-xl"
                style={{ zIndex: '2' }}
                center={[22, 78.9629]}
                zoom={5}
                scrollWheelZoom={false}
                dragging={true}
              >
                <GeoJSON data={mapData}
                  style={{ fillColor: '#f3f3f3', fillOpacity: 1, color: '#eaeaea', weight: 0.7 }} />
                {
                  odrRegions?.map((city: any, index: number) => (
                    <CircleMarker
                      key={city.city}
                      center={city.coordinates} // Provide the coordinates for each city
                      radius={city.totalCount / 1} // Adjust the radius based on totalCount or any other factor
                      color="#221e65" // Set the color of the circle marker
                      fillOpacity={0.7} // Set the fill opacity
                      weight={0}
                    >
                      <CirTool>{city.city}: {city.totalCount}</CirTool>
                    </CircleMarker>
                  ))
                }

              </MapContainer>
            }

          </div>
          <div className='flex flex-col gap-7 sm:w-1/3'>
            <div className='bg-white rounded-xl flex flex-col gap-6 p-6 shadow border border-border-grey'>
              <p className='text-xl font-semibold'>{t('AgeDistribution')}</p>
              <div className='flex flex-col gap-2'>
                {ageDistribution?.map((ageDistributions: any, index: number) => {
                  const maxCount = Math.max(...ageDistribution.map((item: any) => parseInt(item.count, 10)));
                  const relativePercentage: number = ((parseInt(ageDistributions.count, 10) / maxCount) * 100) || (maxCount > 0 ? 0 : 100)
                  const barWidth = relativePercentage < 20 ? relativePercentage + 10 + '%' : relativePercentage + '%'
                  return <div key={ageDistributions?.age} className='py-1 px-2 flex justify-between items-center rounded-md' style={{ width: barWidth, background: 'linear-gradient(90deg, #DEDDE8 -2.57%, rgba(229, 238, 253, 0.00) 112.5%)' }}>
                    <p className='text-sm whitespace-nowrap mr-2'>{ageDistributions?.age}</p>
                    <p className='text-[10px]'>{ageDistributions?.count}</p>
                  </div>
                })}
              </div>
            </div>

            <div className='bg-white rounded-xl flex flex-col gap-6 p-6 shadow border border-border-grey'>
              <div className='flex flex-col gap-2'>
                <p className='text-xl font-semibold'>{t('LanguagesSpoken')}</p>
                <p onClick={() => setLanguageModal(true)} className="text-dash-blue text-sm items-center flex gap-3 h-full cursor-pointer">{t('ClickAllLanguages')} <i className="ph-bold ph-arrow-right"></i></p>
              </div>
              <div className='flex flex-col gap-2'>
                {languageDistribution?.map((languageDistributions: any, index: number) => {
                  const maxCount = Math.max(...languageDistribution.map((item: any) => parseInt(item.count, 10)));
                  const relativePercentage: number = ((parseInt(languageDistributions.count, 10) / maxCount) * 100) || (maxCount > 0 ? 0 : 100)
                  const barWidth = relativePercentage < 20 ? relativePercentage + 10 + '%' : relativePercentage + '%'
                  return <div key={languageDistributions?.language} className='py-1 px-2 flex justify-between items-center rounded-md' style={{ width: barWidth, background: 'linear-gradient(90deg, #DEDDE8 -2.57%, rgba(229, 238, 253, 0.00) 112.5%)' }}>
                    <p className='text-sm whitespace-nowrap mr-2'>{toUpper(languageDistributions?.language)}</p>
                    <p className='text-[10px]'>{languageDistributions?.count}</p>
                  </div>
                })}
              </div>
            </div>

            <div className='bg-white rounded-xl flex flex-col gap-6 p-6 shadow border border-border-grey'>
              <p className='text-xl font-semibold'>{t('SexDistribution')}</p>
              <div className='flex flex-col gap-2'>
                {genderDistribution && Object.entries(genderDistribution)?.map(([gender, count]: any, index) => {
                  // Calculate relative percentage
                  const maxGenderCount = Math.max(...(Object.values(genderDistribution) as string[]).map(count => parseInt(count, 10)));
                  const parsedCount: number = parseInt(count, 10);
                  const relativePercentage = ((parsedCount / maxGenderCount) * 100 || (maxGenderCount > 0 ? 0 : 100));

                  // Set the width dynamically
                  const barWidth = { width: (relativePercentage < 20 ? relativePercentage + 10 + '%' : relativePercentage + '%') };

                  return (
                    <div className='py-1 px-2 flex justify-between items-center rounded-md' style={{ ...barWidth, background: 'linear-gradient(90deg, #DEDDE8 -2.57%, rgba(229, 238, 253, 0.00) 112.5%)' }} key={gender}>
                      <p className='text-sm whitespace-nowrap mr-2'>{toUpper(gender)}</p>
                      <p className='text-[10px]'>{count}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        {!isTotal &&
          <div className='flex sm:flex-row flex-col sm:gap-0 gap-8 justify-between'>
            <div className='bg-white rounded-xl sm:w-1/2 sm:mr-8 flex flex-col justify-between p-6 shadow border border-border-grey'>
              <div className='flex flex-col gap-1'>
                <p className='text-xl font-semibold'>{t('InterestedBecoming')} {disputeType === 'Mediation' ? 'Conciliator' : 'Arbitrator'}?</p>
                <a href={infobank?.empanelmentUrl} target="_blank" className="text-dash-blue text-sm items-center flex gap-3 h-full">{t('ApplyNow')} <i className="ph-bold ph-arrow-right"></i></a>
              </div>

              <div className='flex flex-col gap-2 pt-20'>
                <p className=' font-medium text-5xl'>{disputeType === 'Mediation' ? infobank?.totalConciliators : infobank?.totalArbitrators}</p>
                <p className='text-sm text-label-grey'>{t('TotalNeutralsRegistered')}</p>
              </div>
            </div>
            <div className="flex flex-col justify-between rounded-xl sm:w-1/2 w-full sm:pl-4 px-4 shadow bg-highlight-blue text-white py-4 border border-border-grey" style={{ backgroundImage: 'url(https://ocp-module-production.s3.ap-south-1.amazonaws.com/logos/book-open.svg)', backgroundSize: 'contain', backgroundPosition: 'right', backgroundRepeat: 'no-repeat', backgroundBlendMode: 'soft-light' }}>
              <div className="flex flex-col gap-2 pb-10">
                <p className="text-xl font-semibold sm:w-2/3">{disputeType === 'Mediation' ? t('TrainingProgramsConciliators') : t('TrainingProgramsArbitrators')}
                  <br />{t('ClickbelowToVisit')}</p>
              </div>
              <div className="flex flex-col gap-2 pt-20">
                <a href="https://www.nism.ac.in/" target='_blank' className="bg-white py-4 px-9 text-dash-blue text-sm font-semibold w-2/3 rounded-lg">{t('NISMTraining')}</a>
              </div>
            </div>
          </div>
        }

        {disputeType === "Mediation" &&
          <div className='flex sm:flex-row flex-col gap-8 sm:gap-0 justify-between'>
            <div className='bg-white rounded-xl flex flex-col sm:w-1/4 sm:mr-8 justify-between p-4 shadow border border-border-grey'>
              <p className='text-base text-odr-grey font-semibold w-2/3'>{t('ReferredforOnlineConciliation')}</p>
              <div className='flex flex-col pt-14'>
                <p className=' text-2xl text-odr-text font-semibold'>{infobank?.referredToODR}</p>
                <p className='text-dash-grey text-sm'>{t('Disputes')}</p>
              </div>
            </div>
            <div className='bg-white rounded-xl flex flex-col sm:w-1/4 sm:mr-8 justify-between p-4 shadow border border-border-grey'>
              <p className='text-base text-odr-grey font-semibold w-2/3'>{t('OnlineConciliationsInProgress')}</p>
              <div className='flex flex-col pt-14'>
                <p className=' text-2xl text-odr-text font-semibold'>{parseInt(infobank?.conciliationInProgress, 10) + parseInt(infobank?.conciliationInitiated, 10)}</p>
                <p className='text-dash-grey text-sm'>{t('Disputes')}</p>
              </div>
            </div>
            <div className='bg-white rounded-xl flex flex-col sm:w-1/4 sm:mr-8 justify-between p-4 shadow border border-border-grey'>
              <p className='text-base text-odr-grey font-semibold w-2/3'>{t('SuccessfulOnlineConciliations')}</p>
              <div className='flex flex-col pt-14'>
                <p className=' text-2xl text-odr-text font-semibold'>{infobank?.resolvedConciliation}</p>
                <p className='text-dash-grey text-sm'>{t('Disputes')}</p>
              </div>
            </div>
            <div className='bg-white rounded-xl flex flex-col sm:w-1/4 justify-between p-4 shadow border border-border-grey'>
              <p className='text-base text-odr-grey font-semibold w-2/3'>{t('UnsuccessfulOnlineConciliations')}</p>
              <div className='flex flex-col pt-14'>
                <p className=' text-2xl text-odr-text font-semibold'>{infobank?.conciliationFailed}</p>
                <p className='text-dash-grey text-sm'>{t('Disputes')}</p>
              </div>
            </div>
          </div>
        }
        {disputeType === "Arbitration" &&
          <div className='flex sm:flex-row flex-col gap-8 sm:gap-0 justify-between'>
            <div className='bg-white rounded-xl flex flex-col sm:w-1/3 sm:mr-8 justify-between p-4 shadow border border-border-grey'>
              <p className='text-base text-odr-grey font-semibold w-2/3'>{t('ReferredforOnlineArbitration')}</p>
              <div className='flex flex-col pt-14'>
                <p className=' text-2xl text-odr-text font-semibold'>{infobank?.referredToODRArbitration}</p>
                <p className='text-dash-grey text-sm'>{t('Disputes')}</p>
              </div>
            </div>
            <div className='bg-white rounded-xl flex flex-col sm:w-1/3 sm:mr-8 justify-between p-4 shadow border border-border-grey'>
              <p className='text-base text-odr-grey font-semibold w-2/3'>{t('OnlineArbitrationsinProgress')}</p>
              <div className='flex flex-col pt-14'>
                <p className=' text-2xl text-odr-text font-semibold'>{parseInt(infobank?.arbitrationInProgress, 10) + parseInt(infobank?.arbitrationInitiated, 10)}</p>
                <p className='text-dash-grey text-sm'>{t('Disputes')}</p>
              </div>
            </div>
            <div className='bg-white rounded-xl flex flex-col sm:w-1/3 justify-between p-4 shadow border border-border-grey'>
              <p className='text-base text-odr-grey font-semibold w-2/3'>{t('SuccessfulOnlineArbitrations')}</p>
              <div className='flex flex-col pt-14'>
                <p className=' text-2xl text-odr-text font-semibold'>{infobank?.resolvedArbitration}</p>
                <p className='text-dash-grey text-sm'>{t('Disputes')}</p>
              </div>
            </div>
          </div>
        }
        {/* <div className='flex justify-between'>
          <div className='bg-white rounded-xl flex flex-col w-1/3 mr-8 justify-between p-4 shadow border border-border-grey'>
            <p className='text-xl font-semibold'>Awards in Favor of Investor</p>
            <div className='flex flex-col pt-24'>
              <p className=' text-5xl text-odr-text font-medium'>20</p>
              <p className='text-dash-grey text-sm'>10 passed this week.</p>
            </div>
          </div>
          <div className='bg-white rounded-xl flex flex-col w-1/3 mr-8 justify-between p-4 shadow border border-border-grey'>
            <p className='text-xl font-semibold'>Awards in Favor of Intermediary</p>
            <div className='flex flex-col pt-24'>
              <p className=' text-5xl text-odr-text font-medium'>10</p>
              <p className='text-dash-grey text-sm'>2 passed this week.</p>
            </div>
          </div>
          <div className='bg-white rounded-xl flex flex-col w-1/3 justify-between p-4 shadow border border-border-grey'>
            <p className='text-xl font-semibold'>Awards with indeterminate outcome</p>
            <div className='flex flex-col pt-24'>
              <p className=' text-5xl text-odr-text font-medium'>5</p>
              <p className='text-dash-grey text-sm'>1 passed this week.</p>
            </div>
          </div>
        </div> */}
        {
          !isTotal &&
          <div className='justify between flex sm:flex-row flex-col gap-8 sm:gap-0'>
            <div className='flex flex-col p-4 sm:mr-8 shadow border rounded-lg bg-white border-border-grey sm:w-1/2 sm:h-full h-full'>
              <p className='text-xl text-odr-text font-semibold'>{t('RedactedAwards')}</p>
              <div className='relative w-full h-fit mt-28 flex flex-col items-center justify-start'>
                <div className='bg-dash-blue w-20 h-20' style={{ borderRadius: '50%' }}>
                  {' '}
                </div>
                <div className='h-16 w-28 absolute backdrop-blur-md top-1/2' style={{ background: 'linear-gradient(180deg, #f5f5f5 -62.57%, rgba(222, 221, 232, 0.00) 112.5%)' }}>
                  {' '}
                </div>
              </div>
              <div className='flex flex-col gap-2 items-center text-center pt-14'>
                <p className='text-highlight-blue text-xl font-semibold'>{t('ComingSoon')}</p>
                <p className='text-sm text-odr-text w-2/3'>{t('SeeRedactedArbitrationAwards')}</p>
              </div>
            </div>
            <div className='flex flex-col gap-8'>
              <div className="flex flex-col justify-between rounded-xl w-full sm:pl-4 px-4 shadow bg-white text-highlight-blue py-4 border border-border-grey" style={{ backgroundImage: 'url(https://ocp-module-production.s3.ap-south-1.amazonaws.com/logos/question.svg)', backgroundSize: 'contain', backgroundPosition: 'right', backgroundRepeat: 'no-repeat' }}>
                <div className="flex flex-col gap-2 pb-10">
                  <p className="text-xl font-semibold sm:w-2/3">{t('HaveGrievanceWith')} {infobank?.Organisation?.organisationName}{t('ConciliatorsOrArbitrators')}? {t('FillTheForm')}</p>
                </div>
                <div className="flex flex-col gap-2 pt-20">
                  <a href={infobank?.grievanceUrl} target='_blank' className="bg-highlight-blue py-4 px-9 text-white text-sm font-semibold w-2/3 rounded-lg">{t('GrievanceRedressalForm')}</a>
                </div>
              </div>
              <div className="flex flex-col justify-between rounded-xl w-full sm:pl-4 px-4 shadow bg-highlight-blue text-white py-4 border border-border-grey" style={{ backgroundImage: 'url(https://ocp-module-production.s3.ap-south-1.amazonaws.com/logos/scales.svg)', backgroundSize: 'contain', backgroundPosition: 'right', backgroundRepeat: 'no-repeat', backgroundBlendMode: 'soft-light' }}>
                <div className="flex flex-col gap-2 pb-10">
                  <p className="text-xl font-semibold sm:w-2/3">Explore the comprehensive rules and procedures governing {infobank?.Organisation?.organisationName}'s Dispute Resolution below.</p>
                </div>
                <div className="flex flex-col gap-2 pt-20">
                  <a href={infobank?.rulesUrl} target='_blank' className="bg-white py-4 px-9 text-highlight-blue text-sm font-semibold w-2/3 rounded-lg">{t('RulesProcedures')}</a>
                </div>
              </div>
            </div>
          </div>
        }

      </div>
      {languageModal && (
        <div className="fixed z-10 inset-0 flex items-center justify-center " id="modal" style={{ zIndex: '999' }}>
          <div className="px-8 sm:px-0 sm:w-2/3 h-3/4">
            <div className="flex justify-center pt-4 pb-20 w-full h-full">
              <div
                className="fixed inset-0 transition-opacity"
                onClick={() => setLanguageModal(false)}
              >
                <div className="absolute inset-0 bg-gray-900 opacity-75" />
              </div>
              <div
                className="inline-block align-center w-full bg-white rounded-lg py-4 text-left shadow-xl transform transition-all "
                role="dialog"
                aria-modal="true"
                aria-labelledby="modal-headline"
              >

                <div className="flex flex-col gap-1 px-8 pb-5" style={{ height: "-webkit-fill-available" }}>
                  <div className="flex flex-row w-full justify-between items-center">
                    <h1 className='font-semibold sm:text-2xl text-lg pt-3'>{t('AllLanguages')}</h1>
                    <i className={`ph-bold ph-x text-lg text-label-grey cursor-pointer`} onClick={() => setLanguageModal(false)}></i>
                  </div>
                  <div className="w-full min-h-fit overflow-hidden overflow-y-auto flex flex-col">

                    <div className='flex flex-col gap-4 pb-3 pt-4 text-label-grey overflow-y-auto'>
                      {allLanguageDistribution?.map((languageDistributions: any, index: number) => {
                        const maxCount = Math.max(...allLanguageDistribution.map((item: any) => parseInt(item.count, 10)));
                        const relativePercentage: number = ((parseInt(languageDistributions.count, 10) / maxCount) * 100) || (maxCount > 0 ? 0 : 100)
                        const barWidth = relativePercentage < 20 ? relativePercentage + 10 + '%' : relativePercentage + '%'
                        return <div className='py-1 px-2 flex justify-between items-center rounded-md' style={{ width: barWidth, background: 'linear-gradient(90deg, #DEDDE8 -2.57%, rgba(229, 238, 253, 0.00) 112.5%)' }}>
                          <p className='text-sm whitespace-nowrap mr-2'>{toUpper(languageDistributions?.language)}</p>
                          <p className='text-[10px]'>{languageDistributions?.count}</p>
                        </div>
                      })}
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>

        </div>
      )}
    </div>
  )
}

export default OdrDashboard