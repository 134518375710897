import { Fragment, Suspense, useEffect } from 'react'
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import './App.css'
import AdminModule from './Components/Admin/AdminModule';
import ChangePassword from './Components/Auth/ChangePassword';
import ForgotPassword from './Components/Auth/ForgotPassword';
import Login from './Components/Auth/Login';
import Loader from './Components/common-module/loader';
import InvestorModule from './Components/Investor/InvestorModule';
import MIIModule from './Components/MII/MIIModule';
import ODRModule from './Components/ODR/ODRModule';
import Registration from './Components/Auth/Registration';
import IntermediaryModule from './Components/Intermediary/IntermediaryModule';
import VerifyUser from './Components/Auth/VerifyUser';
import SetupPassword from './Components/Auth/SetupPassword';
import SetupIntermediaryAccount from './Components/Auth/SetupIntermediaryAccount';
import TermsAndCondition from './Components/TermsAndCondition';
import Dashboard from './Components/Dashboard';
import UnderMaintenance from './Components/UnderMaintenance';
import IntermediaryLoginComponent from './Components/Auth/IntermediaryLogin';
import IntermediaryRegistration from './Components/Auth/IntermediaryRegistration';
import OdrDashboard from './Components/OdrDashboard';
import MarketParticipantRegistration from './Components/Auth/MarketParticipantRegistration';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import MarketParticipantReRegistration from './Components/Auth/MarketParticipantReRegistration';
import InvestorVerify from './Components/Auth/InvestorVerify';

function App() {

  useEffect(() => {
    // define a custom handler function
    // for the contextmenu event
    const handleContextMenu = (e: any) => {
      // prevent the right-click menu from appearing
      if (import.meta.env.VITE_ENV === 'production') {
        e.preventDefault();
      }
    };

    // attach the event listener to
    // the document object
    document.addEventListener("contextmenu", handleContextMenu);

    // clean up the event listener when
    // the component unmounts
    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
    };
  }, []);

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey="6Le6dxQnAAAAAB1jJta8BKpxhYbPAHxhDhgBwV1j"
    >
      <Fragment>
        <BrowserRouter>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/terms-and-conditions" element={<TermsAndCondition />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path='/odr-dashboard' element={<OdrDashboard/>} />
            <Route path="/maintenance" element={<UnderMaintenance />} />
            <Route path="/intermediary/login" element={<IntermediaryLoginComponent />} />
            <Route path="/:role/login" element={<Login />} />
            <Route path="/*" element={<Login />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/register" element={<Registration />} />
            <Route path="/intermediary-register" element={<IntermediaryRegistration />} />
            <Route path="/investor-verify" element={<InvestorVerify />} />
            <Route path="/market-participant-register" element={<MarketParticipantRegistration />} />

            <Route path="/market-participant-re-register/:token" element={<MarketParticipantReRegistration />} />

            <Route
              path="/change-password/:token"
              element={<ChangePassword />}
            />
            <Route
              path="/setup-password/:token"
              element={<SetupPassword />}
            />
            <Route
              path="/setup-account/:token"
              element={<SetupIntermediaryAccount />}
            />
            <Route
              path="/verify-user/:token"
              element={<VerifyUser />}
            />
            <Route
              path="/admin/*"
              element={
                <Suspense fallback={<Loader />}>
                  <AdminModule />
                </Suspense>
              }
            />
            <Route
              path="/investor/*"
              element={
                <Suspense fallback={<Loader />}>
                  <InvestorModule />
                </Suspense>
              }
            />
            <Route
              path="/mii/*"
              element={
                <Suspense fallback={<Loader />}>
                  <MIIModule />
                </Suspense>
              }
            />
            <Route
              path="/odr/*"
              element={
                <Suspense fallback={<Loader />}>
                  <ODRModule />
                </Suspense>
              }
            />
            <Route
              path="/intermediary/*"
              element={
                <Suspense fallback={<Loader />}>
                  <IntermediaryModule />
                </Suspense>
              }
            />
            <Route
              path="/"
              element={<Navigate replace={false} to={"/login"} />}
            />
          </Routes>
        </BrowserRouter>
      </Fragment>
   </GoogleReCaptchaProvider>
  )
}

export default App
