import { useState } from "react";
import TermsAndCondition from "../TermsAndCondition";
import { useTranslation } from 'react-i18next';

/**
 * TandCModal component displays a modal with terms and conditions that users must review
 * and accept in order to proceed with a submission. The modal includes a scrollable section
 * containing the full terms and conditions, and an "Accept and Proceed" button which is enabled
 * only after the user scrolls to the bottom of the terms content. There is also a cancel button 
 * which closes the modal without accepting the terms.
 *
 * Props:
 * - setTandCModal: Function to control the visibility of the modal.
 * - submission: Callback function executed upon accepting the terms and conditions.
 * - setTCChecked: Function to update the state indicating whether the terms and conditions have been accepted.
 */
const TandCModal = ({ setTandCModal, submission, setTCChecked }: any) => {
  const { t } = useTranslation();
  const [buttonDisabled, setButtonDisabled] = useState(true);
  function handleScroll(e: any) {
    // Check if the user has scrolled to the bottom of the terms container
    const container = e.target;
    if (
      container.scrollHeight - container.scrollTop - container.clientHeight < 10
    ) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }
  const handleAcceptAndProceed = () => {
    submission();
    setTCChecked(true)
    setTandCModal(false);
  };
  return (
    <div
      className="fixed z-10 inset-0 flex items-center justify-center "
      id="modal"
    >
      <div className="px-8 sm:px-0 sm:w-1/2">
        <div className="flex justify-center pt-4 pb-20 w-fit h-fit">
          <div
            className="fixed inset-0 transition-opacity"
            onClick={() => setTandCModal(false)}
          >
            <div className="absolute inset-0 bg-gray-900 opacity-75" />
          </div>
          <div
            className="inline-block align-center w-fit bg-white rounded-lg py-4 text-left shadow-xl transform transition-all "
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
              <div className="flex flex-col gap-1 px-8 pb-5">
                <h1 className="font-semibold sm:text-2xl text-lg pt-3">
                  {t('PleaseAcceptTermsandConditions')}
                </h1>
                <div className="flex flex-col gap-4 pb-3 pt-4 text-label-grey">
                  <p className="text-xs text-label-grey">
                    {t('AcceptTermsConditions')}
                  </p>
                  <div
                    onScroll={handleScroll}
                    className="w-full h-96 overflow-hidden overflow-y-scroll text-xs p-3 border border-border-grey rounded-lg"
                  >
                  <TermsAndCondition></TermsAndCondition>
                  </div>
                </div>
                <div className="flex flex-col sm:flex-row w-full pt-4 gap-4">
                  <button
                    onClick={handleAcceptAndProceed}
                    disabled={buttonDisabled}
                    className="hover:bg-highlight-blue/[0.4] bg-highlight-blue justify-center text-white flex flex-row gap-2 text-xs py-3 rounded-xl disabled:bg-highlight-blue/[0.4] px-12"
                    aria-label={t('AcceptAndProceed')}
                    type="submit"
                  >
                    <p>{t('AcceptAndProceed')}</p>
                  </button>
                  <button
                    aria-label={t('cancel')}
                    onClick={() => {setTandCModal(false), setTCChecked(false)}}
                    className=" hover:bg-black/[0.1] cursor-pointer hover:text-white justify-center text-xs bg-white text-back-gray px-12 py-3 rounded-xl disabled:bg-highlight-blue/40"
                  >
                    {t('cancel')}
                  </button>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TandCModal;
