import { yupResolver } from "@hookform/resolvers/yup"
import { useState } from "react"
import { useForm } from "react-hook-form"
import { Link, NavLink } from "react-router-dom"
import { registrationValidation } from "../../helpers/validation"
import { useCombinedStore } from "../../store"
import { User } from "../../swagger/models"
import { CityType, encryptString, isValidPasswordNumber, StateType } from "../common-module/helper"
import TandCModal from "./T&CModal"
import { useTranslation } from 'react-i18next';
import { Eye, EyeSlash } from "phosphor-react"

/**
 * Registration component to handle registration process.
 * 
 * This component renders a form with the following fields:
 * - Name
 * - Email
 * - Phone Number
 * - PAN Number
 * - State
 * - City
 * - Pincode
 * - Address
 * - Password
 * - Confirm Password
 * 
 * The component also renders a checkbox for agreeing to the terms and conditions.
 * When the checkbox is checked, the user is redirected to the next page.
 * 
 * The component also renders an error message if the user does not fill in any of the required fields.
 * 
 * The component also renders a success message if the user has successfully registered.
 * 
 * The component also renders a link to go back to the login page.
 */
function Registration() {
    const { t } = useTranslation();
    const [userDetail, setUserDetails] = useState<User>({
        country: "India"
    })
    const [encryptedRequest, setEncryptedRequest] = useState<User>({
        country: "India"
    })
    const [confirmPassword, setConfirmPassword] = useState<string>("")
    const registerApi = useCombinedStore((state: any) => state.register)
    const newProfileCreated = useCombinedStore((state: any) => state.newProfileCreated)
    const isLoading = useCombinedStore((state: any) => state.isLoading)
    const [tandCModal, setTandCModal] = useState<boolean>(false)
    const [tcChecked, setTCChecked] = useState<boolean>(false)
    // Set the password type state
    const [passwordType, setPasswordType] = useState<string>('password')
    const [confirmPasswordType, setConfirmPasswordType] = useState<string>('password')

    const formOptions = {
        resolver: yupResolver(registrationValidation),
    }

    const isValidEmail = (email: any) => {
        const tester = /^[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/
        if (!email) return false

        if (email.length > 256) return false

        if (!tester.test(email)) return false

        // Further checking of some things regex can't handle
        const [account, address] = email.split('@')
        if (account.length > 64) return false

        const domainParts = address.split('.')
        return !domainParts.some((part: any) => part.length > 63)
    }

    const isValidPhoneNumber = (phoneNumber: any) => {
        const phoneRegexIndia = /^(\+?91|0)?[6789]\d{9}$/
        return phoneRegexIndia.test(phoneNumber)
    }

    const isValidPanNumber = (panNumber: any) => {
        if (panNumber) {
            const panNumberRegex = /^([A-Z]{5})(\d{4})([A-Z])$/ // 5 Uppercase,4digits,1 Uppercase
            return panNumberRegex.test(panNumber)
        }
    }

    const isValidPinCode = (pinCode: any) => {
        const pincodeRegex = /^\d{6}$/
        return pincodeRegex.test(pinCode)
    }

    const { register, handleSubmit, formState } = useForm(formOptions)
    const { errors } = formState

    const [error, setErrors] = useState({
        userName: '',
        email: '',
        phoneNumber: '',
        panNumber: '',
        city: '',
        pinCode: '',
        address: '',
        password: '',
        confirmPassword: '',
        state: ''

    });

    const validateField = (name: any, value: any) => {
        const newErrors = { ...error };
        switch (name) {
            case 'userName':
                newErrors.userName = value ? '' : 'Name is required';
                break;
            case 'email':
                newErrors.email = isValidEmail(value) ? '' : 'Invalid email address';
                break;
            case 'phoneNumber':
                newErrors.phoneNumber = isValidPhoneNumber(value) ? '' : 'Invalid phone number';
                break;
            case 'panNumber':
                newErrors.panNumber = isValidPanNumber(value) ? '' : 'Invalid PAN number';
                break;
            case 'pinCode':
                newErrors.pinCode = isValidPinCode(value) ? '' : 'Invalid pincode';
                break;
            case 'city':
                newErrors.city = value ? '' : 'Invalid city selection';
                break;
            case 'state':
                newErrors.state = value ? '' : 'Invalid state selection';
                break;
            case 'address':
                newErrors.address = value ? '' : 'Invalid address';
                break;
            case 'password':
                newErrors.password = isValidPasswordNumber(value) ? '' : 'Password must be at least 8 characters, maximum 32 characters, at least one uppercase letter, one lowercase letter, one number and one special character';
                break;
            case 'confirmPassword':
                newErrors.confirmPassword = value === userDetail.password ? '' : 'Passwords must match';
                break;
            default:
                break;
        }

        setErrors(newErrors);
    };

    /**
     * 
     * @param e 
     */
    const onInputChange = (e: any) => {
        const { name, value } = e.target
        setUserDetails({
            ...userDetail,
            [name]: value,
        })
        setEncryptedRequest({
            ...encryptedRequest,
            [name]: name === 'password' ? encryptString(value) : value,
        })
        validateField(name, value);
    }

    /**
     * 
     * @param e 
     * @returns 
     */
    async function onSubmit(e: any) {
        registerApi(encryptedRequest)
        return false
    }

    // Function to toggle the password type
    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
    }

    // Function to toggle the password type
    const toggleConfirmPassword = () => {
        if (confirmPasswordType === "password") {
            setConfirmPasswordType("text")
            return;
        }
        setConfirmPasswordType("password")
    }

    return (
        <>
            <div className="flex flex-row justify-between pt-7 px-10 lg:pl-32 pb-7 w-full h-fit">
                <div className="flex flex-col h-full w-full lg:w-1/3 pt-10 sm:pt-20 justify-between">
                    <div>
                        <NavLink to="/login">
                            <img src="https://ocp-module-development.s3.ap-south-1.amazonaws.com/SMARTODR_LOGO.svg" alt="SMARTODR Logo" style={{ width: '175px', height: 'fit' }} />
                        </NavLink>
                        <h1 className="pt-10 text-3xl text-highlight-blue font-bold">{t('InvestorRegistration')}</h1>
                        <p className="text-xs pt-3 pb-7">{t('AlreadyHaveAccount')} <Link to="/login" style={{ color: '#306AFF' }}>{t('Loginhere')}</Link></p>
                        {!newProfileCreated ? (
                            <div className="pb-8 border-b-2 border-highlight-blue">
                                <div className="rounded-xl bg-light-blue flex flex-col gap-3 py-4 px-4 text-xs">
                                    <p className="font-bold text-highlight-blue">{t('ImportantNote')}</p>
                                    <p className="text-highlight-blue">{t('PleaseRegisterEmailID')}</p>
                                </div>
                            </div>
                        ) : (
                            <div className="pb-8">
                                <div className="rounded-xl bg-light-blue flex flex-col gap-3 py-4 px-4 text-xs">
                                    <div className="flex flex-row w-full h-fit gap-2 items-center">
                                        <img src="https://ocp-module-development.s3.ap-south-1.amazonaws.com/Green-Tick-Vector-PNG-Images.png" width={"30px"} />
                                        <p className="font-bold text-highlight-blue">{t('AccountRegistrationSuccessful')}! {t('CheckYourEmail')}</p>
                                    </div>
                                    <p className="text-highlight-blue">{t('TheLinkRedirect')}</p>
                                    <p className="text-highlight-blue">{t('PleaseNoteLoginWithout')}</p>
                                    <p className="text-highlight-blue">{t('InCaseofAnyQueries')}</p>
                                </div>
                            </div>
                        )}

                        {!newProfileCreated && (
                            <form autoComplete="off" className="flex flex-col pt-8 gap-2" onSubmit={handleSubmit(onSubmit)}>
                                <label htmlFor="Name" className="text-xs">{t('Name')}*</label>
                                <input {...register('userName')} onChange={onInputChange} value={userDetail?.userName} required aria-label={t('Name')} type="text" name='userName' id='userName' className={`text-xs rounded-lg border p-2 border-solid ${error.userName !== '' ? 'border-highlight-red' : 'border-border-grey'}`} />
                                <span className="text-xs text-highlight-red">{errors?.userName?.message}</span>
                                <span className="text-xs text-highlight-red">{error?.userName}</span>

                                <label htmlFor="email" className="text-xs">{t('EnterEmailID')}*</label>
                                <input {...register('email')} onChange={onInputChange} value={userDetail?.email} required aria-label={t('EnterEmailID')} type="email" name='email' id='email' className={`text-xs rounded-lg border p-2 border-solid ${error.email !== '' ? 'border-highlight-red' : 'border-border-grey'}`} />
                                <span className="text-xs text-highlight-red">{errors?.email?.message}</span>
                                <span className="text-xs text-highlight-red">{error?.email}</span>

                                <label htmlFor="PhoneNumber" className="text-xs">{t('EnterPhoneNumber')}*</label>
                                <input {...register('phoneNumber')} onChange={onInputChange} value={userDetail?.phoneNumber} required aria-label={t('EnterPhoneNumber')} type="text" name='phoneNumber' id='phoneNumber' className={`text-xs rounded-lg border p-2 border-solid ${error.phoneNumber !== '' ? 'border-highlight-red' : 'border-border-grey'}`} />
                                <span className="text-xs text-highlight-red">{errors?.phoneNumber?.message}</span>
                                <span className="text-xs text-highlight-red">{error?.phoneNumber}</span>

                                <label htmlFor="PAN" className="text-xs">{t('PANNumber')}*</label>
                                <input {...register('panNumber')} onChange={onInputChange} value={userDetail?.panNumber} required aria-label={t('PANNumber')} type="text" name='panNumber' id='panNumber' className={`text-xs rounded-lg border p-2 border-solid ${error.panNumber !== '' ? 'border-highlight-red' : 'border-border-grey'}`} />
                                <span className="text-xs text-highlight-red">{errors?.panNumber?.message}</span>
                                <span className="text-xs text-highlight-red">{error?.panNumber}</span>
                                <label htmlFor="State" className="text-xs">{t('State')}*</label>
                                <select
                                    aria-label={t('SelectState')} required name='state' id='state' onChange={onInputChange}
                                    className="rounded-lg border p-3 border-border-grey border-solid text-xs" value={userDetail?.state}
                                >
                                    <option value={''}>{t('SelectState')}</option>
                                    {StateType?.map((state: string) => {
                                        return <option key={state} value={state}>{state}</option>
                                    })}
                                </select>
                                <span className="text-xs text-highlight-red">{error?.state}</span>
                                <div className="flex flex-row justify-between">
                                    <div className="w-1/2 flex flex-col gap-2 pr-4">
                                        <label htmlFor="City" className="text-xs">{t('City')}*</label>
                                        <select
                                            aria-label={t('SelectCity')} {...register('city')} required name='city' id='city' onChange={onInputChange}
                                            className="rounded-lg border p-3 border-border-grey border-solid text-xs" value={userDetail?.city}
                                        >
                                            <option value={''}>{t('SelectCity')}</option>
                                            {CityType.filter((city: any) => city.state === userDetail?.state)?.map((city: any) => {
                                                return <option key={city} value={city.city}>{city.city}</option>
                                            })}
                                        </select>
                                        <span className="text-xs text-highlight-red">{errors?.city?.message}</span>

                                    </div>
                                    <div className="w-1/2 flex flex-col gap-2">
                                        <label htmlFor="pinCode" className="text-xs">{t('Pincode')}*</label>
                                        <input {...register('pinCode')} onChange={onInputChange} value={userDetail?.pinCode} required aria-label={t('Pincode')} type="text" name='pinCode' id='pinCode' className={`text-xs rounded-lg border p-3 h-fit border-solid ${error?.pinCode !== '' ? 'border-highlight-red' : 'border-border-grey'}`} />
                                        <span className="text-xs text-highlight-red">{errors?.pinCode?.message}</span>
                                        <span className="text-xs text-highlight-red">{error?.pinCode}</span>
                                    </div>
                                </div>
                                <label htmlFor="Address" className="text-xs">{t('EnterCompleteAddress')}*</label>
                                <input {...register('address')} onChange={onInputChange} value={userDetail?.address} required aria-label={t('EnterCompleteAddress')} type="text" name='address' id='address' className={`text-xs rounded-lg border p-2 border-solid mb-3 ${error.address !== '' ? 'border-highlight-red' : 'border-border-grey'}`} />
                                <span className="text-xs text-highlight-red">{errors?.address?.message}</span>
                                <span className="text-xs text-highlight-red">{error?.address}</span>
                                <hr className="py-2" />
                                <label htmlFor="Password" className="text-xs">{t('EnterPassword')}*</label>
                                <div className='flex flex-row justify-between items-center border border-solid rounded-lg'>
                                    <input {...register('password')} onChange={onInputChange} value={userDetail?.password} required aria-label={t('EnterPassword')} type={passwordType} name='password' id='password' className={`text-xs rounded-lg p-2 border-solid w-full mr-1 ${error.password !== '' ? 'border-highlight-red' : 'border-border-grey'}`} />
                                    <button onClick={togglePassword} type='button' className='px-3'>
                                        {passwordType === 'password' ? <Eye size={20} weight="thin" /> : <EyeSlash size={20} weight="thin" />}
                                    </button>
                                </div>
                                <span className="text-xs text-highlight-red">{errors?.password?.message}</span>
                                <span className="text-xs text-highlight-red">{error.password}</span>
                                <label htmlFor="ConfirmPassword" className="text-xs">{t('ConfirmPassword')}*</label>
                                <div className='flex flex-row justify-between items-center border border-solid rounded-lg'>
                                    <input {...register('confirmPassword')} onChange={(e) => {
                                        setConfirmPassword(e.target?.value)
                                        validateField("confirmPassword", e.target?.value)
                                    }} value={confirmPassword} required aria-label={t('ConfirmPassword')} type={confirmPasswordType} name='confirmPassword' id='confirmPassword' className={`text-xs rounded-lg p-2 border-solid w-full mr-1 ${error.confirmPassword !== '' ? 'border-highlight-red' : 'border-border-grey'}`} />
                                    <button onClick={toggleConfirmPassword} type='button' className='px-3'>
                                        {confirmPasswordType === 'password' ? <Eye size={20} weight="thin" /> : <EyeSlash size={20} weight="thin" />}
                                    </button>
                                </div>
                                <span className="text-xs text-highlight-red">{errors?.confirmPassword?.message}</span>
                                <span className="text-xs text-highlight-red">{error?.confirmPassword}</span>
                                <div className="flex flex-row gap-2 pt-4">
                                    <input onChange={(e: any) => {
                                        setTandCModal(e.target.checked)
                                        e.target.checked === false && setTCChecked(false)
                                    }} checked={tandCModal || tcChecked} aria-label={t('AgreeSMARTTermsConditions')} type='checkbox' id='logggedin' className='bg-border-grey' />
                                    <label className="text-xs">{t('AgreeSMARTTermsConditions')}</label>
                                </div>
                                <button disabled={!tcChecked} aria-label='Proceed' type="submit" className='bg-highlight-blue disabled:bg-highlight-blue/40 hover:bg-highlight-blue/[0.8] mt-4 text-white text-sm py-4 px-28 rounded-lg'>
                                    {t('Proceed')}
                                </button>
                                {tandCModal && <TandCModal setTandCModal={setTandCModal} setTCChecked={setTCChecked} submission={(e: any) => setTCChecked(true)} />}
                            </form>
                        )}
                    </div>
                </div>
                <div className='hidden lg:flex w-1/2'>
                    <div className='hidden lg:block w-fit h-full px-10 pr-20 mb-8 py-8 fixed top-0 right-0 rounded-2xl'>
                        <img src="https://ocp-module-development.s3.ap-south-1.amazonaws.com/HeroIMAGESMARTODR.png"
                            className='w-fit overflow-hidden h-full rounded-xl' alt="hero" />
                    </div>
                </div>
            </div>
        </>

    )
}

export default Registration