import moment from 'moment'
import CryptoJS from 'crypto-js';
import { ComplainUpdate } from '../../swagger/models';

/**
 * 
 * @param inputDate 
 * @returns 
 */
export function dateHelper(inputDate: any) {
  if (inputDate) {
    return moment(inputDate).format('DD MMM, YYYY')
  }
  return ''
}

/**
 * 
 * @param inputDate 
 * @returns 
 */
export function timeHelper(inputDate: any) {
  if (inputDate) {
    return moment(inputDate).format('hh:mm:ss A')
  }
  return ''
}

/**
 * 
 * @param inputDate 
 * @returns 
 */
export function dateTimeHelper(inputDate: any) {
  if (inputDate) {
    return moment(inputDate).format('DD MMM, YYYY hh:mm:ss A')
  }
  return ''
}

/**
 * 
 * @param data 
 * @param fileName 
 * @param fileType 
 */
export const downloadJSONFile = (data: any, fileName: string, fileType: string) => {
  // Create a blob with the data we want to download as a file
  const blob = new Blob([data], { type: fileType })
  // Create an anchor element and dispatch a click event on it
  // to trigger a download
  const a = document.createElement('a')
  a.download = fileName
  a.href = window.URL.createObjectURL(blob)
  const clickEvt = new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: true,
  })
  a.dispatchEvent(clickEvt)
  a.remove()
}
/**
 * 
 * @param value 
 * @returns 
 */
export function titleCase(value: string) {
  if (value) {
    const updatedString = value.toLowerCase().split('_').map((word) => {
      return word.replace(word[0], word[0].toUpperCase());
    }).join(' ')
    return updatedString.replace('Mii', 'MII');
  }
  return ''
}

export const StateType = [
  'Andaman And Nicobar Islands',
  'Andhra Pradesh',
  'Arunachal Pradesh',
  'Assam',
  'Bihar',
  'Chandigarh',
  'Chhattisgarh',
  'Dadra and Nagar Haveli',
  'Daman and Diu',
  'Goa',
  'Gujarat',
  'Haryana',
  'Himachal Pradesh',
  'Jammu And Kashmir',
  'Jharkhand',
  'Karnataka',
  'Kerala',
  'Lakshadweep',
  'Madhya Pradesh',
  'Maharashtra',
  'Manipur',
  'Meghalaya',
  'Mizoram',
  'National Capital Territory Of Delhi',
  'Nagaland',
  'Odisha',
  'Outside India',
  'Puducherry',
  'Punjab',
  'Rajasthan',
  'Sikkim',
  'Tamil nadu',
  'Telangana',
  'Tripura',
  'Uttar Pradesh',
  'Uttarakhand',
  'West Bengal'
]

export const languages = [
  "Assamese",
  "Bengali",
  "Bodo",
  "Dogri",
  "English",
  "Gujarati",
  "Hindi",
  "Kannada",
  "Kashmiri",
  "Konkani",
  "Maithili",
  "Malayalam",
  "Marathi",
  "Meitei",
  "Nepali",
  "Odia",
  "Punjabi",
  "Sanskrit",
  "Santali",
  "Sindhi",
  "Tamil",
  "Telugu",
  "Urdu"
]

export const ageRange = [
  "20-29", "30-39", "40-49", "50-59", "60-69", "70-79", "80-89"
]

export const CityType = [
  { state: 'Andaman And Nicobar Islands', city: 'Port Blair' },
  { state: 'Andaman And Nicobar Islands', city: 'Havelock Island' },
  { state: 'Andaman And Nicobar Islands', city: 'Neil Island' },
  { state: 'Andaman And Nicobar Islands', city: 'Ross Island' },
  { state: 'Andaman And Nicobar Islands', city: 'Diglipur' },
  { state: 'Andaman And Nicobar Islands', city: 'Long Island' },
  { state: 'Andaman And Nicobar Islands', city: 'Cinque Island' },
  { state: 'Andaman And Nicobar Islands', city: 'Mayabunder' },
  { state: 'Andaman And Nicobar Islands', city: 'Little Andaman' },
  { state: 'Andaman And Nicobar Islands', city: 'Nicobar' },
  { state: 'Andaman And Nicobar Islands', city: 'North And Middle Andaman' },
  { state: 'Andaman And Nicobar Islands', city: 'South Andaman' },
  { state: 'Andhra Pradesh', city: 'Visakhapatnam' },
  { state: 'Andhra Pradesh', city: 'Vijayawada' },
  { state: 'Andhra Pradesh', city: 'Kakinada' },
  { state: 'Andhra Pradesh', city: 'Guntur' },
  { state: 'Andhra Pradesh', city: 'Rajahmundry' },
  { state: 'Andhra Pradesh', city: 'Nellore' },
  { state: 'Andhra Pradesh', city: 'Kurnool' },
  { state: 'Andhra Pradesh', city: 'Kadapa' },
  { state: 'Andhra Pradesh', city: 'Tirupati' },
  { state: 'Andhra Pradesh', city: 'Eluru' },
  { state: 'Andhra Pradesh', city: 'Anantapur' },
  { state: 'Andhra Pradesh', city: 'Vizianagaram' },
  { state: 'Andhra Pradesh', city: 'Proddatur' },
  { state: 'Andhra Pradesh', city: 'Nandyal' },
  { state: 'Andhra Pradesh', city: 'Ongole' },
  { state: 'Andhra Pradesh', city: 'Tenali' },
  { state: 'Andhra Pradesh', city: 'Adoni' },
  { state: 'Andhra Pradesh', city: 'Madanapalle' },
  { state: 'Andhra Pradesh', city: 'Machilipatnam' },
  { state: 'Andhra Pradesh', city: 'Chittoor' },
  { state: 'Andhra Pradesh', city: 'Hindupur' },
  { state: 'Andhra Pradesh', city: 'Bhimavaram' },
  { state: 'Andhra Pradesh', city: 'Srikakulam' },
  { state: 'Andhra Pradesh', city: 'Anakapalli' },
  { state: 'Andhra Pradesh', city: 'Dharmavaram' },
  { state: 'Andhra Pradesh', city: 'Gudivada' },
  { state: 'Andhra Pradesh', city: 'Narasaraopet' },
  { state: 'Andhra Pradesh', city: 'Tadipatri' },
  { state: 'Andhra Pradesh', city: 'Tadepalligudem' },
  { state: 'Andhra Pradesh', city: 'Chilakaluripet' },
  { state: 'Andhra Pradesh', city: 'Amaravati' },
  { state: 'Andhra Pradesh', city: 'Kavali' },
  { state: 'Andhra Pradesh', city: 'Amlapuram' },
  { state: 'Andhra Pradesh', city: 'Avanigadda' },
  { state: 'Andhra Pradesh', city: 'Bapatla' },
  { state: 'Andhra Pradesh', city: 'Bhadrachalam' },
  { state: 'Andhra Pradesh', city: 'East Godavari' },
  { state: 'Andhra Pradesh', city: 'Kovvur' },
  { state: 'Andhra Pradesh', city: 'Krishna' },
  { state: 'Andhra Pradesh', city: 'Palakol' },
  { state: 'Andhra Pradesh', city: 'Prakasam' },
  { state: 'Andhra Pradesh', city: 'Srikalahasti' },
  { state: 'Andhra Pradesh', city: 'West Godavari' },
  { state: 'Andhra Pradesh', city: 'Yanam' },
  { state: 'Arunachal Pradesh', city: 'Along' },
  { state: 'Arunachal Pradesh', city: 'Basar' },
  { state: 'Arunachal Pradesh', city: 'Bomdila' },
  { state: 'Arunachal Pradesh', city: 'Changlang' },
  { state: 'Arunachal Pradesh', city: 'Daporijo' },
  { state: 'Arunachal Pradesh', city: 'Deomali' },
  { state: 'Arunachal Pradesh', city: 'Itanagar' },
  { state: 'Arunachal Pradesh', city: 'Jairampur' },
  { state: 'Arunachal Pradesh', city: 'Khonsa' },
  { state: 'Arunachal Pradesh', city: 'Naharlagun' },
  { state: 'Arunachal Pradesh', city: 'Namsai' },
  { state: 'Arunachal Pradesh', city: 'Pasighat' },
  { state: 'Arunachal Pradesh', city: 'Roing' },
  { state: 'Arunachal Pradesh', city: 'Seppa' },
  { state: 'Arunachal Pradesh', city: 'Tawang' },
  { state: 'Arunachal Pradesh', city: 'Tezu' },
  { state: 'Arunachal Pradesh', city: 'Ziro' },
  { state: 'Arunachal Pradesh', city: 'Dibang Valley' },
  { state: 'Arunachal Pradesh', city: 'East Kameng' },
  { state: 'Arunachal Pradesh', city: 'East Siang' },
  { state: 'Arunachal Pradesh', city: 'Kurung Kumey' },
  { state: 'Arunachal Pradesh', city: 'Lohit' },
  { state: 'Arunachal Pradesh', city: 'Lower Dibang Valley' },
  { state: 'Arunachal Pradesh', city: 'Lower Subansiri' },
  { state: 'Arunachal Pradesh', city: 'Papum Pare' },
  { state: 'Arunachal Pradesh', city: 'Tirap' },
  { state: 'Arunachal Pradesh', city: 'Upper Dibang Valley' },
  { state: 'Arunachal Pradesh', city: 'Upper Siang' },
  { state: 'Arunachal Pradesh', city: 'Upper Subansiri' },
  { state: 'Arunachal Pradesh', city: 'West Kameng' },
  { state: 'Arunachal Pradesh', city: 'West Siang' },
  { state: 'Assam', city: 'Guwahati' },
  { state: 'Assam', city: 'Silchar' },
  { state: 'Assam', city: 'Dibrugarh' },
  { state: 'Assam', city: 'Jorhat' },
  { state: 'Assam', city: 'Nagaon' },
  { state: 'Assam', city: 'Tinsukia' },
  { state: 'Assam', city: 'Tezpur' },
  { state: 'Assam', city: 'Baksa' },
  { state: 'Assam', city: 'Barpeta' },
  { state: 'Assam', city: 'Bongaigaon' },
  { state: 'Assam', city: 'Cachar' },
  { state: 'Assam', city: 'Darrang' },
  { state: 'Assam', city: 'Dhemaji' },
  { state: 'Assam', city: 'Dhubri' },
  { state: 'Assam', city: 'Dima Hasso - North Cachar Hill' },
  { state: 'Assam', city: 'Goalpara' },
  { state: 'Assam', city: 'Golaghat' },
  { state: 'Assam', city: 'Hailakandi' },
  { state: 'Assam', city: 'Kamrup' },
  { state: 'Assam', city: 'Kamrup Metropolitan' },
  { state: 'Assam', city: 'Karbi Anglong' },
  { state: 'Assam', city: 'Karimganj' },
  { state: 'Assam', city: 'Kokrajhar' },
  { state: 'Assam', city: 'Lakhimpur' },
  { state: 'Assam', city: 'Marigaon' },
  { state: 'Assam', city: 'Nalbari' },
  { state: 'Assam', city: 'Sibsagar' },
  { state: 'Assam', city: 'Sonapur' },
  { state: 'Assam', city: 'Sonitpur' },
  { state: 'Assam', city: 'Udalguri' },
  { state: 'Bihar', city: 'Patna' },
  { state: 'Bihar', city: 'Gaya' },
  { state: 'Bihar', city: 'Bhagalpur' },
  { state: 'Bihar', city: 'Muzaffarpur' },
  { state: 'Bihar', city: 'Purnia' },
  { state: 'Bihar', city: 'Darbhanga' },
  { state: 'Bihar', city: 'Bihar Sharif' },
  { state: 'Bihar', city: 'Arrah' },
  { state: 'Bihar', city: 'Begusarai' },
  { state: 'Bihar', city: 'Katihar' },
  { state: 'Bihar', city: 'Munger' },
  { state: 'Bihar', city: 'Chhapra' },
  { state: 'Bihar', city: 'Danapur' },
  { state: 'Bihar', city: 'Bettiah' },
  { state: 'Bihar', city: 'Saharsha' },
  { state: 'Bihar', city: 'Sasaram' },
  { state: 'Bihar', city: 'Hajipur' },
  { state: 'Bihar', city: 'Dehri' },
  { state: 'Bihar', city: 'Siwan' },
  { state: 'Bihar', city: 'Motihari' },
  { state: 'Bihar', city: 'Nawada' },
  { state: 'Bihar', city: 'Bagaha' },
  { state: 'Bihar', city: 'Buxar' },
  { state: 'Bihar', city: 'Kishanganj' },
  { state: 'Bihar', city: 'Sitamarhi' },
  { state: 'Bihar', city: 'Jamalpur' },
  { state: 'Bihar', city: 'Jehanabad' },
  { state: 'Bihar', city: 'Aurangabad' },
  { state: 'Bihar', city: 'Araria' },
  { state: 'Bihar', city: 'Arwal' },
  { state: 'Bihar', city: 'Banka' },
  { state: 'Bihar', city: 'Bhojpur' },
  { state: 'Bihar', city: 'East Champaran' },
  { state: 'Bihar', city: 'Gopalganj' },
  { state: 'Bihar', city: 'Jamui' },
  { state: 'Bihar', city: 'Kaimur Bhabua' },
  { state: 'Bihar', city: 'Khagaria' },
  { state: 'Bihar', city: 'Lakhisarai' },
  { state: 'Bihar', city: 'Madhepura' },
  { state: 'Bihar', city: 'Madhubani' },
  { state: 'Bihar', city: 'Nalanda' },
  { state: 'Bihar', city: 'Rohtas' },
  { state: 'Bihar', city: 'Samastipur' },
  { state: 'Bihar', city: 'Saran' },
  { state: 'Bihar', city: 'Sheikhpura' },
  { state: 'Bihar', city: 'Sheohar' },
  { state: 'Bihar', city: 'Supaul' },
  { state: 'Bihar', city: 'Vaishali' },
  { state: 'Bihar', city: 'West Champaran' },
  { state: 'Chandigarh', city: 'Chandigarh' },
  { state: 'Chhattisgarh', city: 'Bastar' },
  { state: 'Chhattisgarh', city: 'Bijapur' },
  { state: 'Chhattisgarh', city: 'Bilaspur' },
  { state: 'Chhattisgarh', city: 'Dantewada' },
  { state: 'Chhattisgarh', city: 'Dhamtari' },
  { state: 'Chhattisgarh', city: 'Bhilai-Durg' },
  { state: 'Chhattisgarh', city: 'Janjgir-Champa' },
  { state: 'Chhattisgarh', city: 'Jashpur' },
  { state: 'Chhattisgarh', city: 'Kabeerdham' },
  { state: 'Chhattisgarh', city: 'Korba' },
  { state: 'Chhattisgarh', city: 'Koriya' },
  { state: 'Chhattisgarh', city: 'Mahasamund' },
  { state: 'Chhattisgarh', city: 'Narayanpur' },
  { state: 'Chhattisgarh', city: 'Raigarh' },
  { state: 'Chhattisgarh', city: 'Raipur' },
  { state: 'Chhattisgarh', city: 'Rajnandgaon' },
  { state: 'Chhattisgarh', city: 'Sarguja' },
  { state: 'Chhattisgarh', city: 'Kanker' },
  { state: 'Chhattisgarh', city: 'Balod' },
  { state: 'Chhattisgarh', city: 'Balod Bazer' },
  { state: 'Chhattisgarh', city: 'Balrampur' },
  { state: 'Chhattisgarh', city: 'Bemetara' },
  { state: 'Chhattisgarh', city: 'Gariaband' },
  { state: 'Chhattisgarh', city: 'Jagdalpur' },
  { state: 'Chhattisgarh', city: 'Kawardha' },
  { state: 'Chhattisgarh', city: 'Kondagaon' },
  { state: 'Chhattisgarh', city: 'Makdi' },
  { state: 'Chhattisgarh', city: 'Mungeli' },
  { state: 'Chhattisgarh', city: 'Sukma' },
  { state: 'Chhattisgarh', city: 'Surajpur' },
  { state: 'Dadra and Nagar Haveli ', city: 'Dadra' },
  { state: 'Dadra and Nagar Haveli', city: 'Naroli' },
  { state: 'Dadra and Nagar Haveli', city: 'Rakholi' },
  { state: 'Dadra and Nagar Haveli', city: 'Samarvarni' },
  { state: 'Dadra and Nagar Haveli', city: 'Silvassa' },
  { state: 'Daman and Diu', city: 'Bhimpore' },
  { state: 'Daman and Diu', city: 'Dadhel' },
  { state: 'Daman and Diu', city: 'Daman' },
  { state: 'Daman and Diu', city: 'Diu' },
  { state: 'Daman and Diu', city: 'Dunetha' },
  { state: 'Daman and Diu', city: 'Kachigam' },
  { state: 'Daman and Diu', city: 'Kadaiya' },
  { state: 'Daman and Diu', city: 'Marwad' },
  { state: 'Goa', city: 'Bicholim' },
  { state: 'Goa', city: 'Canacona' },
  { state: 'Goa', city: 'Cuncolim' },
  { state: 'Goa', city: 'Curchorem' },
  { state: 'Goa', city: 'Mapusa' },
  { state: 'Goa', city: 'Margao' },
  { state: 'Goa', city: 'Mormugao' },
  { state: 'Goa', city: 'Panaji' },
  { state: 'Goa', city: 'Pernem' },
  { state: 'Goa', city: 'Ponda' },
  { state: 'Goa', city: 'Quepem' },
  { state: 'Goa', city: 'Sanguem' },
  { state: 'Goa', city: 'Sanquelim' },
  { state: 'Goa', city: 'Valpoi' },
  { state: 'Goa', city: 'North Goa' },
  { state: 'Goa', city: 'South Goa' },
  { state: 'Gujarat', city: 'Ahmedabad' },
  { state: 'Gujarat', city: 'Surat' },
  { state: 'Gujarat', city: 'Vadodara' },
  { state: 'Gujarat', city: 'Rajkot' },
  { state: 'Gujarat', city: 'Bhavnagar' },
  { state: 'Gujarat', city: 'Jamnagar' },
  { state: 'Gujarat', city: 'Junagadh' },
  { state: 'Gujarat', city: 'Anand' },
  { state: 'Gujarat', city: 'Navsari' },
  { state: 'Gujarat', city: 'Surendranagar' },
  { state: 'Gujarat', city: 'Morvi' },
  { state: 'Gujarat', city: 'Gandhidham' },
  { state: 'Gujarat', city: 'Nadiad' },
  { state: 'Gujarat', city: 'Bharuch' },
  { state: 'Gujarat', city: 'Porbandar' },
  { state: 'Gujarat', city: 'Amreli' },
  { state: 'Gujarat', city: 'Aravalli' },
  { state: 'Gujarat', city: 'Banaskantha' },
  { state: 'Gujarat', city: 'Botab' },
  { state: 'Gujarat', city: 'Chhota Udepur' },
  { state: 'Gujarat', city: 'Dahod' },
  { state: 'Gujarat', city: 'Dangs' },
  { state: 'Gujarat', city: 'Devbhoomi Dwarka' },
  { state: 'Gujarat', city: 'Gandhi Nagar' },
  { state: 'Gujarat', city: 'Gir Somnath' },
  { state: 'Gujarat', city: 'Kachchh' },
  { state: 'Gujarat', city: 'Kheda' },
  { state: 'Gujarat', city: 'Mahesana' },
  { state: 'Gujarat', city: 'Mahisagar' },
  { state: 'Gujarat', city: 'Narmada' },
  { state: 'Gujarat', city: 'Panch Mahals' },
  { state: 'Gujarat', city: 'Patan' },
  { state: 'Gujarat', city: 'Sabarkantha' },
  { state: 'Gujarat', city: 'Tapi' },
  { state: 'Gujarat', city: 'Valsad' },
  { state: 'Haryana', city: 'Faridabad' },
  { state: 'Haryana', city: 'Gurugram' },
  { state: 'Haryana', city: 'Panipat' },
  { state: 'Haryana', city: 'Ambala' },
  { state: 'Haryana', city: 'Yamunanagar' },
  { state: 'Haryana', city: 'Rohtak' },
  { state: 'Haryana', city: 'Hisar' },
  { state: 'Haryana', city: 'Karnal' },
  { state: 'Haryana', city: 'Sonipat' },
  { state: 'Haryana', city: 'Panchkula' },
  { state: 'Haryana', city: 'Bhiwani' },
  { state: 'Haryana', city: 'Sirsa' },
  { state: 'Haryana', city: 'Bahadurgarh' },
  { state: 'Haryana', city: 'Jind' },
  { state: 'Haryana', city: 'Thanesar' },
  { state: 'Haryana', city: 'Kaithal' },
  { state: 'Haryana', city: 'Rewari' },
  { state: 'Haryana', city: 'Palwal' },
  { state: 'Haryana', city: 'Pundri' },
  { state: 'Haryana', city: 'Kosli' },
  { state: 'Haryana', city: 'Fatehabad' },
  { state: 'Haryana', city: 'Jhajjar' },
  { state: 'Haryana', city: 'Kurukshetra' },
  { state: 'Haryana', city: 'Mahendragarh' },
  { state: 'Haryana', city: 'Mewat' },
  { state: 'Himachal Pradesh', city: 'Shimla' },
  { state: 'Himachal Pradesh', city: 'Solan' },
  { state: 'Himachal Pradesh', city: 'Dharamsala' },
  { state: 'Himachal Pradesh', city: 'Baddi' },
  { state: 'Himachal Pradesh', city: 'Nahan' },
  { state: 'Himachal Pradesh', city: 'Mandi' },
  { state: 'Himachal Pradesh', city: 'Paonta Sahib' },
  { state: 'Himachal Pradesh', city: 'Sundarnagar' },
  { state: 'Himachal Pradesh', city: 'Chamba' },
  { state: 'Himachal Pradesh', city: 'Una' },
  { state: 'Himachal Pradesh', city: 'Kullu' },
  { state: 'Himachal Pradesh', city: 'Hamirpur' },
  { state: 'Himachal Pradesh', city: 'Bilaspur' },
  { state: 'Himachal Pradesh', city: 'Yol Cantonment' },
  { state: 'Himachal Pradesh', city: 'Nalagarh' },
  { state: 'Himachal Pradesh', city: 'Nurpur' },
  { state: 'Himachal Pradesh', city: 'Kangra' },
  { state: 'Himachal Pradesh', city: 'Santokhgarh' },
  { state: 'Himachal Pradesh', city: 'Mehatpur Basdehra' },
  { state: 'Himachal Pradesh', city: 'Shamshi' },
  { state: 'Himachal Pradesh', city: 'Parwanoo' },
  { state: 'Himachal Pradesh', city: 'Manali' },
  { state: 'Himachal Pradesh', city: 'Tira Sujanpur' },
  { state: 'Himachal Pradesh', city: 'Ghumarwin' },
  { state: 'Himachal Pradesh', city: 'Dalhousie' },
  { state: 'Himachal Pradesh', city: 'Rohru' },
  { state: 'Himachal Pradesh', city: 'Nagrota Bagwan' },
  { state: 'Himachal Pradesh', city: 'Rampur' },
  { state: 'Himachal Pradesh', city: 'Kumarsain' },
  { state: 'Himachal Pradesh', city: 'Jawalamukhi' },
  { state: 'Himachal Pradesh', city: 'Jogindernagar' },
  { state: 'Himachal Pradesh', city: 'Dera Gopipur' },
  { state: 'Himachal Pradesh', city: 'Sarkaghat' },
  { state: 'Himachal Pradesh', city: 'Jhakhri' },
  { state: 'Himachal Pradesh', city: 'Indora' },
  { state: 'Himachal Pradesh', city: 'Bhuntar' },
  { state: 'Himachal Pradesh', city: 'Nadaun' },
  { state: 'Himachal Pradesh', city: 'Theog' },
  { state: 'Himachal Pradesh', city: 'Kasauli Cantonment' },
  { state: 'Himachal Pradesh', city: 'Gagret' },
  { state: 'Himachal Pradesh', city: 'Chuari Khas' },
  { state: 'Himachal Pradesh', city: 'Daulatpur' },
  { state: 'Himachal Pradesh', city: 'Sabathu Cantonment' },
  { state: 'Himachal Pradesh', city: 'Dalhousie Cantonment' },
  { state: 'Himachal Pradesh', city: 'Palampur' },
  { state: 'Himachal Pradesh', city: 'Rajgarh' },
  { state: 'Himachal Pradesh', city: 'Arki' },
  { state: 'Himachal Pradesh', city: 'Dagshai Cantonment' },
  { state: 'Himachal Pradesh', city: 'Seoni' },
  { state: 'Himachal Pradesh', city: 'Talai' },
  { state: 'Himachal Pradesh', city: 'Jutogh Cantonment' },
  { state: 'Himachal Pradesh', city: 'Chaupal' },
  { state: 'Himachal Pradesh', city: 'Rewalsar' },
  { state: 'Himachal Pradesh', city: 'Bakloh Cantonment' },
  { state: 'Himachal Pradesh', city: 'Jubbal' },
  { state: 'Himachal Pradesh', city: 'Bhota' },
  { state: 'Himachal Pradesh', city: 'Banjar' },
  { state: 'Himachal Pradesh', city: 'Naina Devi' },
  { state: 'Himachal Pradesh', city: 'Kotkhai' },
  { state: 'Himachal Pradesh', city: 'Narkanda' },
  { state: 'Himachal Pradesh', city: 'Kinnaur' },
  { state: 'Himachal Pradesh', city: 'Lahul Spiti' },
  { state: 'Himachal Pradesh', city: 'Sirmaur' },
  { state: 'Jammu And Kashmir', city: 'Srinagar(Srinagar)' },
  { state: 'Jammu And Kashmir', city: 'Jammu' },
  { state: 'Jammu And Kashmir', city: 'Anantnag' },
  { state: 'Jammu And Kashmir', city: 'Udhampur' },
  { state: 'Jammu And Kashmir', city: 'Baramula(Baramulla)' },
  { state: 'Jammu And Kashmir', city: 'Sopore' },
  { state: 'Jammu And Kashmir', city: 'Kathua' },
  { state: 'Jammu And Kashmir', city: 'Bandipura(Bandipore)' },
  { state: 'Jammu And Kashmir', city: 'Leh' },
  { state: 'Jammu And Kashmir', city: 'Rajauri(Rajouri)' },
  { state: 'Jammu And Kashmir', city: 'Ganderbal' },
  { state: 'Jammu And Kashmir', city: 'Punch(Poonch)' },
  { state: 'Jammu And Kashmir', city: 'Kulgam' },
  { state: 'Jammu And Kashmir', city: 'Duru Verinag' },
  { state: 'Jammu And Kashmir', city: 'Bijbiara(Bijbehara)' },
  { state: 'Jammu And Kashmir', city: 'Kupwara' },
  { state: 'Jammu And Kashmir', city: 'Doda' },
  { state: 'Jammu And Kashmir', city: 'Akhnoor' },
  { state: 'Jammu And Kashmir', city: 'Budgam' },
  { state: 'Jammu And Kashmir', city: 'Kargil' },
  { state: 'Jammu And Kashmir', city: 'Pulwama' },
  { state: 'Jammu And Kashmir', city: 'Ramban' },
  { state: 'Jammu And Kashmir', city: 'Reasi' },
  { state: 'Jammu And Kashmir', city: 'Samba' },
  { state: 'Jammu And Kashmir', city: 'Shopian' },
  { state: 'Jharkhand', city: 'Dhanbad' },
  { state: 'Jharkhand', city: 'Jamshedpur' },
  { state: 'Jharkhand', city: 'Ranchi' },
  { state: 'Jharkhand', city: 'Bokaro Steel City' },
  { state: 'Jharkhand', city: 'Deoghar' },
  { state: 'Jharkhand', city: 'Chakradharpur' },
  { state: 'Jharkhand', city: 'Phusro' },
  { state: 'Jharkhand', city: 'Hazaribagh' },
  { state: 'Jharkhand', city: 'Giridih' },
  { state: 'Jharkhand', city: 'Ramgarh' },
  { state: 'Jharkhand', city: 'Medininagar' },
  { state: 'Jharkhand', city: 'Chirkunda' },
  { state: 'Jharkhand', city: 'Chatra' },
  { state: 'Jharkhand', city: 'Dumka' },
  { state: 'Jharkhand', city: 'East Singhbhum' },
  { state: 'Jharkhand', city: 'Garhwa' },
  { state: 'Jharkhand', city: 'Godda' },
  { state: 'Jharkhand', city: 'Gumla' },
  { state: 'Jharkhand', city: 'Jamtara' },
  { state: 'Jharkhand', city: 'Khunti' },
  { state: 'Jharkhand', city: 'Koderma' },
  { state: 'Jharkhand', city: 'Latehar' },
  { state: 'Jharkhand', city: 'Lohardaga' },
  { state: 'Jharkhand', city: 'Pakur' },
  { state: 'Jharkhand', city: 'Palamau' },
  { state: 'Jharkhand', city: 'Sahibganj' },
  { state: 'Jharkhand', city: 'Seraikelakharsawan' },
  { state: 'Jharkhand', city: 'Simdega' },
  { state: 'Jharkhand', city: 'West Singhbhum' },
  { state: 'Karnataka', city: 'Bengaluru' },
  { state: 'Karnataka', city: 'Hubballi - Dharwad' },
  { state: 'Karnataka', city: 'Mysuru' },
  { state: 'Karnataka', city: 'Kalaburagi' },
  { state: 'Karnataka', city: 'Mangaluru' },
  { state: 'Karnataka', city: 'Belagavi' },
  { state: 'Karnataka', city: 'Davanagere' },
  { state: 'Karnataka', city: 'Ballari' },
  { state: 'Karnataka', city: 'Vijayapura' },
  { state: 'Karnataka', city: 'Shivamogga' },
  { state: 'Karnataka', city: 'Tumakuru' },
  { state: 'Karnataka', city: 'Raichur' },
  { state: 'Karnataka', city: 'Bidar' },
  { state: 'Karnataka', city: 'Hosapete' },
  { state: 'Karnataka', city: 'Gadag - Betageri' },
  { state: 'Karnataka', city: 'Robertsonpete' },
  { state: 'Karnataka', city: 'Hassan' },
  { state: 'Karnataka', city: 'Bhadravati' },
  { state: 'Karnataka', city: 'Chitradurga' },
  { state: 'Karnataka', city: 'Udupi' },
  { state: 'Karnataka', city: 'Kolara' },
  { state: 'Karnataka', city: 'Mandya' },
  { state: 'Karnataka', city: 'Chikkamagaluru' },
  { state: 'Karnataka', city: 'Gangavati' },
  { state: 'Karnataka', city: 'Bagalkote' },
  { state: 'Karnataka', city: 'Ranebennuru' },
  { state: 'Karnataka', city: 'Arsikere' },
  { state: 'Karnataka', city: 'Athani' },
  { state: 'Karnataka', city: 'Bailhongal' },
  { state: 'Karnataka', city: 'Belgaum' },
  { state: 'Karnataka', city: 'Bijapur' },
  { state: 'Karnataka', city: 'Chamarajanagar' },
  { state: 'Karnataka', city: 'Chikkaballapur' },
  { state: 'Karnataka', city: 'Dakshina Kannada' },
  { state: 'Karnataka', city: 'Gulbarga' },
  { state: 'Karnataka', city: 'Hanagodu' },
  { state: 'Karnataka', city: 'Haveri' },
  { state: 'Karnataka', city: 'Hunsur' },
  { state: 'Karnataka', city: 'Jamkhandi' },
  { state: 'Karnataka', city: 'Joida' },
  { state: 'Karnataka', city: 'Kalghatgi' },
  { state: 'Karnataka', city: 'Kodagu' },
  { state: 'Karnataka', city: 'Kollegal' },
  { state: 'Karnataka', city: 'Koppal' },
  { state: 'Karnataka', city: 'Kundgol' },
  { state: 'Karnataka', city: 'Madikeri' },
  { state: 'Karnataka', city: 'Nanjangud' },
  { state: 'Karnataka', city: 'Ramanagar' },
  { state: 'Karnataka', city: 'Ramdurg' },
  { state: 'Karnataka', city: 'Sagar' },
  { state: 'Karnataka', city: 'Saraswathipuram' },
  { state: 'Karnataka', city: 'Siddapur' },
  { state: 'Karnataka', city: 'Uttara Kannada' },
  { state: 'Karnataka', city: 'Yadgir' },
  { state: 'Kerala', city: 'Kochi' },
  { state: 'Kerala', city: 'Kozhikode' },
  { state: 'Kerala', city: 'Thiruvananthapuram' },
  { state: 'Kerala', city: 'Kollam' },
  { state: 'Kerala', city: 'Thrissur' },
  { state: 'Kerala', city: 'Kannur' },
  { state: 'Kerala', city: 'Alappuzha' },
  { state: 'Kerala', city: 'Kottayam' },
  { state: 'Kerala', city: 'Palakkad' },
  { state: 'Kerala', city: 'Changanassery' },
  { state: 'Kerala', city: 'Malappuram' },
  { state: 'Kerala', city: 'Manjeri' },
  { state: 'Kerala', city: 'Kodungallur' },
  { state: 'Kerala', city: 'Thalassery' },
  { state: 'Kerala', city: 'Thrippunithura' },
  { state: 'Kerala', city: 'Ponnani' },
  { state: 'Kerala', city: 'Thrikkakkara' },
  { state: 'Kerala', city: 'Vatakara' },
  { state: 'Kerala', city: 'Kanhangad' },
  { state: 'Kerala', city: 'Taliparamba' },
  { state: 'Kerala', city: 'Koyilandy' },
  { state: 'Kerala', city: 'Neyyattinkara' },
  { state: 'Kerala', city: 'Kalamassery' },
  { state: 'Kerala', city: 'Kayamkulam' },
  { state: 'Kerala', city: 'Beypore' },
  { state: 'Kerala', city: 'Punalur' },
  { state: 'Kerala', city: 'Muvattupuzha' },
  { state: 'Kerala', city: 'Nedumangad' },
  { state: 'Kerala', city: 'Tirur' },
  { state: 'Kerala', city: 'Nileshwaram' },
  { state: 'Kerala', city: 'Kasaragod' },
  { state: 'Kerala', city: 'Kunnamkulam' },
  { state: 'Kerala', city: 'Ottappalam' },
  { state: 'Kerala', city: 'Tiruvalla' },
  { state: 'Kerala', city: 'Thodupuzha' },
  { state: 'Kerala', city: 'Chalakkudy' },
  { state: 'Kerala', city: 'Karunagappalli' },
  { state: 'Kerala', city: 'Thirurangadi' },
  { state: 'Kerala', city: 'Kottarakara' },
  { state: 'Kerala', city: 'Cherthala' },
  { state: 'Kerala', city: 'Maradu' },
  { state: 'Kerala', city: 'Perinthalmanna' },
  { state: 'Kerala', city: 'Shornur' },
  { state: 'Kerala', city: 'Kottakkal' },
  { state: 'Kerala', city: 'Varkala' },
  { state: 'Kerala', city: 'Manjeshwaram' },
  { state: 'Kerala', city: 'Uppala' },
  { state: 'Kerala', city: 'Pathanamthitta' },
  { state: 'Kerala', city: 'Mattanur' },
  { state: 'Kerala', city: 'Chavakkad' },
  { state: 'Kerala', city: 'Kattappana' },
  { state: 'Kerala', city: 'Adoor' },
  { state: 'Kerala', city: 'Attingal' },
  { state: 'Kerala', city: 'Paravur' },
  { state: 'Kerala', city: 'Ramanattukara' },
  { state: 'Kerala', city: 'Pandalam' },
  { state: 'Kerala', city: 'Anchal' },
  { state: 'Kerala', city: 'Edava' },
  { state: 'Kerala', city: 'Kalpetta' },
  { state: 'Kerala', city: 'Sultan Bathery' },
  { state: 'Kerala', city: 'Mananthavady' },
  { state: 'Kerala', city: 'Ernakulam' },
  { state: 'Kerala', city: 'Idukki' },
  { state: 'Kerala', city: 'Mahe' },
  { state: 'Kerala', city: 'Wayanad' },
  { state: 'Lakshadweep', city: 'Lakshadweep' },
  { state: 'Madhya Pradesh', city: 'Indore' },
  { state: 'Madhya Pradesh', city: 'Bhopal' },
  { state: 'Madhya Pradesh', city: 'Jabalpur' },
  { state: 'Madhya Pradesh', city: 'Gwalior' },
  { state: 'Madhya Pradesh', city: 'Ujjain' },
  { state: 'Madhya Pradesh', city: 'Sagar' },
  { state: 'Madhya Pradesh', city: 'Dewas' },
  { state: 'Madhya Pradesh', city: 'Satna' },
  { state: 'Madhya Pradesh', city: 'Ratlam' },
  { state: 'Madhya Pradesh', city: 'Rewa' },
  { state: 'Madhya Pradesh', city: 'Murwara(Katni)' },
  { state: 'Madhya Pradesh', city: 'Singrauli' },
  { state: 'Madhya Pradesh', city: 'Burhanpur' },
  { state: 'Madhya Pradesh', city: 'Khandwa' },
  { state: 'Madhya Pradesh', city: 'Bhind' },
  { state: 'Madhya Pradesh', city: 'Chhindwara' },
  { state: 'Madhya Pradesh', city: 'Guna' },
  { state: 'Madhya Pradesh', city: 'Shivpuri' },
  { state: 'Madhya Pradesh', city: 'Vidisha' },
  { state: 'Madhya Pradesh', city: 'Chhatarpur' },
  { state: 'Madhya Pradesh', city: 'Damoh' },
  { state: 'Madhya Pradesh', city: 'Mandsaur' },
  { state: 'Madhya Pradesh', city: 'Khargone' },
  { state: 'Madhya Pradesh', city: 'Neemuch' },
  { state: 'Madhya Pradesh', city: 'Pithampur' },
  { state: 'Madhya Pradesh', city: 'Hoshangabad' },
  { state: 'Madhya Pradesh', city: 'Itarsi' },
  { state: 'Madhya Pradesh', city: 'Sehore' },
  { state: 'Madhya Pradesh', city: 'Betul' },
  { state: 'Madhya Pradesh', city: 'Seoni' },
  { state: 'Madhya Pradesh', city: 'Datia' },
  { state: 'Madhya Pradesh', city: 'Nagda' },
  { state: 'Madhya Pradesh', city: 'Raisen' },
  { state: 'Madhya Pradesh', city: 'Agar Malwa' },
  { state: 'Madhya Pradesh', city: 'Alirajpur' },
  { state: 'Madhya Pradesh', city: 'Anuppur' },
  { state: 'Madhya Pradesh', city: 'Ashok Nagar' },
  { state: 'Madhya Pradesh', city: 'Balaghat' },
  { state: 'Madhya Pradesh', city: 'Barwani' },
  { state: 'Madhya Pradesh', city: 'Dhar' },
  { state: 'Madhya Pradesh', city: 'Dindori' },
  { state: 'Madhya Pradesh', city: 'Harda' },
  { state: 'Madhya Pradesh', city: 'Jhabua' },
  { state: 'Madhya Pradesh', city: 'Mandla' },
  { state: 'Madhya Pradesh', city: 'Morena' },
  { state: 'Madhya Pradesh', city: 'Narsinghpur' },
  { state: 'Madhya Pradesh', city: 'Panna' },
  { state: 'Madhya Pradesh', city: 'Rajgarh' },
  { state: 'Madhya Pradesh', city: 'Shahdol' },
  { state: 'Madhya Pradesh', city: 'Shajapur' },
  { state: 'Madhya Pradesh', city: 'Sheopur' },
  { state: 'Madhya Pradesh', city: 'Sidhi' },
  { state: 'Madhya Pradesh', city: 'Tikamgarh' },
  { state: 'Madhya Pradesh', city: 'Umaria' },
  { state: 'Maharashtra', city: 'Mumbai' },
  { state: 'Maharashtra', city: 'Pune' },
  { state: 'Maharashtra', city: 'Nagpur' },
  { state: 'Maharashtra', city: 'Thane' },
  { state: 'Maharashtra', city: 'Pimpri - Chinchwad' },
  { state: 'Maharashtra', city: 'Nashik' },
  { state: 'Maharashtra', city: 'Kalyan - Dombivli' },
  { state: 'Maharashtra', city: 'Vasai - Virar City Mc' },
  { state: 'Maharashtra', city: 'Aurangabad' },
  { state: 'Maharashtra', city: 'Navi Mumbai' },
  { state: 'Maharashtra', city: 'Solapur' },
  { state: 'Maharashtra', city: 'Mira - Bhayandar' },
  { state: 'Maharashtra', city: 'Bhiwandi - Nizampur Mc' },
  { state: 'Maharashtra', city: 'Amravati' },
  { state: 'Maharashtra', city: 'Nanded Waghala' },
  { state: 'Maharashtra', city: 'Kolhapur' },
  { state: 'Maharashtra', city: 'Ulhasnagar' },
  { state: 'Maharashtra', city: 'Sangli - Miraj - Kupwad' },
  { state: 'Maharashtra', city: 'Malegaon' },
  { state: 'Maharashtra', city: 'Jalgaon' },
  { state: 'Maharashtra', city: 'Akola' },
  { state: 'Maharashtra', city: 'Latur' },
  { state: 'Maharashtra', city: 'Dhule' },
  { state: 'Maharashtra', city: 'Ahmednagar' },
  { state: 'Maharashtra', city: 'Chandrapur' },
  { state: 'Maharashtra', city: 'Parbhani' },
  { state: 'Maharashtra', city: 'Ichalkaranji' },
  { state: 'Maharashtra', city: 'Jalna' },
  { state: 'Maharashtra', city: 'Ambarnath' },
  { state: 'Maharashtra', city: 'Bhusawal' },
  { state: 'Maharashtra', city: 'Panvel' },
  { state: 'Maharashtra', city: 'Ratnagiri' },
  { state: 'Maharashtra', city: 'Beed' },
  { state: 'Maharashtra', city: 'Gondia' },
  { state: 'Maharashtra', city: 'Satara' },
  { state: 'Maharashtra', city: 'Barshi' },
  { state: 'Maharashtra', city: 'Yavatmal' },
  { state: 'Maharashtra', city: 'Achalpur' },
  { state: 'Maharashtra', city: 'Osmanabad' },
  { state: 'Maharashtra', city: 'Nandurbar' },
  { state: 'Maharashtra', city: 'Wardha' },
  { state: 'Maharashtra', city: 'Udgir' },
  { state: 'Maharashtra', city: 'Hinganghat' },
  { state: 'Maharashtra', city: 'Bhandara' },
  { state: 'Maharashtra', city: 'Buldhana' },
  { state: 'Maharashtra', city: 'Gadchiroli' },
  { state: 'Maharashtra', city: 'Hingoli' },
  { state: 'Maharashtra', city: 'Palghar' },
  { state: 'Maharashtra', city: 'Raigarh' },
  { state: 'Maharashtra', city: 'Shrirampur' },
  { state: 'Maharashtra', city: 'Sindhudurg' },
  { state: 'Maharashtra', city: 'Wardha' },
  { state: 'Maharashtra', city: 'Washim' },
  { state: 'Manipur', city: 'Andro' },
  { state: 'Manipur', city: 'Bishnupur' },
  { state: 'Manipur', city: 'Chingangbam Leikai' },
  { state: 'Manipur', city: 'Heingang' },
  { state: 'Manipur', city: 'Heirok' },
  { state: 'Manipur', city: 'Hill Town' },
  { state: 'Manipur', city: 'Imphal' },
  { state: 'Manipur', city: 'Jiribam' },
  { state: 'Manipur', city: 'Kakching' },
  { state: 'Manipur', city: 'Kakching Khunou' },
  { state: 'Manipur', city: 'Kangpokpi' },
  { state: 'Manipur', city: 'Khongman' },
  { state: 'Manipur', city: 'Khurai Sajor Leikai' },
  { state: 'Manipur', city: 'Kiyamgei' },
  { state: 'Manipur', city: 'Kshetrigao' },
  { state: 'Manipur', city: 'Kumbi' },
  { state: 'Manipur', city: 'Kwakta' },
  { state: 'Manipur', city: 'Laipham Siphai' },
  { state: 'Manipur', city: 'Lairikyengbam Leikai' },
  { state: 'Manipur', city: 'Lamjaotongba' },
  { state: 'Manipur', city: 'Lamlai' },
  { state: 'Manipur', city: 'Lamsang(Lamshang)' },
  { state: 'Manipur', city: 'Langjing' },
  { state: 'Manipur', city: 'Lilong(Thoubal)' },
  { state: 'Manipur', city: 'Lilong(Imphal West)' },
  { state: 'Manipur', city: 'Luwangsangbam' },
  { state: 'Manipur', city: 'Mayang Imphal' },
  { state: 'Manipur', city: 'Moirang' },
  { state: 'Manipur', city: 'Moreh' },
  { state: 'Manipur', city: 'Nambol' },
  { state: 'Manipur', city: 'Naoriya Pakhanglakpa' },
  { state: 'Manipur', city: 'Ningthoukhong' },
  { state: 'Manipur', city: 'Oinam' },
  { state: 'Manipur', city: 'Porompat' },
  { state: 'Manipur', city: 'Rengkai' },
  { state: 'Manipur', city: 'Sagolband' },
  { state: 'Manipur', city: 'Samurou' },
  { state: 'Manipur', city: 'Sekmai Bazar' },
  { state: 'Manipur', city: 'Sikhong Sekmai' },
  { state: 'Manipur', city: 'Sugnu' },
  { state: 'Manipur', city: 'Takyel Mapal' },
  { state: 'Manipur', city: 'Tamenglong' },
  { state: 'Manipur', city: 'Thongju' },
  { state: 'Manipur', city: 'Thongkhong Laxmi Bazar' },
  { state: 'Manipur', city: 'Thoubal' },
  { state: 'Manipur', city: 'Torban(Kshetri Leikai)' },
  { state: 'Manipur', city: 'Ukhrul' },
  { state: 'Manipur', city: 'Wangjing' },
  { state: 'Manipur', city: 'Wangoi' },
  { state: 'Manipur', city: 'Yairipok' },
  { state: 'Manipur', city: 'Zenhang Lamka' },
  { state: 'Manipur', city: 'Chandel' },
  { state: 'Manipur', city: 'Churachandpur' },
  { state: 'Manipur', city: 'Senapati' },
  { state: 'Manipur', city: 'Tengnoupal' },
  { state: 'Manipur', city: 'Thanlon' },
  { state: 'Meghalaya', city: 'Baghmara' },
  { state: 'Meghalaya', city: 'Cherrapunjee(Cherrapunji)' },
  { state: 'Meghalaya', city: 'Jowai' },
  { state: 'Meghalaya', city: 'Lawsohtun' },
  { state: 'Meghalaya', city: 'Madanriting(Madanrting)' },
  { state: 'Meghalaya', city: 'Mairang' },
  { state: 'Meghalaya', city: 'Mawlai' },
  { state: 'Meghalaya', city: 'Mawpat' },
  { state: 'Meghalaya', city: 'Nongkseh' },
  { state: 'Meghalaya', city: 'Nongmynsong' },
  { state: 'Meghalaya', city: 'Nongpoh' },
  { state: 'Meghalaya', city: 'Nongstoin' },
  { state: 'Meghalaya', city: 'Nongthymmai' },
  { state: 'Meghalaya', city: 'Pynthormukhrah(Pynthorumkhrah)' },
  { state: 'Meghalaya', city: 'Resubelpara' },
  { state: 'Meghalaya', city: 'Shillong' },
  { state: 'Meghalaya', city: 'Shillong Cantonment' },
  { state: 'Meghalaya', city: 'Tura' },
  { state: 'Meghalaya', city: 'Umlyngka' },
  { state: 'Meghalaya', city: 'Umpling' },
  { state: 'Meghalaya', city: 'Umroi' },
  { state: 'Meghalaya', city: 'Williamnagar' },
  { state: 'Meghalaya', city: 'East Garo Hills' },
  { state: 'Meghalaya', city: 'East Jaintia Hills' },
  { state: 'Meghalaya', city: 'East Khasi Hills' },
  { state: 'Meghalaya', city: 'Jaintia Hills' },
  { state: 'Meghalaya', city: 'Ri Bhoi' },
  { state: 'Meghalaya', city: 'South Garo Hills' },
  { state: 'Meghalaya', city: 'South West Khasi Hills' },
  { state: 'Meghalaya', city: 'West Garo Hills' },
  { state: 'Meghalaya', city: 'West Jaintia Hills' },
  { state: 'Meghalaya', city: 'West Khasi Hills' },
  { state: 'Mizoram', city: 'Aizawl' },
  { state: 'Mizoram', city: 'Bairabi' },
  { state: 'Mizoram', city: 'Biate' },
  { state: 'Mizoram', city: 'Champhai' },
  { state: 'Mizoram', city: 'Darlawn' },
  { state: 'Mizoram', city: 'Hnahthial' },
  { state: 'Mizoram', city: 'Khawhai' },
  { state: 'Mizoram', city: 'Khawzawl' },
  { state: 'Mizoram', city: 'Kolasib' },
  { state: 'Mizoram', city: 'Lawngtlai' },
  { state: 'Mizoram', city: 'Lengpui' },
  { state: 'Mizoram', city: 'Lunglei' },
  { state: 'Mizoram', city: 'Mamit' },
  { state: 'Mizoram', city: 'N.Kawnpui' },
  { state: 'Mizoram', city: 'North Vanlaiphai' },
  { state: 'Mizoram', city: 'Saiha' },
  { state: 'Mizoram', city: 'Sairang' },
  { state: 'Mizoram', city: 'Saitual' },
  { state: 'Mizoram', city: 'Serchhip' },
  { state: 'Mizoram', city: 'Thenzawl' },
  { state: 'Mizoram', city: 'Tlabung' },
  { state: 'Mizoram', city: 'Vairengte' },
  { state: 'Mizoram', city: 'Zawlnuam' },
  { state: 'Nagaland', city: 'Pfutsero' },
  { state: 'Nagaland', city: 'Changtongya' },
  { state: 'Nagaland', city: 'Chumukedima' },
  { state: 'Nagaland', city: 'Dimapur' },
  { state: 'Nagaland', city: 'Jalukie' },
  { state: 'Nagaland', city: 'Kiphire' },
  { state: 'Nagaland', city: 'Kohima' },
  { state: 'Nagaland', city: 'Kohima Village' },
  { state: 'Nagaland', city: 'Kuda' },
  { state: 'Nagaland', city: 'Longleng' },
  { state: 'Nagaland', city: 'Medziphema' },
  { state: 'Nagaland', city: 'Mokokchung' },
  { state: 'Nagaland', city: 'Mon Town' },
  { state: 'Nagaland', city: 'Naginimora' },
  { state: 'Nagaland', city: 'Peren' },
  { state: 'Nagaland', city: 'Phek' },
  { state: 'Nagaland', city: 'Rangapahar' },
  { state: 'Nagaland', city: 'Satakha Hq.' },
  { state: 'Nagaland', city: 'Tseminyu' },
  { state: 'Nagaland', city: 'Tsudikong(13th Mile Tuli Paper Mill)' },
  { state: 'Nagaland', city: 'Tuensang' },
  { state: 'Nagaland', city: 'Tuli' },
  { state: 'Nagaland', city: 'Wokha' },
  { state: 'Nagaland', city: 'Zunheboto' },
  { state: 'Nagaland', city: 'Puranabazar' },
  { state: 'Nagaland', city: 'Diphupar' },
  { state: 'Odisha', city: 'Bhubaneswar' },
  { state: 'Odisha', city: 'Cuttack' },
  { state: 'Odisha', city: 'Rourkela' },
  { state: 'Odisha', city: 'Brahmapur' },
  { state: 'Odisha', city: 'Sambalpur' },
  { state: 'Odisha', city: 'Puri' },
  { state: 'Odisha', city: 'Balasore' },
  { state: 'Odisha', city: 'Bhadrak' },
  { state: 'Odisha', city: 'Baripada' },
  { state: 'Odisha', city: 'Balangir' },
  { state: 'Odisha', city: 'Jharsuguda' },
  { state: 'Odisha', city: 'Jeypore' },
  { state: 'Odisha', city: 'Bargarh' },
  { state: 'Odisha', city: 'Rayagada' },
  { state: 'Odisha', city: 'Angul' },
  { state: 'Odisha', city: 'Boudh' },
  { state: 'Odisha', city: 'Debagarh' },
  { state: 'Odisha', city: 'Dhenkanal' },
  { state: 'Odisha', city: 'Gajapati' },
  { state: 'Odisha', city: 'Ganjam' },
  { state: 'Odisha', city: 'Jagatsinghapur' },
  { state: 'Odisha', city: 'Jajapur' },
  { state: 'Odisha', city: 'Kalahandi' },
  { state: 'Odisha', city: 'Kandhamal' },
  { state: 'Odisha', city: 'Kendrapara' },
  { state: 'Odisha', city: 'Kendujhar' },
  { state: 'Odisha', city: 'Khorda' },
  { state: 'Odisha', city: 'Koraput' },
  { state: 'Odisha', city: 'Malkangiri' },
  { state: 'Odisha', city: 'Mayurbhanj' },
  { state: 'Odisha', city: 'Nabarangapur' },
  { state: 'Odisha', city: 'Nayagarh' },
  { state: 'Odisha', city: 'Nuapada' },
  { state: 'Odisha', city: 'Sonepur' },
  { state: 'Odisha', city: 'Sundergarh' },
  { state: 'Puducherry', city: 'Ariankuppam' },
  { state: 'Puducherry', city: 'Karaikal' },
  { state: 'Puducherry', city: 'Kurumbapet' },
  { state: 'Puducherry', city: 'Mahe' },
  { state: 'Puducherry', city: 'Manavely' },
  { state: 'Puducherry', city: 'Ozhukarai' },
  { state: 'Puducherry', city: 'Puducherry' },
  { state: 'Puducherry', city: 'Thirumalairayanpattinam' },
  { state: 'Puducherry', city: 'Villianur' },
  { state: 'Puducherry', city: 'Yanam' },
  { state: 'Punjab', city: 'Ludhiana' },
  { state: 'Punjab', city: 'Amritsar' },
  { state: 'Punjab', city: 'Jalandhar' },
  { state: 'Punjab', city: 'Patiala' },
  { state: 'Punjab', city: 'Bathinda' },
  { state: 'Punjab', city: 'Hoshiarpur' },
  { state: 'Punjab', city: 'Mohali' },
  { state: 'Punjab', city: 'Batala' },
  { state: 'Punjab', city: 'Pathankot' },
  { state: 'Punjab', city: 'Moga' },
  { state: 'Punjab', city: 'Abohar' },
  { state: 'Punjab', city: 'Malerkotla' },
  { state: 'Punjab', city: 'Khanna' },
  { state: 'Punjab', city: 'Phagwara' },
  { state: 'Punjab', city: 'Muktasar' },
  { state: 'Punjab', city: 'Barnala' },
  { state: 'Punjab', city: 'Rajpura' },
  { state: 'Punjab', city: 'Firozpur' },
  { state: 'Punjab', city: 'Kapurthala' },
  { state: 'Punjab', city: 'Sunam' },
  { state: 'Punjab', city: 'Sangrur' },
  { state: 'Punjab', city: 'Faridkot' },
  { state: 'Punjab', city: 'Fatehgarh Sahib' },
  { state: 'Punjab', city: 'Fazilka' },
  { state: 'Punjab', city: 'Gurdaspur' },
  { state: 'Punjab', city: 'Mansa' },
  { state: 'Punjab', city: 'Nawanshahr' },
  { state: 'Punjab', city: 'Ropar' },
  { state: 'Punjab', city: 'Rupnagar' },
  { state: 'Punjab', city: 'Tarn Taran' },
  { state: 'Rajasthan', city: 'Jaipur' },
  { state: 'Rajasthan', city: 'Jodhpur' },
  { state: 'Rajasthan', city: 'Kota' },
  { state: 'Rajasthan', city: 'Bikaner' },
  { state: 'Rajasthan', city: 'Ajmer' },
  { state: 'Rajasthan', city: 'Udaipur' },
  { state: 'Rajasthan', city: 'Bhilwara' },
  { state: 'Rajasthan', city: 'Alwar' },
  { state: 'Rajasthan', city: 'Bharatpur' },
  { state: 'Rajasthan', city: 'Sri Ganganagar' },
  { state: 'Rajasthan', city: 'Sikar' },
  { state: 'Rajasthan', city: 'Hanumangarh' },
  { state: 'Rajasthan', city: 'Banswara' },
  { state: 'Rajasthan', city: 'Baran' },
  { state: 'Rajasthan', city: 'Barmer' },
  { state: 'Rajasthan', city: 'Bundi' },
  { state: 'Rajasthan', city: 'Chittorgarh' },
  { state: 'Rajasthan', city: 'Churu' },
  { state: 'Rajasthan', city: 'Dausa' },
  { state: 'Rajasthan', city: 'Dholpur' },
  { state: 'Rajasthan', city: 'Dungarpur' },
  { state: 'Rajasthan', city: 'Jaisalmer' },
  { state: 'Rajasthan', city: 'Jalor' },
  { state: 'Rajasthan', city: 'Jhalawar' },
  { state: 'Rajasthan', city: 'Jhujhunu' },
  { state: 'Rajasthan', city: 'Karauli' },
  { state: 'Rajasthan', city: 'Nagaur' },
  { state: 'Rajasthan', city: 'Pali' },
  { state: 'Rajasthan', city: 'Pratapgarh' },
  { state: 'Rajasthan', city: 'Rajsamand' },
  { state: 'Rajasthan', city: 'Sawai Madhopur' },
  { state: 'Rajasthan', city: 'Sirohi' },
  { state: 'Rajasthan', city: 'Tonk' },
  { state: 'Sikkim', city: 'Gangtok' },
  { state: 'Sikkim', city: 'Gyalshing' },
  { state: 'Sikkim', city: 'Jorethang' },
  { state: 'Sikkim', city: 'Mangan' },
  { state: 'Sikkim', city: 'Namchi' },
  { state: 'Sikkim', city: 'Nayabazar' },
  { state: 'Sikkim', city: 'Rangpo' },
  { state: 'Sikkim', city: 'Rhenak' },
  { state: 'Sikkim', city: 'Singtam' },
  { state: 'Sikkim', city: 'East Sikkim' },
  { state: 'Sikkim', city: 'North Sikkim' },
  { state: 'Sikkim', city: 'South Sikkim' },
  { state: 'Sikkim', city: 'West Sikkim' },
  { state: 'Tamil nadu', city: 'Chennai' },
  { state: 'Tamil nadu', city: 'Coimbatore' },
  { state: 'Tamil nadu', city: 'Madurai' },
  { state: 'Tamil nadu', city: 'Tiruchirappalli' },
  { state: 'Tamil nadu', city: 'Tiruppur' },
  { state: 'Tamil nadu', city: 'Salem' },
  { state: 'Tamil nadu', city: 'Erode' },
  { state: 'Tamil nadu', city: 'Tirunelveli' },
  { state: 'Tamil nadu', city: 'Vellore' },
  { state: 'Tamil nadu', city: 'Thoothukkudi' },
  { state: 'Tamil nadu', city: 'Dindigul' },
  { state: 'Tamil nadu', city: 'Thanjavur' },
  { state: 'Tamil nadu', city: 'Ranipet' },
  { state: 'Tamil nadu', city: 'Sivakasi' },
  { state: 'Tamil nadu', city: 'Karur' },
  { state: 'Tamil nadu', city: 'Udhagamandalam' },
  { state: 'Tamil nadu', city: 'Hosur' },
  { state: 'Tamil nadu', city: 'Nagercoil' },
  { state: 'Tamil nadu', city: 'Kancheepuram' },
  { state: 'Tamil nadu', city: 'Kumarapalayam' },
  { state: 'Tamil nadu', city: 'Karaikkudi' },
  { state: 'Tamil nadu', city: 'Neyveli' },
  { state: 'Tamil nadu', city: 'Cuddalore' },
  { state: 'Tamil nadu', city: 'Kumbakonam' },
  { state: 'Tamil nadu', city: 'Tiruvannamalai' },
  { state: 'Tamil nadu', city: 'Pollachi' },
  { state: 'Tamil nadu', city: 'Rajapalayam' },
  { state: 'Tamil nadu', city: 'Gudiyatham' },
  { state: 'Tamil nadu', city: 'Pudukkottai' },
  { state: 'Tamil nadu', city: 'Vaniyambadi' },
  { state: 'Tamil nadu', city: 'Ambur' },
  { state: 'Tamil nadu', city: 'Nagapattinam' },
  { state: 'Tamil nadu', city: 'Ariyalur' },
  { state: 'Tamil nadu', city: 'Dharmapuri' },
  { state: 'Tamil nadu', city: 'Kanyakumari' },
  { state: 'Tamil nadu', city: 'Karaikal' },
  { state: 'Tamil nadu', city: 'Krishnagiri' },
  { state: 'Tamil nadu', city: 'Namakkal' },
  { state: 'Tamil nadu', city: 'Nilgiris' },
  { state: 'Tamil nadu', city: 'Perambalur' },
  { state: 'Tamil nadu', city: 'Ramanathapuram' },
  { state: 'Tamil nadu', city: 'Sivaganga' },
  { state: 'Tamil nadu', city: 'Theni' },
  { state: 'Tamil nadu', city: 'Tiruvallur' },
  { state: 'Tamil nadu', city: 'Tiruvarur' },
  { state: 'Tamil nadu', city: 'Tuticorin' },
  { state: 'Tamil nadu', city: 'Villupuram' },
  { state: 'Tamil nadu', city: 'Virudhunagar' },
  { state: 'Telangana', city: 'Hyderabad' },
  { state: 'Telangana', city: 'Warangal' },
  { state: 'Telangana', city: 'Nizamabad' },
  { state: 'Telangana', city: 'Khammam' },
  { state: 'Telangana', city: 'Karimnagar' },
  { state: 'Telangana', city: 'Ramagundam' },
  { state: 'Telangana', city: 'Mahabubnagar' },
  { state: 'Telangana', city: 'Nalgonda' },
  { state: 'Telangana', city: 'Adilabad' },
  { state: 'Telangana', city: 'Suryapet' },
  { state: 'Telangana', city: 'Miryalaguda' },
  { state: 'Telangana', city: 'Jagtial' },
  { state: 'Telangana', city: 'Bhadrachalam' },
  { state: 'Telangana', city: 'Bhongir' },
  { state: 'Telangana', city: 'Bhupalpalli' },
  { state: 'Telangana', city: 'Gadwal' },
  { state: 'Telangana', city: 'Hanamkonda' },
  { state: 'Telangana', city: 'Jangaon' },
  { state: 'Telangana', city: 'Jayashankar' },
  { state: 'Telangana', city: 'Kamareddy' },
  { state: 'Telangana', city: 'Kothagudem' },
  { state: 'Telangana', city: 'Mahabubabad' },
  { state: 'Telangana', city: 'Mancherial' },
  { state: 'Telangana', city: 'Medak' },
  { state: 'Telangana', city: 'Nagar Kurnool' },
  { state: 'Telangana', city: 'Nirmal' },
  { state: 'Telangana', city: 'Parkal' },
  { state: 'Telangana', city: 'Peddapalli' },
  { state: 'Telangana', city: 'Rangareddy' },
  { state: 'Telangana', city: 'Sangareddy' },
  { state: 'Telangana', city: 'Secunderabad' },
  { state: 'Telangana', city: 'Siddipet' },
  { state: 'Telangana', city: 'Sircilla' },
  { state: 'Telangana', city: 'Stn.Jadcherla' },
  { state: 'Telangana', city: 'Trimulgherry' },
  { state: 'Telangana', city: 'Vikarabad' },
  { state: 'Telangana', city: 'Wanaparthy' },
  { state: 'Telangana', city: 'Zaheerabad' },
  { state: 'Tripura', city: 'Agartala' },
  { state: 'Tripura', city: 'Amarpur' },
  { state: 'Tripura', city: 'Belonia' },
  { state: 'Tripura', city: 'Dharmanagar' },
  { state: 'Tripura', city: 'Kailasahar' },
  { state: 'Tripura', city: 'Kamalpur' },
  { state: 'Tripura', city: 'Khowai' },
  { state: 'Tripura', city: 'Kumarghat' },
  { state: 'Tripura', city: 'Ranirbazar' },
  { state: 'Tripura', city: 'Sabroom' },
  { state: 'Tripura', city: 'Sonamura' },
  { state: 'Tripura', city: 'Teliamura' },
  { state: 'Tripura', city: 'Udaipur' },
  { state: 'Tripura', city: 'Bishalgarh' },
  { state: 'Tripura', city: 'Santirbazar' },
  { state: 'Tripura', city: 'Ambassa' },
  { state: 'Tripura', city: 'Jirania' },
  { state: 'Tripura', city: 'Mohanpur' },
  { state: 'Tripura', city: 'Melaghar' },
  { state: 'Tripura', city: 'Panisagar' },
  { state: 'Tripura', city: 'Dhalai' },
  { state: 'Tripura', city: 'Gomati' },
  { state: 'Tripura', city: 'North Tripura' },
  { state: 'Tripura', city: 'Sepahijala' },
  { state: 'Tripura', city: 'South Tripura' },
  { state: 'Tripura', city: 'West Tripura' },
  { state: 'Uttar Pradesh', city: 'Kanpur' },
  { state: 'Uttar Pradesh', city: 'Lucknow' },
  { state: 'Uttar Pradesh', city: 'Ghaziabad' },
  { state: 'Uttar Pradesh', city: 'Agra' },
  { state: 'Uttar Pradesh', city: 'Varanasi' },
  { state: 'Uttar Pradesh', city: 'Meerut' },
  { state: 'Uttar Pradesh', city: 'Prayagraj' },
  { state: 'Uttar Pradesh', city: 'Bareilly' },
  { state: 'Uttar Pradesh', city: 'Aligarh' },
  { state: 'Uttar Pradesh', city: 'Moradabad' },
  { state: 'Uttar Pradesh', city: 'Saharanpur' },
  { state: 'Uttar Pradesh', city: 'Gorakhpur' },
  { state: 'Uttar Pradesh', city: 'Ayodhya' },
  { state: 'Uttar Pradesh', city: 'Firozabad' },
  { state: 'Uttar Pradesh', city: 'Jhansi' },
  { state: 'Uttar Pradesh', city: 'Muzaffarnagar' },
  { state: 'Uttar Pradesh', city: 'Mathura' },
  { state: 'Uttar Pradesh', city: 'Budaun' },
  { state: 'Uttar Pradesh', city: 'Rampur' },
  { state: 'Uttar Pradesh', city: 'Shahjahanpur' },
  { state: 'Uttar Pradesh', city: 'Farrukhabad' },
  { state: 'Uttar Pradesh', city: 'Ayodhya Cantt' },
  { state: 'Uttar Pradesh', city: 'Maunath Bhanjan' },
  { state: 'Uttar Pradesh', city: 'Hapur' },
  { state: 'Uttar Pradesh', city: 'Noida' },
  { state: 'Uttar Pradesh', city: 'Etawah' },
  { state: 'Uttar Pradesh', city: 'Mirzapur' },
  { state: 'Uttar Pradesh', city: 'Bulandshahr' },
  { state: 'Uttar Pradesh', city: 'Sambhal' },
  { state: 'Uttar Pradesh', city: 'Amroha' },
  { state: 'Uttar Pradesh', city: 'Hardoi' },
  { state: 'Uttar Pradesh', city: 'Fatehpur' },
  { state: 'Uttar Pradesh', city: 'Raebareli' },
  { state: 'Uttar Pradesh', city: 'Orai' },
  { state: 'Uttar Pradesh', city: 'Sitapur' },
  { state: 'Uttar Pradesh', city: 'Bahraich' },
  { state: 'Uttar Pradesh', city: 'Modinagar' },
  { state: 'Uttar Pradesh', city: 'Unnao' },
  { state: 'Uttar Pradesh', city: 'Jaunpur' },
  { state: 'Uttar Pradesh', city: 'Lakhimpur' },
  { state: 'Uttar Pradesh', city: 'Hathras' },
  { state: 'Uttar Pradesh', city: 'Banda' },
  { state: 'Uttar Pradesh', city: 'Pilibhit' },
  { state: 'Uttar Pradesh', city: 'Mughalsarai' },
  { state: 'Uttar Pradesh', city: 'Barabanki' },
  { state: 'Uttar Pradesh', city: 'Khurja' },
  { state: 'Uttar Pradesh', city: 'Gonda' },
  { state: 'Uttar Pradesh', city: 'Mainpuri' },
  { state: 'Uttar Pradesh', city: 'Lalitpur' },
  { state: 'Uttar Pradesh', city: 'Etah' },
  { state: 'Uttar Pradesh', city: 'Deoria' },
  { state: 'Uttar Pradesh', city: 'Ujhani' },
  { state: 'Uttar Pradesh', city: 'Ghazipur' },
  { state: 'Uttar Pradesh', city: 'Sultanpur' },
  { state: 'Uttar Pradesh', city: 'Azamgarh' },
  { state: 'Uttar Pradesh', city: 'Bijnor' },
  { state: 'Uttar Pradesh', city: 'Basti' },
  { state: 'Uttar Pradesh', city: 'Sahaswan' },
  { state: 'Uttar Pradesh', city: 'Chandausi' },
  { state: 'Uttar Pradesh', city: 'Akbarpur' },
  { state: 'Uttar Pradesh', city: 'Ballia' },
  { state: 'Uttar Pradesh', city: 'Tanda' },
  { state: 'Uttar Pradesh', city: 'Greater Noida' },
  { state: 'Uttar Pradesh', city: 'Shikohabad' },
  { state: 'Uttar Pradesh', city: 'Shamli' },
  { state: 'Uttar Pradesh', city: 'Awagarh' },
  { state: 'Uttar Pradesh', city: 'Kasganj' },
  { state: 'Uttar Pradesh', city: 'Ambedkar Nagar' },
  { state: 'Uttar Pradesh', city: 'Amethi' },
  { state: 'Uttar Pradesh', city: 'Auraiya' },
  { state: 'Uttar Pradesh', city: 'Bagpat' },
  { state: 'Uttar Pradesh', city: 'Balrampur' },
  { state: 'Uttar Pradesh', city: 'Chandauli' },
  { state: 'Uttar Pradesh', city: 'Chitrakoot' },
  { state: 'Uttar Pradesh', city: 'Faizabad' },
  { state: 'Uttar Pradesh', city: 'Gautam Buddha Nagar' },
  { state: 'Uttar Pradesh', city: 'Hamirpur' },
  { state: 'Uttar Pradesh', city: 'Jalaun' },
  { state: 'Uttar Pradesh', city: 'Kannauj' },
  { state: 'Uttar Pradesh', city: 'Kanshiram Nagar' },
  { state: 'Uttar Pradesh', city: 'Kaushambi' },
  { state: 'Uttar Pradesh', city: 'Kheri' },
  { state: 'Uttar Pradesh', city: 'Kushinagar' },
  { state: 'Uttar Pradesh', city: 'Maharajganj' },
  { state: 'Uttar Pradesh', city: 'Mahoba' },
  { state: 'Uttar Pradesh', city: 'Mau' },
  { state: 'Uttar Pradesh', city: 'Pratapgarh' },
  { state: 'Uttar Pradesh', city: 'Sant Kabir Nagar' },
  { state: 'Uttar Pradesh', city: 'Sant Ravidas Nagar' },
  { state: 'Uttar Pradesh', city: 'Shrawasti' },
  { state: 'Uttar Pradesh', city: 'Siddharthnagar' },
  { state: 'Uttar Pradesh', city: 'Sonbhadra' },
  { state: 'Uttarakhand', city: 'Dehradun' },
  { state: 'Uttarakhand', city: 'Haridwar' },
  { state: 'Uttarakhand', city: 'Roorkee' },
  { state: 'Uttarakhand', city: 'Haldwani' },
  { state: 'Uttarakhand', city: 'Rudrapur' },
  { state: 'Uttarakhand', city: 'Kashipur' },
  { state: 'Uttarakhand', city: 'Rishikesh' },
  { state: 'Uttarakhand', city: 'Almora' },
  { state: 'Uttarakhand', city: 'Bageshwar' },
  { state: 'Uttarakhand', city: 'Chamoli' },
  { state: 'Uttarakhand', city: 'Champawat' },
  { state: 'Uttarakhand', city: 'Nainital' },
  { state: 'Uttarakhand', city: 'Pauri Garhwal' },
  { state: 'Uttarakhand', city: 'Pithoragarh' },
  { state: 'Uttarakhand', city: 'Rudraprayag' },
  { state: 'Uttarakhand', city: 'Tehri Garhwal' },
  { state: 'Uttarakhand', city: 'Udham Singh Nagar' },
  { state: 'Uttarakhand', city: 'Uttarkashi' },
  { state: 'West Bengal', city: 'Kolkata' },
  { state: 'West Bengal', city: 'Asansol' },
  { state: 'West Bengal', city: 'Siliguri' },
  { state: 'West Bengal', city: 'Durgapur' },
  { state: 'West Bengal', city: 'Bardhaman' },
  { state: 'West Bengal', city: 'English Bazar' },
  { state: 'West Bengal', city: 'Baharampur' },
  { state: 'West Bengal', city: 'Habra' },
  { state: 'West Bengal', city: 'Kharagpur' },
  { state: 'West Bengal', city: 'Shantipur' },
  { state: 'West Bengal', city: 'Dankuni' },
  { state: 'West Bengal', city: 'Dhulian' },
  { state: 'West Bengal', city: 'Ranaghat' },
  { state: 'West Bengal', city: 'Haldia' },
  { state: 'West Bengal', city: 'Raiganj' },
  { state: 'West Bengal', city: 'Krishnanagar' },
  { state: 'West Bengal', city: 'Nabadwip' },
  { state: 'West Bengal', city: 'Medinipur' },
  { state: 'West Bengal', city: 'Jalpaiguri' },
  { state: 'West Bengal', city: 'Balurghat' },
  { state: 'West Bengal', city: 'Basirhat' },
  { state: 'West Bengal', city: 'Bankura' },
  { state: 'West Bengal', city: 'Chakdaha' },
  { state: 'West Bengal', city: 'Darjeeling' },
  { state: 'West Bengal', city: 'Alipurduar' },
  { state: 'West Bengal', city: 'Purulia' },
  { state: 'West Bengal', city: 'Jangipur' },
  { state: 'West Bengal', city: 'Bangaon' },
  { state: 'West Bengal', city: 'Cooch Behar' },
  { state: 'West Bengal', city: 'Birbhum' },
  { state: 'West Bengal', city: 'Hooghly' },
  { state: 'West Bengal', city: 'Howrah' },
  { state: 'West Bengal', city: 'Kalimpong' },
  { state: 'West Bengal', city: 'Malda' },
  { state: 'West Bengal', city: 'Murshidabad' },
  { state: 'West Bengal', city: 'Nadia' },
  { state: 'West Bengal', city: 'North 24 Parganas' },
  { state: 'West Bengal', city: 'North Dinajpur' },
  { state: 'West Bengal', city: 'South 24 Parganas' },
  { state: 'West Bengal', city: 'South Dinajpur' },
  { state: 'National Capital Territory Of Delhi', city: 'National Capital Territory Of Delhi' },

]

export const isValidPhoneNumber = (phoneNumber: any) => {
  const phoneRegexIndia = /^(\+?91|0)?[6789]\d{9}$/
  return phoneRegexIndia.test(phoneNumber)
}

export const isValidEmail = (email: any) => {
  const tester = /^[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/
  if (!email) return false

  if (email.length > 256) return false

  if (!tester.test(email)) return false

  // Further checking of some things regex can't handle
  const [account, address] = email.split('@')
  if (account.length > 64) return false

  const domainParts = address.split('.')
  return !domainParts.some((part: any) => part.length > 63)
}

export const isValidPasswordNumber = (password: string) => {
  const tester = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%*?&])[A-Za-z\d@#$!%*?&]{8,32}$/
  if (!password) return false

  if (password.length > 32) return false
  if (password.length < 8) return false
}

// Encrypting string using the below function
export const encryptString = (plaintext: string) => {
  return CryptoJS.AES.encrypt(plaintext, import.meta.env.VITE_SECRET_KEY).toString();
}

export const COMPLAIN_STATUS = [
  {
    status: 'AWAITING_MII_REVIEW', // When Investor files the complain & waiting for MII to accept it
    icon: 'border-dashed border-status-orange border',
    subIcon: "",
    color: 'bg-status-white text-status-orange'
  },
  {
    status: 'DISPUTE_RESOLVED_-_PRECONCILIATION', // Complain got resolved
    icon: "border-solid border-highlight-blue border",
    subIcon: "",
    color: 'bg-light-blue text-highlight-blue'
  },
  {
    status: 'CONCILIATION_INITIATED', // Conciliation started
    icon: "border-dashed border-status-green",
    subIcon: "",
    color: 'bg-status-pale-grey text-status-green'
  },
  {
    status: 'CONCILIATION_IN_PROGRESS', // Conciliation In progress
    icon: "border-solid border-status-green border",
    subIcon: "text-xs whitespace-nowrap",
    color: 'bg-status-pale-green text-status-green'
  },
  {
    status: 'CONCILIATION_FAILED', // Waitinf for party to start the arbitration process
    icon: "border-solid border-highlight-red border",
    subIcon: "",
    color: 'bg-status-fail-white text-highlight-red'
  },
  {
    status: 'ARBITRATION_REQUESTED', // Arbitration Requested by Investor
    icon: "border-dashed border-status-green",
    subIcon: "",
    color: 'bg-status-pale-green text-status-green'
  },
  {
    status: 'ARBITRATION_INITIATED', // Arbitration started
    icon: "border-dashed border-status-green",
    subIcon: "",
    color: 'bg-status-pale-green text-status-green'
  },
  {
    status: 'ARBITRATION_IN_PROGRESS', // Arbitration in progress
    icon: "border-solid border-status-green border",
    subIcon: "text-xs whitespace-nowrap",
    color: 'bg-status-pale-green text-status-green'
  },
  {
    status: 'DISPUTE_RESOLVED_-_CONCILIATION', // Complain got resolved
    icon: "border-solid border-highlight-blue border",
    subIcon: "",
    color: 'bg-light-blue text-highlight-blue'
  },
  {
    status: 'DISPUTE_RESOLVED_-_ARBITRATION', // Complain got resolved
    icon: "border-solid border-highlight-blue border",
    subIcon: "",
    color: 'bg-light-blue text-highlight-blue'
  },
  // {
  //   status: 'COMPLAINT_RESOLVED', // Complain got resolved
  //   icon: "border-solid border-highlight-blue border",
  //   subIcon: "",
  //   color: 'bg-light-blue text-highlight-blue'
  // },
  {
    status: 'DISPUTE_RETURNED', // When Complain is rejected by Intermediary
    icon: "border-solid border-status-grey border",
    subIcon: "",
    color: 'bg-F5F5F5 text-status-grey'
  },
  {
    status: 'DISPUTE_WITHDRAWN', // When Complain is rejected by Intermediary
    icon: "border-solid border-status-grey border",
    subIcon: "",
    color: 'bg-F5F5F5 text-status-grey'
  },
]

/**
 * 
 * @param complainUpdates 
 * @param currentStatus 
 * @param complaintDate 
 * @param arbitrationExtension 
 * @param conciliationExtension 
 * @returns 
 */
export const calculateDaysElapsed = (complainUpdates: ComplainUpdate[], currentStatus: string, complaintDate: string, arbitrationExtension: any, conciliationExtension: any) => {
  let statusChangeDate: any
  let currentDate: any = new Date();
  let overDueDays: any

  const conciliationInitaited = complainUpdates && complainUpdates.length > 0
    ? complainUpdates.filter((update: ComplainUpdate) => (update && update.comment ? update.comment.includes('changed Status to CONCILIATION INITIATED.') : null)) : []
  const conciliationInProgress = complainUpdates && complainUpdates.length > 0
    ? complainUpdates.filter((update: ComplainUpdate) => (update && update.comment ? update.comment.includes('changed Status to CONCILIATION IN PROGRESS.') : null)) : []
  const conciliationFailed = complainUpdates && complainUpdates.length > 0
    ? complainUpdates.filter((update: ComplainUpdate) => (update && update.comment ? update.comment.includes('changed Status to CONCILIATION FAILED.') : null)) : []
  const conciliationResolved = complainUpdates && complainUpdates.length > 0
    ? complainUpdates.filter((update: ComplainUpdate) => (update && update.comment ? update.comment.includes('changed Status to DISPUTE RESOLVED - CONCILIATION.') : null)) : []
  const arbitrationInitaited = complainUpdates && complainUpdates.length > 0
    ? complainUpdates.filter((update: ComplainUpdate) => (update && update.comment ? update.comment.includes('changed Status to ARBITRATION INITIATED.') : null)) : []
  const arbitrationInProgress = complainUpdates && complainUpdates.length > 0
    ? complainUpdates.filter((update: ComplainUpdate) => (update && update.comment ? update.comment.includes('changed Status to ARBITRATION IN PROGRESS.') : null)) : []
  const arbitrationResolved = complainUpdates && complainUpdates.length > 0
    ? complainUpdates.filter((update: ComplainUpdate) => (update && update.comment ? update.comment.includes('changed Status to DISPUTE RESOLVED - ARBITRATION.') : null)) : []
  const arbitrationRequested = complainUpdates && complainUpdates.length > 0
    ? complainUpdates.filter((update: ComplainUpdate) => (update && update.comment ? update.comment.includes('changed Status to ARBITRATION REQUESTED') : null)) : []
  const preConciliationResolved = complainUpdates && complainUpdates.length > 0
    ? complainUpdates.filter((update: ComplainUpdate) => (update && update.comment ? update.comment.includes('changed Status to DISPUTE RESOLVED - PRECONCILIATION.') : null)) : []
  const disputeReturned = complainUpdates && complainUpdates.length > 0
    ? complainUpdates.filter((update: ComplainUpdate) => (update && update.comment ? update.comment.includes('changed Status to DISPUTE RETURNED.') : null)) : []

  switch (currentStatus) {
    case 'AWAITING_MII_REVIEW':
      statusChangeDate = new Date(complaintDate)
      break
    // case 'DISPUTE_RESOLVED_-_PRECONCILIATION':
    //   statusChangeDate = preConciliationResolved.length > 0 ? new Date(preConciliationResolved[0].createdAt) : new Date()
    //   break
    case 'CONCILIATION_INITIATED':
      statusChangeDate = conciliationInitaited.length > 0 ? new Date(conciliationInitaited[0].createdAt) : new Date()
      break
    case 'CONCILIATION_IN_PROGRESS':
      statusChangeDate = conciliationInProgress.length > 0 ? new Date(conciliationInProgress[0].createdAt) : new Date()
      break
    case 'CONCILIATION_FAILED':
      statusChangeDate = conciliationFailed.length > 0 ? new Date(conciliationFailed[0].createdAt) : new Date()
      break
    case 'DISPUTE_RESOLVED_-_CONCILIATION':
      statusChangeDate = conciliationResolved.length > 0 ? new Date(conciliationResolved[0].createdAt) : new Date()
      break
    case 'ARBITRATION_REQUESTED':
      statusChangeDate = arbitrationRequested.length > 0 ? new Date(arbitrationRequested[0].createdAt) : new Date()
      break
    case 'ARBITRATION_INITIATED':
      statusChangeDate = arbitrationInitaited.length > 0 ? new Date(arbitrationInitaited[0].createdAt) : new Date()
      break
    case 'ARBITRATION_IN_PROGRESS':
      statusChangeDate = arbitrationInProgress.length > 0 ? new Date(arbitrationInProgress[0].createdAt) : new Date()
      break
    // case 'DISPUTE_RETURNED':
    //   statusChangeDate = disputeReturned.length > 0 ?  new Date(disputeReturned[0].createdAt) : new Date()
    //   break
    // case 'DISPUTE_RESOLVED_-_ARBITRATION':
    //   statusChangeDate = arbitrationResolved.length > 0 ?  new Date(arbitrationResolved[0].createdAt) : new Date()
    //   break
    default:
      statusChangeDate = new Date()
      break;
  }

  // Calculate the time difference in milliseconds
  let timeDifference = currentDate - statusChangeDate;

  // Calculate the number of days
  let daysDifference = Math.floor(timeDifference / (24 * 60 * 60 * 1000)) || 0;

  switch (currentStatus) {
    case 'AWAITING_MII_REVIEW':
      overDueDays = daysDifference - 21
      break
    case 'CONCILIATION_INITIATED':
      overDueDays = daysDifference - 5
      break
    case 'CONCILIATION_IN_PROGRESS':
      overDueDays = conciliationExtension?.isTimelineExtended ? daysDifference - 31 : daysDifference - 21
      break
    case 'CONCILIATION_FAILED':
      overDueDays = daysDifference - 7
      break
    case 'DISPUTE_RESOLVED_-_CONCILIATION':
      overDueDays = daysDifference - 7
      break
    case 'ARBITRATION_REQUESTED':
      overDueDays = daysDifference - 7
      break
    case 'ARBITRATION_INITIATED':
      overDueDays = daysDifference - 5
      break
    case 'ARBITRATION_IN_PROGRESS':
      overDueDays = arbitrationExtension?.isTimelineExtended ? daysDifference - 60 : daysDifference - 30
      break
    default:
      overDueDays = 0
      break;
  }

  return {
    daysDifference,
    overDueDays
  };
}

export const MII_SUB_STATUS = [{ status: 'COMPLAINT_TAKEN_UP_WITH_RESPONDENT', },
{ status: 'RESPONSE_AWAITED_FROM_RESPONDENT', },
{ status: 'ADDITIONAL_DETAILS_SOUGHT_FROM_RESPONDENT', },
{ status: 'ADDITIONAL_DETAILS_SOUGHT_FROM_INITIATOR', },
{ status: 'RESPONSE_RECEIVED_FROM_INITIATOR', },
{ status: '1ST_REMINDER_SENT_TO_RESPONDENT', },
{ status: '1ST_REMINDER_SENT_TO_INITIATOR', },
{ status: '2ND_REMINDER_SENT_TO_RESPONDENT', },
{ status: '2ND_REMINDER_SENT_TO_INITIATOR', },
{ status: '3RD_REMINDER_SENT_TO_RESPONDENT', },
{ status: '3RD_REMINDER_SENT_TO_INITIATOR', },
{ status: 'GENERAL_MEETING_SCHEDULED', },
{ status: 'GENERAL_MEETING_HELD', },
{ status: 'GENERAL_MEETING_CANCELLED', },]
