import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  Filler,
  BarElement,
} from "chart.js";
import { useEffect, useRef, useState } from "react";
import { Bar, Line } from "react-chartjs-2";
import { NavLink, useNavigate } from "react-router-dom";
import { ApiController } from "../apiController";
import { Tooltip as Tool } from 'react-tippy';
import { useTranslation } from 'react-i18next';
import 'react-tippy/dist/tippy.css';
import {
  DashboardCategoryReport,
  DashboardReport,
  DashboardReportParams,
} from "../swagger/models";
import mapDataFeatures from '../helpers/indiageo.json';
import { dateTimeHelper, titleCase } from "./common-module/helper";
import { MapContainer, GeoJSON, CircleMarker, Tooltip as CirTool } from "react-leaflet";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

import cityData from "../../city.json"

const mapData = {
  "type": "FeatureCollection" as const,
  "features": mapDataFeatures
}

const options = {
  scales: {
    x: {
      grid: {
        display: true,
      },
    },
    y: {
      display: true,
      grid: {
        display: true,
      },
      ticks: {
        display: true,
      },
    },
  },
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
  },
};


/**
 * Renders the Dashboard component, which provides a visual representation of
 * various dispute metrics and details. The component includes charts, maps,
 * and tables to display statistics about disputes, including the number of
 * disputes filed, resolved, and returned, as well as regional dispute data.
 * It fetches data from an API and displays it using react-chartjs-2 and 
 * react-leaflet for graphical representation.
 *
 * The component manages various states to handle data fetching, dropdown 
 * actions, and accordion toggles. It uses the `useTranslation` hook for 
 * localizing text and `useNavigate` for navigation purposes.
 */
function Dashboard() {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate()
  const dropdownRef = useRef<any>(null);
  const [dashboardReport, setDashboardReport] = useState<DashboardReport>({});
  const [dashboardCategoryReport, setDashboardCategoryReport] = useState<DashboardCategoryReport>({});
  const [disputesResolved, setDisputesResolved] = useState<number>(0);
  const [disputeType, setDisputeType] = useState('Total')
  const [barDisputeType, setBarDisputeType] = useState('Pre-Con')
  const [barDisputeExtensionType, setBarDisputeExtensionType] = useState('Without')

  const [dashboardReportParams, setDashboardReportParams] = useState<DashboardReportParams>({});

  const [labels, setLabels] = useState<any[]>([]);
  const [currentRegionIndex, setCurrentRegionIndex] = useState(0);
  const [animationToggle, setAnimationToggle] = useState(false);
  const [marketModal, setMarketModal] = useState(false)
  const [disputeRegions, setDisputeRegions] = useState<any[]>([]);
  const [intermediariesAccordion, setIntermediariesAccordion] = useState<any[]>([])
  const [natureOfComplaintAccordion, setNatureOfComplaintAccordion] = useState<any[]>([])
  const RegionReport = {
    topDisputeRegion: {
      regions: [
        { city: 'City A', totalCount: 10 },
        { city: 'City B', totalCount: 15 },
        { city: 'City C', totalCount: 8 },
      ],
    },
  };

  const customTooltipLabel = (tooltipItem: any) => {
    return `${tooltipItem.parsed.y} ${t('days')}`;
  };

  const optionsBar = {
    scales: {
      x: {
        stacked: true,
        grid: {
          display: true,
        },
      },
      y: {
        stacked: true,
        display: true,
        grid: {
          display: true,
        },
        ticks: {
          display: true,
          callback: (value: any) => `${value} ${t('days')}`,
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: customTooltipLabel,
        },
      },
    },
  };

  useEffect(() => {
    const fetchCoordinates = async () => {
      const topDisputeRegionCopy = [];

      for (const city of dashboardReport?.topDisputeRegion) {
        // Replace "null" with "Delhi" if the city name is null
        const cityName = city.city == "null" ? "Delhi" : city.city;

        const data = cityData.find((c: any) => c.city === cityName)

        if (data) {
          const lat = data?.coordinates[0];
          const lng = data?.coordinates[1];
          city.coordinates = [lat, lng];
          topDisputeRegionCopy.push({
            city: city.city,
            coordinates: [lat, lng],
            totalCount: city.totalCount
          })
        }
      }
      setDisputeRegions(topDisputeRegionCopy);
    };

    if (dashboardReport?.topDisputeRegion) {
      fetchCoordinates();
    }
  }, [dashboardReport?.topDisputeRegion]);

  const [graphData, setGraphData] = useState<{ labels: any[], datasets: any[] }>({
    labels: labels,
    datasets: [
      {
        label: t("Disputes"),
        data: [],
        fill: true,
        backgroundColor: ["rgba(37, 205, 37, 0.40)"],
        borderColor: ["rgba(37,205,37,1)"],
        borderWidth: 1,
        borderRadius: 6,
        tension: 0.4,
      },
      {
        label: `${t('Pre-Conciliation')} ${t('Disputes')}`,
        data: [],
        fill: true,
        backgroundColor: ["rgba(15, 15, 15, 0.40)"],
        borderColor: ["rgba(15,15,15,1)"],
        borderWidth: 1,
        borderRadius: 6,
        tension: 0.4,
      },
      {
        label: `${t('Conciliation')} ${t('Disputes')}`,
        data: [],
        fill: true,
        backgroundColor: ["rgba(179, 19, 18, 0.40)"],
        borderColor: ["rgba(179, 19, 18,1)"],
        borderWidth: 1,
        borderRadius: 6,
        tension: 0.4,
      },
      {
        label: `${t('Arbitration')} ${t('Disputes')}`,
        data: [],
        fill: true,
        backgroundColor: ["rgba(97, 163, 186, 0.40)"],
        borderColor: ["rgba(97, 163, 186,1)"],
        borderWidth: 1,
        borderRadius: 6,
        tension: 0.4,
      },
    ],
  });

  const [barData, setBarData] = useState<{ labels: any[], datasets: any[] }>({
    labels: [t('Max'), t('Mean'), t('Min')],
    datasets: [
      {
        label: 'Without Extension',
        fill: true,
        backgroundColor: "rgba(61, 102, 230, 1)",
        borderColor: "rgba(61, 102, 230, 1)",
        borderWidth: 1,
        borderRadius: 6,
        tension: 0.4,
        data: [],
      },
      {
        label: 'With Extension',
        fill: true,
        backgroundColor: "rgba(227, 238, 255, 1)",
        borderColor: "rgba(61, 102, 230, 1)",
        borderDash: [5, 5],
        borderWidth: 1,
        borderRadius: 6,
        tension: 0.4,
        data: [],
      },
    ],
  });


  const handleClickOutside = (event: any) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const fetchReport = async () => {
    const categoryReport = await ApiController.getInstance().indexApi.dashboardCategoryReport(
      dashboardReportParams
    );
    const report = await ApiController.getInstance().indexApi.dashboardReport(
      dashboardReportParams
    );
    setDashboardCategoryReport(categoryReport.data)
    setDashboardReport(report.data);
    setDisputesResolved(report.data?.resolvedConciliationCount + report.data?.resolvedArbitrationCount + report.data?.resolvedPreConciliationCount)
    setLabels(report.data.twelveMonthComplains.map((complain: any) => complain.Date));
    setGraphData({
      ...graphData,
      labels: report.data.twelveMonthComplains.map((complain: any) => complain.Date),
      datasets: [{
        ...graphData.datasets[0],
        data: report.data.twelveMonthComplains.map((complain: any) => complain.count)
      }, {
        ...graphData.datasets[1],
        data: report.data.twelveMonthPreConComplains.map((complain: any) => complain.count)
      }, {
        ...graphData.datasets[2],
        data: report.data.twelveMonthConComplains.map((complain: any) => complain.count)
      }, {
        ...graphData.datasets[3],
        data: report.data.twelveMonthArbComplains.map((complain: any) => complain.count)
      }]
    })
    setBarData({
      ...barData,
      datasets: [
        {
          ...barData.datasets[0], // "Without Extension" data
          label: 'Without Extension',
          data: [
            categoryReport.data?.avgResolutionTime?.preConciliation?.maxDays,
            categoryReport.data?.avgResolutionTime?.preConciliation?.medianDays,
            categoryReport.data?.avgResolutionTime?.preConciliation?.minDays
          ],
          backgroundColor: 'rgba(61, 102, 230, 1)',
          borderColor: 'rgba(61, 102, 230, 1)',
          borderWidth: 1,
          stack: 'stack1',
        },
        {
          ...barData.datasets[1],
          label: 'With Extension',
          data: [

            Math.max(0, (categoryReport.data?.avgResolutionTime?.preConciliationExtension?.maxDays || 0) - (categoryReport.data?.avgResolutionTime?.preConciliation?.maxDays || 0)),
            Math.max(0, (categoryReport.data?.avgResolutionTime?.preConciliationExtension?.medianDays || 0) - (categoryReport.data?.avgResolutionTime?.preConciliation?.medianDays || 0)),
            Math.max(0, (categoryReport.data?.avgResolutionTime?.preConciliationExtension?.minDays || 0) - (categoryReport.data?.avgResolutionTime?.preConciliation?.minDays || 0))
          ],
          backgroundColor: 'rgba(227, 238, 255, 1)',
          borderColor: 'rgba(61, 102, 230, 1)',
          borderDash: [5, 5],
          borderWidth: 1,
          stack: 'stack1',
        }
      ]
    })
  };

  const formatNumber = (number: number) => {
    if (number >= 10000000) {
      return (number / 10000000).toFixed(2) + ' Cr';
    } else if (number < 10000000 && number >= 100000) {
      return (number / 100000).toFixed(2) + ' L';
    } else {
      return number;
    }
  }

  const toggleIntermediaryAccordion = (index: any) => {
    if (intermediariesAccordion.includes(index)) {
      setIntermediariesAccordion(intermediariesAccordion.filter(item => item !== index));
    } else {
      setIntermediariesAccordion([...intermediariesAccordion, index]);
      setNatureOfComplaintAccordion(natureOfComplaintAccordion.filter(item => item !== index));
    }
  };

  const toggleNatureOfComplaintAccordion = (index: any) => {
    if (natureOfComplaintAccordion.includes(index)) {
      setNatureOfComplaintAccordion(natureOfComplaintAccordion.filter(item => item !== index));
    } else {
      setNatureOfComplaintAccordion([...natureOfComplaintAccordion, index]);
      setIntermediariesAccordion(intermediariesAccordion.filter(item => item !== index));
    }
  };

  const closeAccordion = (index: any) => {
    if (intermediariesAccordion.includes(index)) {
      toggleIntermediaryAccordion(index);
    }
    if (natureOfComplaintAccordion.includes(index)) {
      toggleNatureOfComplaintAccordion(index);
    }
  }

  useEffect(() => {
    fetchReport();
  }, [dashboardReportParams]);

  const openRegisterMP = () => {
    navigate('/intermediary-register')
  }

  return (
    <div className=" w-full sm:h-full h-full p-5">
      <div
        style={{ background: "#F4F4F4" }}
        className="flex w-full sm:h-full h-full px-8 py-7 flex-col gap-8 rounded-2xl"
      >

        <div className="flex flex-col-reverse text-center sm:text-left gap-5 items-center sm:items-start sm:flex-row sm:justify-between">
          <div className="flex flex-col gap-1">
            <div className="flex flex-row gap-2 items-center">
              <p className="sm:text-4xl text-2xl font-semibold">{t('DisputeDataDashboard')}</p>
              <Tool
                title={t('AllDataCumulative')}
                position="right"
                trigger="click"
                arrow={true}
                animation="shift"
                {...({ children: <i className="ph-bold text-xl ph-info cursor-pointer"></i> } as any)} // Type assertion to bypass TS errors
              >
              </Tool>
            </div>

            <p className="text-xs text-back-gray">{t('LastUpdatedAt', {
              date: dateTimeHelper(new Date())
            })} </p>
          </div>
          <img src="https://ocp-module-development.s3.ap-south-1.amazonaws.com/SMARTODR_LOGO.svg" alt="SMARTODR Logo" style={{ width: '250px', height: 'fit' }} />
        </div>

        <div className="flex sm:flex-row sm:justify-between sm:gap-0 gap-8 flex-col">
          <div className="flex flex-col justify-between rounded-xl sm:w-1/3 w-full sm:mr-8 sm:pl-4 px-4 shadow bg-white py-4 border border-border-grey">
            <div className="flex flex-col gap-2 pb-10">
              <p className="text-xl font-semibold">{t('RegisteredInvestors')}</p>
              <a href="https://help.smartodr.in/docs/investor-basics/registering-on-smartodr" target="_blank" className="text-dash-blue text-sm items-center flex gap-3 h-full">{t('RegisterOnSMARTODR')} <i className="ph-bold ph-arrow-right"></i></a>
            </div>
            <div className="flex flex-col gap-2">
              <p className="text-4xl font-medium flex gap-3 items-center">{dashboardReport?.investorCount}</p>
              <p className="text-dash-grey text-sm">{dashboardReport?.currentWeekInvestorCount} {t('InvestorsRegisteredWeek')}</p>
            </div>
          </div>
          <div className="flex flex-col justify-between rounded-xl sm:w-1/3 w-full sm:mr-8 sm:pl-4 px-4 shadow bg-white py-4 border border-border-grey">
            <div className="flex flex-col gap-2 pb-10">
              <p className="text-xl font-semibold">{t('RegisteredMarketParticipants')}</p>
              <p onClick={() => setMarketModal(true)} className="text-dash-blue text-sm items-center flex gap-3 h-full cursor-pointer">{t('CategoryWiseRegistrations')} <i className="ph-bold ph-arrow-right"></i></p>
            </div>
            <div className="flex flex-col gap-2">
              <p className="text-4xl font-medium flex gap-3 items-center">{dashboardReport?.marketParticipantCount}</p>
              <p className="text-dash-grey text-sm">{t('Aggregate')}</p>
            </div>
          </div>
          <div className="flex flex-col justify-between rounded-xl sm:w-1/3 w-full sm:pl-4 px-4 shadow bg-highlight-blue text-white py-4 border border-border-grey" style={{ backgroundImage: 'url(https://ocp-module-production.s3.ap-south-1.amazonaws.com/whitesparktrimmed.png)', backgroundSize: 'contain', backgroundPosition: 'right', backgroundRepeat: 'no-repeat', backgroundBlendMode: 'soft-light' }}>
            <div className="flex flex-col gap-2 pb-10">
              <p className="text-xl font-semibold">{t('MarketParticipantVerificationPending')}</p>
            </div>
            <div className="flex flex-col gap-2">
              <button className="bg-white py-4 px-9 text-highlight-blue text-sm font-semibold w-2/3 rounded-lg" onClick={() => openRegisterMP()}>{t('VerifyNow')}</button>
            </div>
          </div>


        </div>

        <div className="flex sm:flex-row sm:justify-between sm:gap-0 gap-8 flex-col">
          <div className="flex flex-col justify-between rounded-xl sm:w-1/3 w-full sm:mr-8 sm:pl-4 px-4 shadow bg-white py-4 border border-border-grey">
            <div className="flex flex-col gap-2 pb-10">
              <p className="text-xl font-semibold">{t('DisputesFiled')}</p>
              <a href="https://help.smartodr.in/docs/investor-basics/filing-a-dispute" target="_blank" className="text-dash-blue text-sm items-center flex gap-3">{t('HowtoFileDispute')} <i className="ph-bold ph-arrow-right"></i></a>
            </div>
            <div className="flex flex-col gap-2">
              <p className="text-4xl font-medium flex gap-3 items-center">{dashboardReport?.complainCount}</p>
              <p className="text-dash-grey text-sm">{dashboardReport?.currentWeekComplainCount} {t('DisputesFiledWeek')}</p>
            </div>
          </div>

          <div className="flex flex-col justify-between rounded-xl sm:mr-8 sm:w-1/3 w-full sm:pl-4 px-4 shadow bg-white py-4 border border-border-grey">
            <div className="flex flex-row gap-2 items-center">
              <p className="text-xl font-semibold">{t('TotalClaimValue')}</p>
              <Tool
                title={t('TotalClaimValueExcludes')}
                position="right"
                trigger="click"
                arrow={true}
                animation="shift"
                {...({ children: <i className="ph-bold text-xl ph-info cursor-pointer"></i> } as any)} // Type assertion to bypass TS errors
              >
              </Tool>
            </div>
            <div className="flex flex-col gap-2 pt-10">
              <p className="font-medium text-4xl">₹{formatNumber(dashboardReport?.claimTotal)}</p>
              <p className="text-sm text-dash-grey">{t('Aggregate')}</p>
            </div>
          </div>

          <div className="flex flex-col justify-between rounded-xl sm:w-1/3 sm:pl-4 px-4 shadow bg-white py-4 border border-border-grey">
            <div className="flex flex-row gap-2 pb-6 items-center">
              <p className="text-xl font-semibold">{t('DisputesReturned')}</p>
              <Tool
                title={t('DisputesDeemedIrrelevant')}
                position="right"
                trigger="click"
                arrow={true}
                animation="shift"
                {...({ children: <i className="ph-bold text-xl ph-info cursor-pointer"></i> } as any)} // Type assertion to bypass TS errors
              >
              </Tool>
            </div>
            <div className="flex flex-col gap-2 pt-10">
              <p className="font-medium text-4xl">{formatNumber(dashboardReport?.disputeReturnedCount)}</p>
              <p className="text-sm text-dash-grey">{t('Aggregate')}</p>
            </div>
          </div>

        </div>

        <div className="flex sm:flex-row w-full gap-8 flex-col sm:justify-between">
          <div className="flex flex-col justify-between rounded-xl sm:mr-8 sm:w-1/2 w-full sm:pl-4 px-4 shadow bg-white py-4 border border-border-grey">
            <div className="flex flex-col gap-2 pb-10">
              <div className="flex flex-row gap-2 items-center">
                <p className="text-xl font-semibold">{t('ReferredToODR')}</p>
                <Tool
                  title={t('ODRIncludesPreConciliation')}
                  position="right"
                  trigger="click"
                  arrow={true}
                  animation="shift"
                  {...({ children: <i className="ph-bold text-xl ph-info cursor-pointer"></i> } as any)} // Type assertion to bypass TS errors
                >
                </Tool>
              </div>
              <a href="https://help.smartodr.in/docs/investor-basics/odr-definitions" target="_blank" className="text-dash-blue text-sm items-center flex gap-3">{t('LearnMoreAboutODR')} <i className="ph-bold ph-arrow-right"></i></a>
            </div>
            <div className="flex flex-col gap-2">
              <p className="text-4xl font-medium flex gap-3 items-center">{dashboardReport?.complainODRCount}</p>
              <p className="text-xs text-dash-grey">{t('DisputesWorth')} ₹{formatNumber(dashboardReport?.odrClaimTotal)}.</p>
            </div>
          </div>
          <div className="flex flex-col justify-between rounded-xl sm:w-1/2 w-full sm:pl-4 px-4 shadow bg-white py-4 border border-border-grey">
            <div className="flex flex-col gap-2">
              <p className="text-xl font-semibold">{t('DisputesResolvedSMARTODR')}</p>
              <div className="text-xs items-center flex flex-wrap gap-2 sm:gap-0 pb-11">
                <button className={`${disputeType === "Total" ? 'bg-border-grey text-white' : ''} text-[10px] p-2 sm:rounded-tl-lg sm:rounded-bl-lg border border-border-grey hover:bg-back-gray`}
                  onClick={() => {
                    setDisputesResolved(dashboardReport?.resolvedPreConciliationCount + dashboardReport?.resolvedConciliationCount + dashboardReport?.resolvedArbitrationCount)
                    setDisputeType("Total")
                  }}>{t('Total')}</button>
                <button className={`${disputeType === "Pre-Con" ? 'bg-border-grey text-white' : ''} text-[10px] p-2 border border-border-grey hover:bg-back-gray whitespace-nowrap`}
                  onClick={() => {
                    setDisputesResolved(dashboardReport?.resolvedPreConciliationCount)
                    setDisputeType("Pre-Con")
                  }}>{t('Pre-Conciliation')}</button>
                <button className={`${disputeType === "Con" ? 'bg-border-grey text-white' : ''} text-[10px] p-2 border border-border-grey hover:bg-back-gray`}
                  onClick={() => {
                    setDisputesResolved(dashboardReport?.resolvedConciliationCount)
                    setDisputeType("Con")
                  }}>{t('Conciliation')}</button>
                <button className={`${disputeType === "Arb" ? 'bg-border-grey text-white' : ''} text-[10px] p-2 sm:rounded-tr-lg sm:rounded-br-lg border border-border-grey hover:bg-back-gray`}
                  onClick={() => {
                    setDisputesResolved(dashboardReport?.resolvedArbitrationCount)
                    setDisputeType("Arb")
                  }}>{t('Arbitration')}</button>
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <p className="text-4xl font-medium flex gap-3 items-center">{disputesResolved}
              </p>
              <p className="text-dash-grey text-xs">{disputeType === "Total" ? `${t('WorthTotal')} ₹ ${formatNumber(dashboardReport?.resolvedArbitrationClaimAmount + dashboardReport?.resolvedPreConciliationClaimAmount + dashboardReport?.resolvedConciliationClaimAmount)}` : disputeType === "Pre-Con" ? `${t('WorthTotal')} ₹ ${formatNumber(dashboardReport?.resolvedPreConciliationClaimAmount)}` : disputeType === "Con" ? `${t('WorthTotal')} ₹ ${formatNumber(dashboardReport?.resolvedConciliationClaimAmount)}` : `${t('WorthTotal')} ₹ ${formatNumber(dashboardReport?.resolvedArbitrationClaimAmount)}`}</p>
            </div>
          </div>
        </div>
        <div className="flex sm:flex-row sm:justify-between sm:gap-0 gap-8 flex-col">
          <div className="h-screen sm:h-full sm:mr-8 sm:w-1/2 bg-white p-5 rounded-xl shadow  border border-border-grey">
            {disputeRegions?.length > 0 && mapData?.features?.length > 0 &&
              <MapContainer
                className="h-full w-full rounded-xl"
                center={[24, 80]}
                zoom={5}
                scrollWheelZoom={false}
                dragging={true}
              >
                <GeoJSON data={mapData}
                  style={{ fillColor: '#f3f3f3', fillOpacity: 1, color: '#eaeaea', weight: 0.7 }} />
                {
                  disputeRegions?.map((city: any, index: number) => (
                    <CircleMarker
                      key={city.city}
                      center={city.coordinates} // Provide the coordinates for each city
                      radius={city.totalCount / 10} // Adjust the radius based on totalCount or any other factor
                      color="#221e65" // Set the color of the circle marker
                      fillOpacity={0.5} // Set the fill opacity
                      weight={0}
                    >
                      <CirTool>{city.city}: {city.totalCount}</CirTool>
                    </CircleMarker>

                  ))
                }
              </MapContainer>
            }
          </div>
          <div className="flex flex-col gap-9 bg-white p-5 rounded-xl shadow  border border-border-grey sm:w-1/2 sm:h-full">
            <div className="flex sm:flex-row flex-col justify-between">
              <p className=" text-xl font-semibold">{t('TopDisputeRegions')}</p>
              <div className="flex flex-row gap-4">
                <div className="flex flex-row gap-1 items-center">
                  <div className="rounded-full h-fit bg-highlight-blue p-1.5">{" "}</div>
                  <p className="text-sm">{t('DisputesFiled')}</p>
                </div>
                <div className="flex flex-row gap-1 items-center">
                  <div className="rounded-full h-fit bg-dash-blue p-1.5">{" "}</div>
                  <p className="text-sm">{t('DisputesResolved')}</p>
                </div>
              </div>

            </div>
            <div className="flex flex-col gap-6">
              {dashboardReport?.topDisputeRegion?.slice(0, 10).map((region: any, index: number) => {
                return <div className="flex sm:flex-row flex-col justify-between sm:items-center w-full">
                  <p className="w-1/4">{region.city}</p>
                  <div className="w-full sm:w-3/4 flex flex-row rounded-xl">
                    <div className="py-2 rounded-tl-lg rounded-bl-lg bg-dash-blue px-2 text-right text-white" style={{ width: `${((region?.resolvedCount || 0) * 100 / ((region?.resolvedCount || 0) + region?.totalCount)) * 100 !== 0 ? ((region?.resolvedCount || 0) * 100 / ((region?.resolvedCount || 0) + region?.totalCount)) : 5}%` }}>{region.resolvedCount ? region.resolvedCount : 0}</div>
                    <div className="py-2 rounded-tr-lg rounded-br-lg bg-highlight-blue text-white px-2 text-right" style={{ width: `${(region?.totalCount * 100 / ((region?.resolvedCount || 0) + region?.totalCount)) !== 100 ? (region?.totalCount * 100 / ((region?.resolvedCount || 0) + region?.totalCount)) : 95}%` }}>{region.totalCount}</div>
                  </div>
                </div>
              })}
            </div>
          </div>
        </div>
        <div className="flex flex-col sm:flex-row w-full gap-8 sm:gap-0 justify-between">
          <div className="flex flex-col gap-9 sm:mr-8 bg-white p-5 rounded-xl shadow  border border-border-grey sm:w-1/2 sm:h-full">
            <div className="flex flex-col sm:flex-row gap-2 sm:gap-0 justify-between">
              <p className=" text-xl font-semibold">{t('TopDisputeLabels')}</p>
              <div className="flex flex-row gap-4">
                <div className="flex flex-row gap-1 items-center">
                  <div className="rounded-full h-fit bg-highlight-blue p-1.5">{" "}</div>
                  <p className="text-sm">{t('DisputesFiled')}</p>
                </div>
                <div className="flex flex-row gap-1 items-center">
                  <div className="rounded-full h-fit bg-dash-blue p-1.5">{" "}</div>
                  <p className="text-sm">{t('DisputesResolved')}</p>
                </div>
              </div>

            </div>
            <div className="flex flex-col gap-4">
              {dashboardCategoryReport?.topTenReasons?.slice(0, 10)?.map((reason: any, index: number) => {
                return <div className="flex flex-col gap-1 w-full">
                  <p>{reason?.natureType}</p>
                  <div className="flex flex-row rounded-xl">
                    <div className="py-2 rounded-tl-lg rounded-bl-lg bg-dash-blue px-2 text-right text-white" style={{ width: `${((reason?.resolvedCount ? reason?.resolvedCount : 0) * 100 / ((reason?.resolvedCount ? reason?.resolvedCount : 0) + reason?.totalCount)) !== 0 ? ((reason?.resolvedCount ? reason?.resolvedCount : 0) * 100 / ((reason?.resolvedCount ? reason?.resolvedCount : 0) + reason?.totalCount)) : 5}%` }}>{reason?.resolvedCount ? reason?.resolvedCount : 0}</div>
                    <div className="py-2 rounded-tr-lg rounded-br-lg bg-highlight-blue text-white px-2 text-right" style={{ width: `${(reason?.totalCount * 100 / ((reason?.resolvedCount ? reason?.resolvedCount : 0) + reason?.totalCount)) !== 100 ? (reason?.totalCount * 100 / ((reason?.resolvedCount ? reason?.resolvedCount : 0) + reason?.totalCount)) : 95}%` }}>{reason?.totalCount}</div>
                  </div>
                </div>
              })}
            </div>
          </div>
          <div className="flex flex-col justify-end sm:w-1/2 w-full gap-8 sm:h-full h-fit overflow-auto">

            <div className="flex flex-col gap-4 px-4 py-4 h-full w-full shadow bg-white border border-border-grey rounded-xl">
              <div>
                <p className="text-sm text-label-grey">{t('AverageTime')}</p>
                <p className=" text-xl font-semibold">
                  {barDisputeType === "Pre-Con" && barDisputeExtensionType === "Without" && dashboardCategoryReport?.avgResolutionTime?.preConciliation?.medianDays + ` ${t('days')}`}
                  {barDisputeType === "Con" && barDisputeExtensionType === "Without" && dashboardCategoryReport?.avgResolutionTime?.conciliation?.medianDays + ` ${t('days')}`}
                  {barDisputeType === "Arb" && barDisputeExtensionType === "Without" && dashboardCategoryReport?.avgResolutionTime?.arbitration?.medianDays + ` ${t('days')}`}
                  {barDisputeType === "Pre-Con" && barDisputeExtensionType === "With" && dashboardCategoryReport?.avgResolutionTime?.preConciliationExtension?.medianDays + ` ${t('days')}`}
                  {barDisputeType === "Con" && barDisputeExtensionType === "With" && dashboardCategoryReport?.avgResolutionTime?.conciliationExtension?.medianDays + ` ${t('days')}`}
                  {barDisputeType === "Arb" && barDisputeExtensionType === "With" && dashboardCategoryReport?.avgResolutionTime?.arbitrationExtension?.medianDays + ` ${t('days')}`}
                </p>
              </div>
              {barDisputeExtensionType === "Without" &&
                <div className="text-xs items-center flex pb-4">
                  <button className={`${barDisputeType === "Pre-Con" ? 'bg-border-grey text-white' : ''} text-[10px] p-2 border rounded-tl-lg rounded-bl-lg border-border-grey hover:bg-back-gray whitespace-nowrap`}
                    onClick={() => {
                      setBarData({
                        ...barData,
                        datasets: [
                          {
                            label: "Without Extension",
                            data: [
                              dashboardCategoryReport?.avgResolutionTime?.preConciliation?.maxDays,
                              dashboardCategoryReport?.avgResolutionTime?.preConciliation?.medianDays,
                              dashboardCategoryReport?.avgResolutionTime?.preConciliation?.minDays,
                            ],
                            backgroundColor: "rgba(61, 102, 230, 1)",
                            borderColor: "rgba(61, 102, 230, 1)",
                            stack: 'stack1',
                          },
                          {
                            label: "With Extension",
                            data: [
                              Math.max(0, (dashboardCategoryReport?.avgResolutionTime?.preConciliationExtension?.maxDays || 0) - (dashboardCategoryReport?.avgResolutionTime?.preConciliation?.maxDays || 0)),
                              Math.max(0, (dashboardCategoryReport?.avgResolutionTime?.preConciliationExtension?.medianDays || 0) - (dashboardCategoryReport?.avgResolutionTime?.preConciliation?.medianDays || 0)),
                              Math.max(0, (dashboardCategoryReport?.avgResolutionTime?.preConciliationExtension?.minDays || 0) - (dashboardCategoryReport?.avgResolutionTime?.preConciliation?.minDays || 0)),
                            ],
                            backgroundColor: "rgba(227, 238, 255, 1)",
                            borderColor: "rgba(61, 102, 230, 1)",
                            borderDash: [5, 5],
                            stack: 'stack1',
                          },
                        ],
                      })
                      setBarDisputeType("Pre-Con")
                    }}>Pre-Con</button>
                  <button className={`${barDisputeType === "Con" ? 'bg-border-grey text-white' : ''} text-[10px] p-2 border border-border-grey hover:bg-back-gray`}
                    onClick={() => {
                      setBarData({
                        ...barData,
                        datasets: [
                          {
                            label: "Without Extension",
                            data: [
                              dashboardCategoryReport?.avgResolutionTime?.conciliation?.maxDays,
                              dashboardCategoryReport?.avgResolutionTime?.conciliation?.medianDays,
                              dashboardCategoryReport?.avgResolutionTime?.conciliation?.minDays,
                            ],
                            backgroundColor: "rgba(61, 102, 230, 1)",
                            borderColor: "rgba(61, 102, 230, 1)",
                            stack: 'stack1',
                          },
                          {
                            label: "With Extension",
                            data: [
                              Math.max(0, (dashboardCategoryReport?.avgResolutionTime?.conciliationExtension?.maxDays || 0) - (dashboardCategoryReport?.avgResolutionTime?.conciliation?.maxDays || 0)),
                              Math.max(0, (dashboardCategoryReport?.avgResolutionTime?.conciliationExtension?.medianDays || 0) - (dashboardCategoryReport?.avgResolutionTime?.conciliation?.medianDays || 0)),
                              Math.max(0, (dashboardCategoryReport?.avgResolutionTime?.conciliationExtension?.minDays || 0) - (dashboardCategoryReport?.avgResolutionTime?.conciliation?.minDays || 0)),
                            ],
                            backgroundColor: "rgba(227, 238, 255, 1)",
                            borderColor: "rgba(61, 102, 230, 1)",
                            borderDash: [5, 5],
                            stack: 'stack1',
                          },
                        ],
                      })
                      setBarDisputeType("Con")
                    }}>Con</button>
                  <button className={`${barDisputeType === "Arb" ? 'bg-border-grey text-white' : ''} text-[10px] p-2 rounded-tr-lg rounded-br-lg border border-border-grey hover:bg-back-gray`}
                    onClick={() => {
                      setBarData({
                        ...barData,
                        datasets: [
                          {
                            label: "Without Extension",
                            data: [
                              dashboardCategoryReport?.avgResolutionTime?.arbitration?.maxDays,
                              dashboardCategoryReport?.avgResolutionTime?.arbitration?.medianDays,
                              dashboardCategoryReport?.avgResolutionTime?.arbitration?.minDays,
                            ],
                            backgroundColor: "rgba(61, 102, 230, 1)",
                            borderColor: "rgba(61, 102, 230, 1)",
                            stack: 'stack1',
                          },
                          {
                            label: "With Extension",
                            data: [
                              Math.max(0, (dashboardCategoryReport?.avgResolutionTime?.arbitrationExtension?.maxDays || 0) - (dashboardCategoryReport?.avgResolutionTime?.arbitration?.maxDays || 0)),
                              Math.max(0, (dashboardCategoryReport?.avgResolutionTime?.arbitrationExtension?.medianDays || 0) - (dashboardCategoryReport?.avgResolutionTime?.arbitration?.medianDays || 0)),
                              Math.max(0, (dashboardCategoryReport?.avgResolutionTime?.arbitrationExtension?.minDays || 0) - (dashboardCategoryReport?.avgResolutionTime?.arbitration?.minDays || 0)),
                            ],
                            backgroundColor: "rgba(227, 238, 255, 1)",
                            borderColor: "rgba(61, 102, 230, 1)",
                            borderDash: [5, 5],
                            stack: 'stack1',
                          },
                        ],
                      })
                      setBarDisputeType("Arb")
                    }}>Arb</button>
                </div>
              }
              <div className="h-full pb-4">
                {labels?.length > 0 && <Bar aria-label="Bar Graph" options={optionsBar} data={barData} />}
              </div>
            </div>


          </div>
        </div>
        <div className="flex sm:flex-row sm:h-[32rem] h-fit gap-8 sm:gap-0 flex-col  overflow-auto ">
          <div className="flex flex-col sticky top-0 sm:w-2/5 w-full sm:h-full h-fit shadow border border-border-grey bg-white rounded-lg">
            <div className="px-6 pt-6 pb-4 flex flex-row gap-2 items-center">
              <p className="text-lg font-semibold">{t('Categories')}</p>
              <Tool
                title={t('SeeAggregateDisputes')}
                position="right"
                trigger="click"
                arrow={true}
                animation="shift"
                {...({ children: <i className="ph-bold text-xl ph-info cursor-pointer"></i> } as any)} // Type assertion to bypass TS errors
              >
              </Tool>
            </div>
            <div className="px-2 max-h-full w-full overflow-hidden overflow-y-scroll flex flex-col gap-2">
              {dashboardCategoryReport?.dashboardCategory?.map(
                (category: any, index: any) => {
                  return (
                    <div className={`flex flex-col gap-2 pb-2 rounded-lg ${intermediariesAccordion.includes(index) || natureOfComplaintAccordion.includes(index) ? 'bg-dash-highblue' : ''}`} onClick={() => closeAccordion(index)}>
                      <div className="group hover:bg-dash-highblue rounded-lg w-full flex flex-row pl-6 py-4 pr-4 justify-between">
                        <div className="flex flex-row gap-4 w-2/3">
                          <div className="text-center bg-highlight-blue  h-12 w-12 flex items-center justify-center rounded-full">
                            <i className={`ph-bold ph-${category?.icon || 'buildings'} text-lg text-white`}></i>
                          </div>

                          <div className="flex flex-col">
                            <p className="text-sm font-medium">{category?.categoryName}</p>
                            <p className="text-xs text-dash-grey">{category?.categoryComplainsCount} {t('disputes')}</p>
                            <p className="text-xs text-dash-grey">₹{formatNumber(category?.claimTotal)}</p>
                          </div>
                        </div>
                        <div className="flex flex-col justify-end items-end">
                          <p className="items-center hidden text-[10px] group-hover:flex">O: {category?.categoryOpenCount}</p>
                          <p className="items-center hidden text-[10px] group-hover:flex">UR: {category?.categoryUnResolvedCount}</p>
                          <p className="items-center hidden text-[10px] group-hover:flex">R: {category?.categoryResolvedCount}</p>
                        </div>

                        <div className="flex gap-2 items-start justify-end">
                          <Tool
                            title={t('ClickTopNature')}
                            position="bottom"
                            arrow={true}
                            animation="shift"
                            size="small"
                            {...({ children: <i className={`ph-list-dashes text-lg text-highlight-blue ${natureOfComplaintAccordion.includes(index) ? 'ph-bold' : 'ph'}`} onClick={() => toggleNatureOfComplaintAccordion(index)}></i> } as any)} // Type assertion to bypass TS errors
                          >
                          </Tool>
                        </div>
                      </div>
                      <div className={`flex flex-col gap-2 sm:pl-28 pl-8 sm:pr-14 pr-8 py-2 ${intermediariesAccordion.includes(index) ? '' : 'hidden'}`}>
                        <div className="flex flex-row w-full justify-between">
                          <table className="table-auto w-full">
                            <thead>
                              <tr>
                                <th className="border-none py-1 pr-3 font-semibold text-left text-xs">
                                  {t('TopIntermediaries')}
                                </th>
                                <th className="border-none py-1 pr-3 font-semibold text-left text-xs">
                                  {t('TotalDisputes')}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {category?.topMarketParticipants?.map((participant: any) => {
                                return <tr>
                                  <td className="border-none pr-3 py-1 font-normal text-xs">
                                    {participant?.organisationName}
                                  </td>
                                  <td className="border-none pr-3 py-1 font-normal text-xs">
                                    {participant?.totalCount} {t('Disputes')}
                                  </td>
                                </tr>
                              }
                              )}
                            </tbody>
                          </table>

                        </div>
                      </div>
                      <div className={`flex flex-col gap-2 sm:pl-28 pl-8 sm:pr-14 pr-8 py-2 ${natureOfComplaintAccordion.includes(index) ? '' : 'hidden'}`}>
                        <div className="flex flex-row w-full justify-between">
                          <table className="table-auto w-full">
                            <thead>
                              <tr>
                                <th className="border-none py-1 pr-3 font-semibold text-left text-xs">
                                  {t('TopNatureComplaints')}
                                </th>
                                <th className="border-none py-1 pr-3 font-semibold text-left text-xs">
                                  {t('TotalDisputes')}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {category?.allNatures?.map((nature: any) => {
                                return <tr>
                                  <td className="border-none pr-3 py-1 font-normal text-xs">
                                    {nature?.natureType}
                                  </td>
                                  <td className="border-none pr-3 py-1 font-normal text-xs">
                                    {nature?.count} {t('Disputes')}
                                  </td>
                                </tr>
                              }
                              )}
                            </tbody>
                          </table>

                        </div>
                      </div>
                    </div>

                  )
                })}
            </div>
          </div>
          <div className="flex flex-col justify-end sm:w-3/5 w-full gap-8 sm:h-full h-fit sm:ml-8 overflow-auto">

            <div className="flex flex-col gap-4 px-4 py-4 h-full w-full shadow bg-white border border-border-grey rounded-xl">
              <p className="text-lg font-semibold">{t('DisputesFiledOverTime')}</p>
              <div className="h-full pb-4">
                {labels?.length > 0 && <Line aria-label="Area Graph" options={options} data={graphData} />}
              </div>
            </div>


          </div>
        </div>
        <div className="bg-white rounded-lg w-full overflow-x-auto flex flex-col gap-6 p-6">
          <p className="font-semibold text-xl">{t('StageWiseDisputeDashboard')}</p>
          <table className="table-auto w-full">
            <thead>
              <tr>
                <th className="border-b py-3 pr-3 font-normal text-left text-sm text-label-grey">
                  {t('Stage')}
                </th>
                <th className="border-b py-3 pr-3 font-normal text-center text-sm text-label-grey">
                  {t('Timeline')}
                </th>
                <th className="border-b text-center py-3 pr-3 font-normal text-sm text-label-grey">
                  <div className="flex flex-row justify-center">
                    <img src="https://ocp-module-production.s3.ap-south-1.amazonaws.com/logos/NSE_Logo.svg" className=" sm:pb-1 sm:h-7 h-fit" alt="NSE logo" />
                  </div>
                </th>
                <th className="border-b py-3 pr-3 font-normal text-center text-sm text-label-grey">
                  <div className="flex flex-row justify-center">
                    <img src="https://ocp-module-production.s3.ap-south-1.amazonaws.com/logos/bse-new-logo.jpg" className=" sm:pb-5 sm:h-16" alt="BSE logo" />
                  </div>
                </th>
                <th className="border-b py-3 pr-3 font-normal  text-center text-sm text-label-grey">
                  <div className="flex flex-row justify-center">
                    <img src="https://ocp-module-production.s3.ap-south-1.amazonaws.com/logos/nsdl-logo.png" className=" sm:pb-0 sm:h-20" alt="NSDL logo" />
                  </div>
                </th>
                <th className="border-b py-3 pr-3 font-normal text-center text-sm text-label-grey">
                  <div className="flex flex-row justify-center">
                    <img src="https://ocp-module-production.s3.ap-south-1.amazonaws.com/logos/CDSL-Logo.png" className=" sm:pb-0 sm:h-7" alt="CDSL logo" />
                  </div>
                </th>
                <th className="border-b py-3 pr-3 font-normal text-center text-sm text-label-grey">
                  <div className="flex flex-row justify-center">
                    <img src="https://ocp-module-production.s3.ap-south-1.amazonaws.com/logos/MSE+logo.jpeg" className=" sm:pb-0 sm:h-20" alt="MSE logo" />
                  </div>
                </th>
                <th className="border-b py-3 pr-3 font-normal text-center text-sm text-label-grey">
                  <div className="flex flex-row justify-center">
                    <img src="https://ocp-module-production.s3.ap-south-1.amazonaws.com/logos/nCDEX+logo.avif" className=" sm:pb-2 sm:h-20" alt="NCDEX logo" />
                  </div>
                </th>
                <th className="border-b py-3 pr-6 font-normal text-center text-sm text-label-grey">
                  <div className="flex flex-row justify-center">
                    <img src="https://ocp-module-production.s3.ap-south-1.amazonaws.com/logos/MCX+logo.png" className=" sm:pb-2 sm:h-6" alt="MCX logo" />
                  </div>
                </th>
                <th className="border-b pl-3 py-3 font-normal text-center text-sm text-label-grey">
                  {t('Total')}
                </th>
              </tr>
            </thead>
            <tbody>
              {dashboardReport?.mappedMIIStatus?.map((status: any) => {
                return <tr>
                  <td className="border-b pr-3 py-5 font-normal text-xs">
                    {titleCase(status?.Status)}
                  </td>

                  {
                    titleCase(status?.Status) === "Awaiting MII Review" ? (
                      <td className="border-b pr-3 py-5 font-normal text-xs text-center" rowSpan={4}>
                        <div className="flex flex-row justify-center rounded-xl px-2 py-44 h-full  bg-light-blue">
                          <p className="">21 {t('days')}</p>
                        </div>
                      </td>

                    ) : titleCase(status?.Status) === "Conciliation Initiated" ? (
                      <td className="border-b pr-3 py-5 font-normal text-xs text-center">
                        <div className="flex flex-row justify-center rounded-full px-2 py-2 bg-light-blue">
                          <p className="">5 {t('days')}</p>
                        </div>
                      </td>

                    ) : titleCase(status?.Status) === "Conciliation In Progress" ? (
                      <td className="border-b pr-3 py-5 font-normal text-xs text-center" rowSpan={3}>
                        <div className="flex flex-row justify-center rounded-xl px-2 py-32 bg-light-blue">
                          <p className="">21 {t('days')}*</p>
                        </div>
                      </td>

                    ) : titleCase(status?.Status) === "Arbitration Initiated" ? (
                      <td className="border-b pr-3 py-5 font-normal text-xs text-center">
                        <div className="flex flex-row justify-center rounded-full px-2 py-2 bg-light-blue">
                          <p className="">5 {t('days')}</p>
                        </div>
                      </td>

                    ) : titleCase(status?.Status) === "Arbitration Requested" ? (
                      <td className="border-b pr-3 py-5 font-normal text-xs text-center">
                        <div className="flex flex-row justify-center rounded-full px-2 py-2 bg-light-blue">
                          <p className="">7 {t('days')}</p>
                        </div>
                      </td>

                    ) : titleCase(status?.Status) === "Arbitration In Progress" ? (
                      <td className="border-b pr-3 py-5 font-normal text-xs text-center">
                        <div className="flex flex-row justify-center rounded-full px-2 py-2 bg-light-blue">
                          <p className="">30 {t('days')}**</p>
                        </div>
                      </td>
                    ) : titleCase(status?.Status) === "Canceled" || titleCase(status?.Status) === "Dispute Returned" || titleCase(status?.Status) === "Dispute Resolved - Preconciliation" || titleCase(status?.Status) === "Dispute Resolved - Conciliation" || titleCase(status?.Status) === "Conciliation Failed" ? (
                      null
                    ) :

                      (<td className="border-b pr-3 py-5 font-normal text-xs text-center"></td>)
                  }

                  <td className="border-b pr-3 py-5 font-normal text-xs text-center">
                    {status.NSE}
                  </td>
                  <td className="border-b pr-3 py-5 font-normal text-xs text-center">
                    {status.BSE}
                  </td>
                  <td className="border-b pr-3 py-5 font-normal text-xs text-center">
                    {status.NSDL}
                  </td>
                  <td className="border-b pr-3 py-5 font-normal text-xs text-center">
                    {status.CDSL}
                  </td>
                  <td className="border-b pr-3 py-5 font-normal text-xs text-center">
                    {status.MSE}
                  </td>
                  <td className="border-b pr-3 py-5 font-normal text-xs text-center">
                    {status.NCDEX}
                  </td>
                  <td className="border-b pr-6 py-5 font-normal text-xs text-center">
                    {status.MCX}
                  </td>
                  <td className="border-b pl-3 py-5 font-normal text-xs text-center">
                    <p className="rounded-full px-2 py-2 bg-light-blue">{status?.TotalCount}</p>
                  </td>
                </tr>
              })}
            </tbody>
          </table>
          <div className="flex flex-col gap-2">
            <p className="text-xs text-label-grey">*{t('TimelineforOnlineConciliation')}</p>
            <p className="text-xs text-label-grey">**{t('TimelineforOnlineArbitration')}.</p>
          </div>
        </div>

        <div className="bg-white rounded-lg w-full overflow-x-auto flex flex-col gap-6 p-6">
          <div className="flex w-full justify-between">
            <p className="font-semibold text-xl">{t('ODRInstitutionTieUps')}</p>
            <NavLink to="/odr-dashboard" className="text-sm text-odr-grey" >{t('SeeODRInstitutionInfobank')}</NavLink>
          </div>

          <table className="table-auto w-full">
            <thead>
              <tr>
                <td className="border-b pr-3 py-5 font-normal text-sm">
                  {t('MII')}
                </td>
                <td className="border-b pr-6 py-5 font-normal text-sm" colSpan={8}>
                  {t('ODRInstitutionsPartneredWith')}
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="border-b pr-3 py-5 font-normal text-xs text-center">
                  <img src="https://ocp-module-production.s3.ap-south-1.amazonaws.com/logos/NSE_Logo.svg" className=" sm:pb-1 sm:h-7 p-0 h-fit" alt="NSE logo" />
                </td>
                <td className="border-b pr-6 py-5 font-normal text-sm">
                  5
                </td>
                <td className="border-b pr-3 py-5 font-normal text-xs text-center">
                  <img src="https://ocp-module-production.s3.ap-south-1.amazonaws.com/logos/CADRE+logo.png" alt="CADRE Logo" style={{ width: '114px', height: 'fit' }} />
                </td>
                <td className="border-b pr-3 py-5 font-normal text-xs text-center">
                  <img src="https://ocp-module-production.s3.ap-south-1.amazonaws.com/logos/CORD+ODR.png" alt="CORD Logo" style={{ width: '115px', height: 'fit' }} />
                </td>
                <td className="border-b pr-3 py-5 font-normal text-xs text-center">
                  <img src="https://ocp-module-production.s3.ap-south-1.amazonaws.com/logos/Jupitice+Logo.png" alt="JUPITICE Logo" style={{ width: '111px', height: 'fit' }} />
                </td>
                <td className="border-b pr-3 py-5  font-normal text-xs text-center">
                  <img src="https://ocp-module-production.s3.ap-south-1.amazonaws.com/logos/Sama_Logo+Plain.png" alt="SAMA Logo" style={{ width: '123px', height: 'fit' }} />
                </td>
                <td className="border-b pr-3 py-5  font-normal text-xs text-center">
                  <img src="https://ocp-module-production.s3.ap-south-1.amazonaws.com/logos/webnyay+logo.png" alt="WEBNYAY Logo" style={{ width: '115px', height: 'fit' }} />
                </td>
              </tr>
              <tr>
                <td className="border-b pr-3 py-3  font-normal text-xs text-center">
                  <img src="https://ocp-module-production.s3.ap-south-1.amazonaws.com/logos/bse-new-logo.jpg" className=" sm:pb-2 sm:h-14 h-fit" alt="BSE logo" />
                </td>
                <td className="border-b pr-6 py-5 font-normal text-sm">
                  1
                </td>

                <td className="border-b pr-3 py-5  font-normal text-xs text-center" colSpan={5}>
                  <img src="https://ocp-module-production.s3.ap-south-1.amazonaws.com/logos/CADRE+logo.png" alt="CADRE Logo" style={{ width: '114px', height: 'fit' }} />
                </td>
              </tr>
              <tr>
                <td className="border-b pr-3 py-  font-normal text-xs text-center">
                  <img src="https://ocp-module-production.s3.ap-south-1.amazonaws.com/logos/nsdl-logo.png" className=" sm:pb-1 sm:h-20 h-fit" alt="NSDL logo" />
                </td>
                <td className="border-b pr-6 py-5 font-normal text-sm">
                  1
                </td>
                <td className="border-b pr-3 py-5  font-normal text-xs text-center" colSpan={5}>
                  <img src="https://ocp-module-production.s3.ap-south-1.amazonaws.com/logos/webnyay+logo.png" alt="WEBNYAY Logo" style={{ width: '115px', height: 'fit' }} />
                </td>
              </tr>
              <tr>
                <td className="border-b pr-3 py-5 font-normal text-xs text-center">
                  <img src="https://ocp-module-production.s3.ap-south-1.amazonaws.com/logos/CDSL-Logo.png" className=" sm:pb-1 sm:h-7 h-fit" alt="CDSL logo" />
                </td>
                <td className="border-b pr-6 py-5 font-normal text-sm">
                  1
                </td>
                <td className="border-b pr-3 py-5 font-normal text-xs text-center" colSpan={5}>
                  <img src="https://ocp-module-production.s3.ap-south-1.amazonaws.com/logos/Presolv360.png" alt="PRESOLV360 Logo" style={{ width: '164px', height: 'fit' }} />
                </td>
              </tr>
              <tr>
                <td className="border-b pr-3 py- font-normal text-xs text-center">
                  <img src="https://ocp-module-production.s3.ap-south-1.amazonaws.com/logos/MSE+logo.jpeg" className=" sm:pb-1 sm:h-20 h-fit" alt="MSE logo" />
                </td>
                <td className="border-b pr-6 py-5 font-normal text-sm">
                  1
                </td>
                <td className="border-b pr-3 py-5 font-normal text-xs text-center" colSpan={5}>
                  <img src="https://ocp-module-production.s3.ap-south-1.amazonaws.com/logos/Jupitice+Logo.png" alt="JUPITICE Logo" style={{ width: '111px', height: 'fit' }} />
                </td>
              </tr>
              <tr>
                <td className="border-b pr-3 font-normal text-xs text-center">
                  <img src="https://ocp-module-production.s3.ap-south-1.amazonaws.com/logos/nCDEX+logo-2cropped.png" className=" sm:pb-1 sm:h-8 h-fit" alt="NCDEX logo" />
                </td>
                <td className="border-b pr-6 py-5 font-normal text-sm">
                  1
                </td>
                <td className="border-b pr-3 py-5 font-normal text-xs text-center" colSpan={5}>
                  <img src="https://ocp-module-production.s3.ap-south-1.amazonaws.com/logos/CORD+ODR.png" alt="CORD Logo" style={{ width: '115px', height: 'fit' }} />
                </td>
              </tr>
              <tr>
                <td className="border-b pr-3 py- font-normal text-xs text-center">
                  <img src="https://ocp-module-production.s3.ap-south-1.amazonaws.com/logos/MCX+logo.png" className="  h-4" alt="MCX logo" />
                </td>
                <td className="border-b pr-6 py-5 font-normal text-sm">
                  1
                </td>
                <td className="border-b pr-3 py-5 font-normal text-xs text-center" colSpan={5}>
                  <img src="https://ocp-module-production.s3.ap-south-1.amazonaws.com/logos/JustAct+logo+wo+bg.png" alt="JUPITICE Logo" style={{ width: '60px', height: '39px' }} />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      {marketModal && (
        <div className="fixed z-10 inset-0 flex items-center justify-center " id="modal">
          <div className="px-8 sm:px-0 sm:w-2/3 h-3/4">
            <div className="flex justify-center pt-4 pb-20 w-full h-full">
              <div
                className="fixed inset-0 transition-opacity"
                onClick={() => setMarketModal(false)}
              >
                <div className="absolute inset-0 bg-gray-900 opacity-75" />
              </div>
              <div
                className="inline-block align-center w-full bg-white rounded-lg py-4 text-left shadow-xl transform transition-all "
                role="dialog"
                aria-modal="true"
                aria-labelledby="modal-headline"
              >

                <div className="flex flex-col gap-1 px-8 pb-5" style={{ height: "-webkit-fill-available" }}>
                  <div className="flex flex-row w-full justify-between items-center">
                    <h1 className='font-semibold sm:text-2xl text-lg pt-3'>{t('AllMarketParticipants')}</h1>
                    <i className={`ph-bold ph-x text-lg text-label-grey cursor-pointer`} onClick={() => setMarketModal(false)}></i>
                  </div>
                  <div className="w-full min-h-fit overflow-hidden overflow-y-auto flex flex-col">

                    <div className='flex flex-col gap-4 pb-3 pt-4 text-label-grey overflow-y-scroll'>
                      {dashboardCategoryReport?.dashboardCategory?.map(
                        (category: any) => {
                          return (
                            <div className="group hover:bg-dash-highblue rounded-lg w-full flex flex-row py-4 justify-between">
                              <div className="flex flex-row gap-4 w-2/3">
                                <div className="text-center bg-highlight-blue  h-12 w-12 flex items-center justify-center rounded-full">
                                  <i className={`ph-bold ph-${category?.icon || 'buildings'} text-lg text-white`}></i>
                                </div>

                                <div className="flex flex-col">
                                  <p className="text-sm font-medium">{category?.categoryName}</p>
                                  <p className="text-xs text-dash-grey">{category?.totalMPCount} {t('MarketParticipants')}</p>
                                </div>
                              </div>
                              <p className="items-center hidden text-xs group-hover:flex">{t('Verified')}: {category?.activeTotalMPCount} | {t('Unverified')}: {category?.inactiveTotalMPCount}</p>
                            </div>
                          )
                        })
                      }
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>

        </div>
      )}

    </div>
  );
}

export default Dashboard
